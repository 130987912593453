import { put, takeEvery, call } from "redux-saga/effects";
import { privacyListAPI, privacyHtmlAPI } from "../API/privacyAPI";
import { privacyGetList, privacyGetListSuccess, privacyGetHtml, privacyGetHtmlSuccess } from "./privacy.slice";

// Player List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(privacyListAPI, params);
        console.log('겟에쳄 사가', data)

        yield put(privacyGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

function* getHtml(action) {
    const { payload: params } = action;

    try {
        const data = yield call(privacyHtmlAPI, params);

        yield put(privacyGetHtmlSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* privacySaga() {
    yield takeEvery(privacyGetList, getList);
    yield takeEvery(privacyGetHtml, getHtml);
}
