import { useDispatch } from "react-redux";
import StaffContent from "./staffCont";
import StaffTabs from "./staffTabs";
import React, { useState } from "react";
import { staffGetList } from "./module/staff.slice";

const Staff = () => {
    const dispatch = useDispatch();
    const [stafftype,setStaffType] = useState("");
    React.useEffect(() => {
        dispatch(
            staffGetList({
                typeCd: "",
            })
        );
    }, [dispatch]);
    
    const handleTabClick = React.useCallback((val) => {
        const value = val === "ALL" ? "" : val;

        setStaffType(value);
    }, []);

    return (
        <>
            <StaffTabs handleTabClick={handleTabClick} />
            <StaffContent stafftype={stafftype} />
        </>
    )
}

export default Staff;