import React from "react";

const CompleteClock = () => {
    return (
        <div className="_qWTcH _1tbqx flip-clock flip-clock-complete">
            <div className="_3cpN7">
                <div className="_2aOn7">DAYS</div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
            </div>
            <div className="_2hRXr"></div>
            <div className="_3cpN7">
                <div className="_2aOn7">HOURS</div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
            </div>
            <div className="_2hRXr"></div>
            <div className="_3cpN7">
                <div className="_2aOn7">MINUTES</div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
            </div>
            <div className="_2hRXr"></div>
            <div className="_3cpN7">
                <div className="_2aOn7">SECONDS</div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx _2kgZC">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
                <div className="_2EBbg">
                    <div className="_SKh-V">0</div>
                    <div className="_106d2">0</div>
                    <div className="_3luxx _2kgZC">
                        <div className="_2v-d4 _1GZQ7">0</div>
                        <div className="_2v-d4 _3AVXa">0</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompleteClock;
