import { call, put, takeEvery } from "redux-saga/effects";
import { errorTestAPI } from "../API/errorTestAPI";
import { errorTest } from "./errorTest.slice";

function* errorTestAction(action) {
    const param = action.payload;
    try {
        const data = yield call(errorTestAPI);
        yield put({ type: errorTest, data });
    } catch (err) {
        console.error(err);
    }
}

export function* errorTestSaga() {
    yield takeEvery(errorTest.type, errorTestAction);
}
