export const reducers = {
    matchInfo: {
        reducer: (state, param) => {
            const { data } = param;
            state.matchInfo = data;
        },
    },
    matchVictory: {
        reducer: (state, param) => {
            const { data } = param;
            state.matchVictory = data;
        },
    },
    getMatchView: {
        reducer: (state, param) => {
            const { data } = param;
            state.getMatchView = data;
        },
    },
    checkApply: {
        reducer: (state, param) => {
            const { data } = param;
            state.checkApply = data;
        },
    },
};
