import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import {
    luckyDrawSlice,
    luckyDrawListSaga,
    luckyDrawViewSaga,
    luckyDrawApplyCheckSaga,
    luckyDrawWinnerYnSaga,
} from "../../pages/luckyDraw/module";
import {
    matchLeagueSortSaga,
    matchSaga,
    matchSlice,
    nextMatchSaga,
} from "../../pages/match/module";
import {
    matchJoinViewSaga,
    matchPredictionInfoSaga,
    matchPredictionSlice,
    getMatchVictoryApplyCheckSaga,
    matchVictorySaga,
} from "../../pages/matchPrediction/module";
import { playerSlice, playerListSaga } from "../../pages/player/module";
import { gatewaySlice, gatewayDetailSaga } from "../../pages/gateway/module";
import { layerModalSlice } from "../../components/layerModal/module";
import {
    eventSlice,
    eventVotingSaga,
    getLeaguesSaga,
    voteCheckSaga,
} from "../../pages/event/module";
import {
    merchandiseSaga,
    merchandiseSlice,
} from "../../pages/merchandise/module";
import { pepperLiveSaga, pepperLiveSlice } from "../../pages/pepperLive/module";
import { faqListSaga, faqSlice } from "../../pages/faq/module";
import { bannerViewSaga, bannerSlice } from "../../pages/banner/module";
import {
    attendanceApplySaga,
    attendanceListSaga,
    attendanceSetSaga,
    attendanceSlice,
} from "../../pages/attendance/module";
import { privacySaga, privacySlice } from "../../pages/footer/module";
import {
    applicationSaga,
    applicationSlice,
} from "../../pages/application/module";
import {
    eventListSaga,
    newsSlice,
    noticeListSaga,
    noticeViewSaga,
} from "../../pages/news/module";
import {
    buyInterParkCodeSaga,
    getMyPointSaga,
    myTicketSaga,
    myTicketSlice,
} from "../../pages/myTicket/module";
import { infiniteSlice } from "../../components/infinite/module";
import {
    applySaga,
    applySlice,
    getEssnInfoSaga,
} from "../../pages/apply/module";
import { errorTestSaga, errorTestSlice } from "../../pages/errorTest/module";
import {
    authSaga,
    authSlice,
    loginCheckSaga,
    logoutSaga,
} from "../../components/header/module";
import { snsSaga, snsSlice, snsViewSaga } from "../../pages/sns/module";
import { videoSaga, videoSlice, videoViewSaga } from "../../pages/video/module";
import {
    pepperNewsSaga,
    pepperNewsSlice,
    pepperNewsViewSaga,
} from "../../pages/pepperNews/module";
import {
    broadcastInfo,
    broadcastSaga,
    broadcastSlice,
} from "../../pages/broadcast/module";
import {
    ediyaSlice,
    ediyaCpnCntSaga,
    ediyaCpnIssueSaga,
    myEdiyaCpnCntSaga,
    myEdiyaCpnSaga,
} from "../../pages/event/module";
import { aboutSlice } from "pages/about/module/about.slice";
import { staffSlice } from "pages/team/module/staff.slice";
import { staffListSaga, staffMenuListSaga } from "pages/team/module/staff.saga";
import { aboutListSaga, aboutMenuListSaga } from "pages/about/module/about.saga";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
    yield all([
        fork(applySaga),
        fork(getEssnInfoSaga),
        fork(luckyDrawListSaga),
        fork(luckyDrawViewSaga),
        fork(luckyDrawApplyCheckSaga),
        fork(luckyDrawWinnerYnSaga),
        fork(merchandiseSaga),
        fork(attendanceListSaga),
        fork(attendanceSetSaga),
        fork(attendanceApplySaga),
        fork(matchSaga),
        fork(nextMatchSaga),
        fork(matchLeagueSortSaga),
        fork(matchPredictionInfoSaga),
        fork(matchVictorySaga),
        fork(getMatchVictoryApplyCheckSaga),
        fork(matchJoinViewSaga),
        fork(playerListSaga),
        fork(gatewayDetailSaga),
        fork(eventVotingSaga),
        fork(voteCheckSaga),
        fork(getLeaguesSaga),
        fork(pepperLiveSaga),
        fork(faqListSaga),
        fork(bannerViewSaga),
        fork(privacySaga),
        fork(applicationSaga),
        fork(noticeListSaga),
        fork(noticeViewSaga),
        fork(eventListSaga),
        fork(myTicketSaga),
        fork(getMyPointSaga),
        fork(buyInterParkCodeSaga),
        fork(errorTestSaga),
        fork(authSaga),
        fork(logoutSaga),
        fork(loginCheckSaga),
        fork(snsSaga),
        fork(snsViewSaga),
        fork(videoSaga),
        fork(videoViewSaga),
        fork(pepperNewsSaga),
        fork(pepperNewsViewSaga),
        fork(broadcastSaga),
        fork(ediyaCpnCntSaga),
        fork(ediyaCpnIssueSaga),
        fork(myEdiyaCpnCntSaga),
        fork(myEdiyaCpnSaga),
        fork(staffListSaga),
        fork(staffMenuListSaga),
        fork(aboutListSaga),
        fork(aboutMenuListSaga),
    ]);
}

export const store = configureStore({
    reducer: {
        apply: applySlice.reducer,
        infinite: infiniteSlice.reducer,
        layerModal: layerModalSlice.reducer,
        luckyDraw: luckyDrawSlice.reducer,
        merchandise: merchandiseSlice.reducer,
        attendance: attendanceSlice.reducer,
        match: matchSlice.reducer,
        matchPrediction: matchPredictionSlice.reducer,
        player: playerSlice.reducer,
        gateway: gatewaySlice.reducer,
        event: eventSlice.reducer,
        pepperLive: pepperLiveSlice.reducer,
        faq: faqSlice.reducer,
        banner: bannerSlice.reducer,
        privacy: privacySlice.reducer,
        application: applicationSlice.reducer,
        news: newsSlice.reducer,
        myTicket: myTicketSlice.reducer,
        errorTest: errorTestSlice.reducer,
        auth: authSlice.reducer,
        sns: snsSlice.reducer,
        video: videoSlice.reducer,
        pepperNews: pepperNewsSlice.reducer,
        broadcast: broadcastSlice.reducer,
        ediya: ediyaSlice.reducer,
        about:aboutSlice.reducer,
        staff:staffSlice.reducer
    },
    middleware: [sagaMiddleware],
    devTools: true,
});

sagaMiddleware.run(rootSaga);

export default store;
