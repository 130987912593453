import React from "react";
import DirectorCard from "./directorCard";
import OrganizationCard from "./organizationCard";
import StaffCard from "./staffCard";
import { useLocation } from "react-router";
import Staff from "./staff";

const Team = () => {
    const location = useLocation();
    const isStaff = location.pathname.includes("staff");

    return (
        <div className="staff">
            <div className="inner">
                {isStaff ? (
                    <>
                        <Staff />
                        {/* <section className="staff-section">
                            <header className="title">감독</header>
                            <DirectorCard />
                        </section>
                        <section className="staff-section">
                            <header className="title">코치 & 스태프</header>
                            <StaffCard />
                        </section> */}
                    </>
                ) : (
                    <section className="staff-section">
                        <header className="title">구단조직</header>
                        <OrganizationCard />
                    </section>
                )}
            </div>
        </div>
    );
};

export default Team;
