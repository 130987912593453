import React from "react";
import classNames from "classnames";

const CardItem = ({ item }) => {
    const { isMark, position, name, engName, koPosition, src, career } = item;

    return (
        <div className="swiper-body">
            {career ? (
                <div className="career-list-wrap">
                    {career.map(({ category, list }, index) => (
                        <section key={index}>
                            {category && (
                                <h2 className="category">[{category}]</h2>
                            )}
                            {list.map(({ title, content }, i) => (
                                <dl key={i}>
                                    {title && <dt>{title}</dt>}
                                    <dd>{content}</dd>
                                </dl>
                            ))}
                        </section>
                    ))}
                </div>
            ) : (
                <div className="image-wrap">
                    <img src={src} alt={name} />
                </div>
            )}
            <ul
                className={classNames(
                    "info-list",
                    position.toLowerCase(),
                    isMark && "mark",
                )}
            >
                <li className="position">{position}</li>
                <li className="name">{name}</li>
                {engName && <li className="eng-name">{engName}</li>}
                {koPosition && <li className="ko-position">{koPosition}</li>}
            </ul>
        </div>
    );
};

export default CardItem;
