import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const broadcastAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/aipeppers/broadCastInfo", //구단소식 API 주소로 변경해주세요
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
