export const reducers = {
    luckyDrawGetList: (state, { payload: { type } }) => {
        state.list.step = type;
        state.list.count = 0;
        state.list.data = null;
    },
    luckyDrawGetView: (state, { payload: { id } }) => {
        state.view.id = id;
        state.view.data = null;
        state.apply.code = 0;
        state.apply.data = null;
        state.view.applyCheckCode = 0;
    },
    luckyDrawGetApply: (state) => {
        state.apply.code = 0;
        state.apply.data = null;
    },
    luckyDrawGetApplyCheck: () => {},
    luckyDrawGetListSuccess: (state, { payload: { count, data } }) => {
        state.list.count = count;
        state.list.data = data;
    },
    luckyDrawGetViewSuccess: (state, { payload }) => {
        state.view.data = payload;
    },
    luckyDrawGetApplySuccess: (state, { payload: { code, data } }) => {
        state.apply.code = code;
        state.apply.data = data;
    },
    luckyDrawGetApplyCheckSuccess: (state, { payload: { code } }) => {
        state.view.applyCheckCode = code;
    },
    luckyDrawWinnerYn: (state) => {
        state.winner.data = null;
        state.winner.code = null;
    },
    luckyDrawWinnerYnSuccess: (state, { payload: { code, data } }) => {
        state.winner.code = code;
        state.winner.data = data;
    },
};
