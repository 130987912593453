import React from "react";

const NotFound = () => {
  return (
    <div className="error-page">
      <p className="message">
        oops...
        <br />
        page not found
      </p>
    </div>
  );
};

export default NotFound;
