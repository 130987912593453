import { useCallback, useState } from "react";


const BroadcastComment = (props) => {

    const [textValue, setTextValue] = useState("");
    const handleSetValue = (e) => {
        setTextValue(e.target.value);
    };

    const handleClick = () => {
        const msgTxtArea = document.getElementById("msgTxtArea");
        sendMsg(msgTxtArea.value);
        msgTxtArea.focus();
    }

    function sendMsg(msg) {
        props.setChatValue(msg);
        setTextValue("");
    }


    return (
        <>
        <div className="input_form">
            <textarea
                id="msgTxtArea"
                placeholder="메세지를 입력해 주세요."
                onChange={(e) => handleSetValue(e)}
                value={textValue}
                maxLength="300"
            />
            <button type="button" onClick={handleClick}>작성</button>
        </div>
        </>
    );
}

export default BroadcastComment;