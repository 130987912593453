import React, { useEffect, useState } from "react";
import SnsTabs from "./snsTabs";
import SnsItem from "./snsItem";
import { useSelector } from "react-redux";
import { snsGetList } from "./module";
import Infinite from "../../components/infinite/infinite";
import { useParams } from "react-router";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Sns = () => {
    const { type } = useParams();

    const { count, data } = useSelector((state) => ({
        count: state.sns.list.count,
        data: state.sns.list.data,
    }));

    const [activeTab, setActiveTab] = useState(type);

    // 탭 클릭 시
    const onTabClick = (type) => {
        if (activeTab !== type) {
            setActiveTab(type);
        }
    };

    // 리스트 아이템
    const onItemComponent = (post) => <SnsItem key={post.id} post={post} />;

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="none-result">
            <span className="description">등록된 영상이 없습니다.</span>
        </p>
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button2_AI페퍼스_SNS_click','AI_peppers_button2_AI페퍼스_SNS_click','AI_peppers_button2_AI페퍼스_SNS_click');
            } else if ( gb === 'm4_b' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner_페퍼포토_더보기_click','maintab4_banner_페퍼포토_더보기_click','maintab4_banner_페퍼포토_더보기_click');
            } else if ( gb === 'm4_b3' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner3_페퍼인스타그램_더보기_click','maintab4_banner3_페퍼인스타그램_더보기_click','maintab4_banner3_페퍼인스타그램_더보기_click');
            } else if ( gb === 'm4_b4' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner4_페퍼유튜브_더보기_click','maintab4_banner4_페퍼유튜브_더보기_click','maintab4_banner4_페퍼유튜브_더보기_click');
            } else if ( gb === 'm4_b5' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner5_페퍼포스트_더보기_click','maintab4_banner5_페퍼포스트_더보기_click','maintab4_banner5_페퍼포스트_더보기_click');
            } else if ( gb === 'm4_b6' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner5_페퍼네이버TV_click','maintab4_banner5_페퍼네이버TV_click','maintab4_banner5_페퍼네이버TV_click');
            }
        } else {
            if( type === 'SN01' ) {
                TrackGoogleAnalyticsEvent('maintab4_banner_페퍼포토_click','maintab4_banner_페퍼포토_click','maintab4_banner_페퍼포토_click');
            }
        }
        
    }, []);

    useEffect(() => {        
        setActiveTab(type ?? "all");
    }, []);

    return (
        <div className="live-posts sns">
            <div className="inner">
                <SnsTabs activeTab={activeTab} onTabClick={onTabClick} />

                {/* 임시 */}
                {/* <ul className="live-post-list">
                    {posts.map((post) => {
                        return <SnsItem key={post.id} post={post} />;
                    })}
                </ul>*/}

                <Infinite
                    listClass="live-post-list"
                    count={count}
                    data={data}
                    activeTab={activeTab}
                    onGetList={snsGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                />
            </div>
        </div>
    );
};

export default Sns;
