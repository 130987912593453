import React from "react";
import { FULL_URL } from "../../constant/contant";
import { handleAppBridge, setDateFormat } from "../../libs/js/common";

const PepperItem = ({ post, onClick }) => {
    const { contentCd, id, title, thumb, reg_dt, regDt, link } = post;

    return (
        <li onClick={() => onClick(id, contentCd, link)}>
            <figure className="thumb">
                <img src={`${FULL_URL}/file/${thumb}`} alt={title} />
            </figure>
            <div className="txt">
                <strong>{title}</strong>
                <p>{regDt}</p>
            </div>
        </li>
    );
};

export default PepperItem;
