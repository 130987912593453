import { createSlice } from "@reduxjs/toolkit";

export const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        bannerView: {
            id: "",
            data: null,
        },
    },
    reducers: {
        bannerGetView: (state, { payload: id }) => {
            state.bannerView.id = id;
            state.bannerView.data = null;
        },
        bannerGetViewSuccess: (state, { payload }) => {
            state.bannerView.data = payload;
        },
    },
});

export const { bannerGetView, bannerGetViewSuccess } = bannerSlice.actions;
