import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 리스트
export const attendanceListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/defaultAttendanceCheck",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response.data;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 출석체크
export const attendanceCheckAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/setAttendanceCheck",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 당첨 정보 입력
export const attendanceApplyAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/setAttendanceRecipient",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
