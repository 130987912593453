export const reducers = {
    eventVoting: (state, { payload: playerId, matchId }) => {
        state.vote.player_id = playerId;
        state.vote.match_id = matchId;
        state.vote.data = null;
    },
    eventVotingSuccess: (state, { payload }) => {
        state.vote.data = payload;
    },
    eventVoteCheck: (state, { payload: { matchId, player_id } }) => {
        state.voteCheck.match_id = matchId;
        state.voteCheck.data = null;
        state.voteCheck.player_id = player_id;
    },
    eventVoteCheckSuccess: (state, { payload }) => {
        state.voteCheck.data = payload;
    },
    eventLeagues: (state) => {
        state.leagues.data = null;
        state.leagues.code = null;
    },
    eventLeaguesSuccess: (state, { payload: { data, code } }) => {
        state.leagues.data = data;
        state.leagues.code = code;
    },
    eventApply: (
        state,
        { payload: { match_id, victory_id, agree, name, phone } },
    ) => {
        state.apply.match_id = match_id;
        state.apply.victory_id = victory_id;
        state.apply.agree = agree;
        state.apply.name = name;
        state.apply.phone = phone;
        state.apply.data = null;
    },
    eventApplySuccess: (state, { payload }) => {
        state.apply.data = payload;
    },
};
