import { createSlice } from "@reduxjs/toolkit";

export const applySlice = createSlice({
    name: "apply",
    initialState: {
        code: 0,
        data: null,
        message: null,
        EssnCode: 0,
        EssnData: null,
        EssnMessage: null,
    },
    reducers: {
        postApply: (state) => {
            state.code = 0;
            state.message = null;
        },
        postApplySuccess: (state, { payload: { code, data, msg } }) => {
            state.code = code;
            state.message = msg;
            state.data = data;
        },
        getApplyEssnInfo: (state) => {
            state.EssnCode = 0;
            state.EssnData = null;
            state.EssnMessage = null;
        },
        getApplyEssnInfoSuccess: (state, { payload: { code, data, msg } }) => {
            state.EssnCode = code;
            state.EssnData = data;
            state.EssnMessage = msg;
        },
    },
});

export const {
    postApply,
    postApplySuccess,
    getApplyEssnInfo,
    getApplyEssnInfoSuccess,
} = applySlice.actions;
