// 로그인
import request from "../../../utils/api";
import { handleError } from "../../../libs/js/common";
import { LOGIN_URL } from "../../../constant/contant";

// 로그인
export const loginAPI = async () => {
    try {
        const response = await request({
            baseURL: LOGIN_URL,
            method: "post",
            url: "/login",
            data: {
                check_yn: "Y",
                device_token:
                    "f1Z-ZJo4QyCzO4Jobw_tnb:APA91bFBYHS2676zZmR_UZoCbtgrhUy4v7SeQAdd7VLa00FOaDxbnpNhwqUakqRbMDIR0RjXbX60jRf9sIxVu_e-QkJSFwSQB1hO4DwoNsup5zzwDwTQOGrM30MFC3cuaUd3D2gBp1ts",
                email: "aipepperspZBiovRQCA/rHYVGwzcpsQ==\n",
                password: "aipeppersoDI0Yoe/v21BErCG4K2yPg==\n",
            },
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 로그아웃
export const logoutAPI = async () => {
    try {
        const response = await request({
            baseURL: LOGIN_URL,
            method: "post",
            url: "/logout",
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

//로그인 세션 체크
export const loginCheckedAPI = async () => {
    try {
        const response = await request({
            baseURL: LOGIN_URL,
            method: "post",
            url: "/sessionChk",
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
