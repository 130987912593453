import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { playerGetList } from "./module";
import PlayerTabs from "./playerTabs";
import Athlete from "./athlete";
import Infinite from "../../components/infinite/infinite";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const PlayerList = () => {
    const { type, data, count } = useSelector((state) => ({
        type: state.player.list.type,
        data: state.player.list.data,
        count: state.player.list.count,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
        } else {
            TrackGoogleAnalyticsEvent('maintab1_banner4_선수소개_click','maintab1_banner4_선수소개_click','maintab1_banner4_선수소개_click');
        }
    }, []);

    const [playerPosition, setPlayerPosition] = useState(type);
    // tab
    const handleTabClick = (name) => {
        if (playerPosition !== name) {
            setPlayerPosition(name);
        }
    };

    const onItemComponent = (post) => (
        <Athlete post={post} key={post.id} type="basic" />
    );

    const onNoneResultComponent = () => (
        <span className="description">선수리스트가 없습니다.</span>
    );

    return (
        <div className="player-wrap">
            <div className="inner">
                <PlayerTabs
                    type={playerPosition}
                    handleTabClick={handleTabClick}
                />
                <Infinite
                    listClass="player-list-wrap"
                    count={count}
                    data={data}
                    activeTab={playerPosition}
                    onGetList={playerGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                    isAllShow={true}
                />
            </div>
        </div>
    );
};

export default PlayerList;
