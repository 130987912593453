import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./event.reducer";

export const eventSlice = createSlice({
    name: "event",
    initialState: {
        vote: {
            player_id: null,
            match_id: null,
            data: null,
        },
        voteCheck: {
            match_id: null,
            data: null,
            player_id: null,
        },
        leagues: {
            data: null,
            code: null,
        },
        apply: {
            match_id: null,
            victory_id: null,
            agree: null,
            name: null,
            phone: null,
            data: null,
        },
    },
    reducers,
});

export const {
    eventVoting,
    eventVotingSuccess,
    eventVoteCheck,
    eventVoteCheckSuccess,
    eventLeagues,
    eventLeaguesSuccess,
    eventApply,
    eventApplySuccess,
} = eventSlice.actions;
