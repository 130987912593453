import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { APPLE_EVENT_MESSAGE, IOS } from "../../constant/contant";
import { handleAppBridge, scrollError } from "../../libs/js/common";
import { BtnDefault } from "../../components/common/common";
import { getApplyEssnInfo, postApply } from "./module";
import { luckyDrawApplyAPI } from "../luckyDraw/API/luckyDrawAPI";
import { attendanceApplyAPI } from "../attendance/API/attendanceAPI";
import { matchVictoryInsertInfoAPI } from "../matchPrediction/API/matchPredictionAPI";
import { getApplyEventAPI } from "../event/API/eventAPI";
import { useLocation } from "react-router-dom";
import { eventVoting } from "../event/module";
import { encrypt } from "../../libs/js/ase256";
import useLoginCheck from "../../hooks/useLoginCheck";

const Apply = ({ noteTitle, noteList }) => {
    const dispatch = useDispatch();
    const {
        event_id,
        attend_id,
        match_id,
        victory_id,
        event_match,
        player_id,
    } = useParams();
    const location = useLocation();
    const [endDate] = useState(location.state?.endDate);
    const pageType = location.state?.pageType;

    const isLogin = useLoginCheck();

    const { code, message, EssnCode, EssnMessage, EssnData } = useSelector(
        (state) => ({
            code: state.apply.code,
            message:
                state.apply.data === null
                    ? state.apply.message
                    : state.apply.data.msg,
            EssnCode: state.apply.EssnCode,
            EssnMessage: state.apply.EssnMessage,
            EssnData: state.apply.EssnData,
        }),
    );

    const voteData = useSelector((state) => state.event.vote.data);

    const isLuckyDraw = !!event_id;
    const isAttendance = !!attend_id;
    const isMatch = !!match_id;
    const isEvent = !!event_match;

    const [isMount, setIsMount] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        phone: "",
        agree: false,
    });
    const [isUserName, setIsUserName] = useState(true);
    const [isUserPhone, setIsUserPhone] = useState(true);
    const [isUserAgree, setIsUserAgree] = useState(true);

    const handleInputChange = (e) => {
        const target = e.target;
        const { name } = target;
        let value = "";

        if (name === "name") {
            //value = target.value.replace(/[^a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|·]/g, "");
            value = target.value.replace(
                /[^가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z0-9]/gi,
                "",
            );
            setIsUserName(!!value);
        } else if (name === "phone") {
            value = target.value.replace(/[^0-9]/g, "");
            setIsUserPhone(/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(value));
        } else if (name === "agree") {
            value = target.checked;
            setIsUserAgree(!!value);
        }

        if (value !== userInfo[name]) {
            setUserInfo((userInfo) => ({
                ...userInfo,
                [name]: value,
            }));
        }
    };

    // 응모하기 버튼 클릭 시
    const handleApply = () => {
        const { name, phone, agree } = userInfo;

        // 벨리데이션 체크
        !name && setIsUserName(false);
        !phone && setIsUserPhone(false);
        !agree && setIsUserAgree(false);

        if (!(name && phone.length >= 10 && agree)) {
            return false;
        }

        // 로그인 여부 체크
        if (isLogin) {
            let message = `이름 : ${name}\n휴대폰 번호 : ${phone}\n입력하신 정보가 맞으신가요?\n등급에 따라 포인트가 차감될 수 있습니다.`;
            if( isAttendance ) {
                message = `이름 : ${name}\n휴대폰 번호 : ${phone}\n입력하신 정보가 맞으신가요?`;
            }

            if (window.confirm(message)) {
                if (isEvent) {
                    // 수훈선수 투표 정보 저장
                    // 내 참여정보까지 입력해야 최종 이벤트 참여 완료
                    dispatch(
                        eventVoting({
                            match_id: event_match,
                            player_id: player_id,
                        }),
                    );
                } else {
                    handlePostApply();
                }
            }
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 디스패치
    const handlePostApply = () => {
        dispatch(getApplyEssnInfo());
    };

    // 암호화

    useEffect(() => {
        if (EssnCode === "200") {
            const { crypt_pass_value, crypt_iv_value } = EssnData;
            const options = {
                name: userInfo.name,
                phone: encrypt(
                    userInfo.phone,
                    crypt_pass_value,
                    crypt_iv_value,
                ),
                agree: "Y",
            };

            // 페이지 분기
            if (isLuckyDraw) {
                options.id = event_id;
                options.callAPI = luckyDrawApplyAPI;
            } else if (isAttendance) {
                options.attend_id = attend_id;
                options.callAPI = attendanceApplyAPI;
            } else if (isMatch) {
                options.matchId = match_id;
                options.victoryId = victory_id;
                options.callAPI = matchVictoryInsertInfoAPI;
            } else if (isEvent) {
                options.matchId = event_match;
                options.callAPI = getApplyEventAPI;
            }

            dispatch(postApply(options));
        }
    }, [EssnCode]);

    // 마운트 시
    useEffect(() => {
        setIsMount(true);
    }, []);

    // 응모기간 경과 여부 체크
    useEffect(() => {
        if (isMount && endDate) {
            const onCallback = () => {
                alert(`응모 가능한 기간이 지나\n이전 페이지로 돌아갑니다.`);

                handleAppBridge({
                    fnName: "goBack",
                });
            };

            if (new Date() >= endDate) {
                // 로드 되자마자 경과 시
                onCallback();
            } else {
                const interval = setInterval(() => {
                    if (new Date() >= endDate) {
                        clearInterval(interval);
                        onCallback();
                    }
                }, 1000);
            }
        }
    }, [isMount]);

    // API 호출 성공 시
    useEffect(() => {
        if (isMount && code) {
            if (message === null && code === "200") {
                alert("제출 완료 되었습니다.");
            } else {
                alert(message);
            }

            if (code === "200") {
                handleAppBridge({
                    fnName: "goBack",
                });
            }
        }
    }, [code]);

    // 수훈선수 이벤트 참여 API 호출 성공 시
    useEffect(() => {
        // console.log(voteData);
        if (voteData) {
            const { code, msg } = voteData;
            // console.log(voteData);
            if (code === "200") {
                handlePostApply();
            } else {
                alert(msg);
            }
        }
    }, [voteData]);

    useEffect(() => {
        if (pageType === "view" && IOS) {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    if (!isMount) return false;

    return (
        <div className="lucky-draw-apply">
            <div className="inner">
                <dl className="apply-list">
                    <dt className="required">이름</dt>
                    <dd>
                        <label>
                            <input
                                name="name"
                                type="text"
                                placeholder="수령인과 동일하게 작성해주세요."
                                value={userInfo.name}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        {!isUserName && (
                            <p className="validation-message">
                                이름을 입력해 주세요.
                            </p>
                        )}
                    </dd>
                    <dt className="required">휴대폰 번호</dt>
                    <dd>
                        <label>
                            <input
                                type="text"
                                name="phone"
                                placeholder="( - ) 제외하고 입력해 주세요."
                                maxLength="11"
                                inputMode="numeric"
                                value={userInfo.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        {!isUserPhone && (
                            <p className="validation-message">
                                {userInfo.phone.length === 0
                                    ? "휴대폰 번호를 입력해 주세요."
                                    : "형식에 맞지 않는 번호입니다."}
                            </p>
                        )}
                    </dd>
                </dl>
                <div className="point-note">
                    <h2 className="title required">{noteTitle}</h2>
                    <ul className="text-list-dot point-note-list">
                        {noteList.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                        {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                    </ul>
                </div>
                <div className="button-wrap-single">
                    <label className="checkbox-basic">
                        <input
                            type="checkbox"
                            name="agree"
                            onChange={handleInputChange}
                        />
                        <span className="text">동의합니다.</span>
                    </label>
                    {!isUserAgree && (
                        <p className="validation-message">
                            유의사항에 동의해 주세요.
                        </p>
                    )}
                    <BtnDefault
                        text={
                            isAttendance || isMatch
                                ? "제출하기"
                                : "응모하기 (-1,000P)"
                        }
                        size="large"
                        onClick={handleApply}
                    />
                </div>
            </div>
        </div>
    );
};

export default Apply;
