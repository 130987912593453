import React, { useEffect, useState } from "react";
import { FULL_URL } from "../../constant/contant";
import { handleAppBridge } from "../../libs/js/common";

const MerchandiseItem = ({ post }) => {
    const { title, price, discount_rate, giftUrl, image, available_nm } = post;
    const [isLoading, setIsLoading] = useState(true);

    const handleClick = () => {
        if (giftUrl) {
            handleAppBridge({
                fnName: "onOpenPage",
                param: giftUrl,
            });
        }
    };

    useEffect(() => {
        if (post) {
            setIsLoading(false);
        }
    }, [post]);

    if (isLoading) return false;

    const priceInt = parseInt(price);
    const discount = parseInt(discount_rate);
    const finalPrice = (
        priceInt -
        (priceInt * discount) / 100
    ).toLocaleString();

    return (
        <li>
            <div className="merchandise-description" onClick={handleClick}>
                <div className="image-wrap">
                    <img src={`${FULL_URL}/file/${image}`} alt={title} />
                </div>
                <ul className="detail-list">
                    {available_nm && (
                        <li className="age">사용연령: {available_nm}</li>
                    )}

                    <li className="title">{title}</li>
                    {discount > 0 && (
                        <li className="price">
                            <span className="discount">{discount}%</span>
                            <span className="num">
                                {priceInt.toLocaleString()}원
                            </span>
                        </li>
                    )}
                    <li className="final-price">{finalPrice}원</li>
                </ul>
            </div>
        </li>
    );
};

export default MerchandiseItem;
