export const reducers = {
    applicationGetList: (state, { payload }) => {
        state.list.count = 0;
        state.list.data = null;
        state.list.code = null;
    },
    applicationGetListSuccess: (state, { payload: { count, data, code } }) => {
        state.list.count = count;
        state.list.data = data;
        state.list.code = code;
    },
};
