import React, { useEffect, useState, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { broadcastInfo } from "./module";
import io from "socket.io-client";
import ReactHlsPlayer from "react-hls-player";
import { handleAppBridge } from "../../libs/js/common";
import useLoginCheck from "../../hooks/useLoginCheck";
import picSample from "../../libs/images/broadcast/pic_sample.png";
import BroadcastComment from "./broadcastComment";
import { FULL_URL } from "../../constant/contant";

const Boradcast = () => {

    const isLogin = useLoginCheck();

    // 로그인 여부 체크
    if (!isLogin) {
        handleAppBridge({
            fnName: "goLogin",
        });
    }

    /*
    handleAppBridge({
        fnName: "getUserInfo",
        callbackName: "handleBroadcastSet",
    });
    
    useEffect(() => {
        window.handleBroadcastSet = new CustomEvent("handleBroadcastSet");
        const nativeEventCallback = (t) => {
            roomInfo.userName = t.detail.userName;
            roomInfo.userRole = t.detail.userRole;
            roomInfo.userId = t.detail.userId;
            roomInfo.token = t.detail.token;
        };
    
        window.addEventListener("handleBroadcastSet", nativeEventCallback);
    
        // event listener clean up
        return () => {
            window.removeEventListener("handleBroadcastSet", nativeEventCallback);
        };
    }, []);
    */

    const VideoPlayer = useMemo(() => {
        return (
            <ReactHlsPlayer
                src="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                // src="http://101.101.219.64:8088/live/debug/index.m3u8"
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
            />
        );
    }, []);

    const { data } = useSelector((state) => ({
        data: state.broadcast.info.data,
    }));
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(broadcastInfo());
    }, []);

    const [showUserList, setSowUserList] = useState(false);
    const userListState = () => {
        setSearchUserList(userList);
        setSowUserList(!showUserList);
    };
    const [chatValue, setChatValue] = useState("");
    const [chatList, setChatList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [searchUserList, setSearchUserList] = useState([]);
    const [userListCnt, setUserListCnt] = useState(0);
    let chatArr = [];
    let userArr = [];

    const handleRed = (userId) => {
        if(window.confirm("사용자를 강제 퇴장 시키고, 이 방송에\n더 이상 참여하지 못하게 합니다.")) {
            socket.emit("red-card", {
                token : roomInfo.token,
                userId : roomInfo.userId,
                roomName : roomInfo.roomName,
            });
        }
    }

    const handleReport = (userId) => {
        handleAppBridge({
            fnName: "onReportLive",
            param: [userId],
        });
    }

    const handleSearch = (e) => {
        const val = e.target.value.toLowerCase();
        setSearchUserList(userList.filter((item) => item.userName.toLowerCase().indexOf(val) > -1));
    }

    let roomInfo = {
        userName: "TEST",
        userRole: "player",
        userId: "TEST",
        token: "1",
        roomName: "TEST230725",
        roomTitle: "하하하하하",
        isAudio: true,
        isVideo: true,
    };

    const socket = io("http://101.101.219.64:3331?sign=aaaasign", {
        cors: {
            origin: "*",
            methods: ["GET", "POST"],
            credentials: true,
        },
        forceNew: true,
        transports: ["websocket"],
    });

    useEffect(() => {
        const param = {
            token: roomInfo.token,
            userId: roomInfo.userId,
            userName: roomInfo.userName,
            message: chatValue,
            roomName: roomInfo.roomName,
            role: "ADMIN",
        };

        socket.emit("send-message", param);
    }, [chatValue]);

    useEffect(() => {
        socket.on("connect", () => {
            socket.emit("join-room", roomInfo);
            socket.emit("get-user-list", roomInfo);
        });

        socket.on("red-card-return", () => {
            socket.emit("get-user-list", roomInfo);
        });

        socket.on("user-list-return", (sData) => {
            if (Array.isArray(sData)) {
                userArr = [];
                setUserListCnt(
                    sData.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                );
                for (const user of sData) {
                    userArr = [
                        ...userArr,
                        {
                            status: user.status,
                            isRedCard: user.isRedCard,
                            createdAt: user.createdAt,
                            lastAt: user.lastAt,
                            _id: user._id,
                            userName: user.userName,
                            userId: user.userId,
                            roomName: user.roomName,
                            roomTitle: user.roomTitle,
                            socketId: user.socketId,
                            __v: user.__v,
                            profile_pic: user.profile_pic,
                            role: user.role,
                        },
                    ];
                }
            }

            setUserList(userArr);
            setSearchUserList(userArr);
        });

        socket.on("send-message-return", (sData) => {
            const sMsg = sData.msg;

            chatArr = [
                ...chatArr,
                {
                    message: sMsg.message,
                    role: sMsg.role,
                    userId: sMsg.userId,
                    userName: sMsg.userName,
                    creatAt: sMsg.creatAt,
                    msg_key: sMsg.msg_key,
                },
            ];

            if (sMsg.userId === null || sMsg.userId === "") {
                socket.emit("get-user-list", roomInfo);
            }

            setChatList(chatArr);
        });

        return () => {
            socket.emit("leave-room", roomInfo);
            socket.disconnect();
        };
    }, []);

    // if (!data) return false;
    return (
        <>
            <div className="realtime_chat_wrap">
                <div className="live_game">{VideoPlayer}</div>
                <div className="realtime_chat_ttl">
                    <strong>
                        실시간 채팅
                        <i className="live">LIVE</i>
                    </strong>
                    <button onClick={userListState} id="userTotalCount">
                        {userListCnt}
                    </button>
                </div>
                <div className="chat_list_wrap">
                    <ul id="chat_list_ul">
                        {chatList.map((chat) => {
                            if (chat.message !== null && chat.message !== "") {
                                return (
                                    <li
                                        key={chat.msg_key}
                                        className={
                                            chat.userId === "" ? ""
                                            : chat.userId === roomInfo.userId ? "to"
                                            : "from"
                                        }
                                    >
                                        {chat.userId !== "" &&
                                            <div className="card_t">
                                                <strong>
                                                    {chat.userName}
                                                </strong>
                                            </div>
                                        }
                                        <p>{chat.message}</p>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
            <div className="fixed_chat_input">
                <BroadcastComment setChatValue={setChatValue} />
            </div>
            {showUserList === true && (
                <>
                    <div className="ban_chat_layer_dimm" onClick={userListState}></div>
                    <div className="ban_chat_layer">
                        <div className="ban_chat_layer_top"></div>
                        <p className="now_watching" id="userTotalCountMsg">
                            {userListCnt}명 시청 중
                        </p>
                        <div className="chat_search_box">
                            <i></i>
                            <input id="" type="text" onKeyUp={handleSearch} />
                        </div>
                        <ul className="now_watching_list">
                            {searchUserList.map((user) => {
                                return (
                                    <li key={user._id}>
                                        <img alt="" src={(user.profile_pic === null || user.profile_pic === "") ? picSample : `${FULL_URL}/file/${user.profile_pic}`} />
                                        {user.role === "player" && (
                                            <p className="icon_p">
                                                {user.userName}
                                            </p>
                                        )}
                                        {user.role !== "player" && (
                                            <p>{user.userName}</p>
                                        )}
                                        {user.userId !== roomInfo.userId && (
                                        <div className="btn_wrap">
                                            {user.role === "player" && user.userId !== roomInfo.userId && (
                                                <button className="red" onClick={()=>handleRed(user.userId)}>
                                                    퇴장
                                                </button>
                                            )}
                                            <button className="gray" onClick={()=>handleReport(user.userId)}>
                                                신고
                                            </button>
                                        </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
};

export default Boradcast;
