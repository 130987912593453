import { put, takeEvery, call } from "redux-saga/effects";
import {
    luckyDrawApplyCheckAPI,
    luckyDrawListAPI,
    luckyDrawViewAPI,
    luckydrawWinYnAPI,
} from "../API/luckyDrawAPI";
import {
    luckyDrawGetApplyCheck,
    luckyDrawGetApplyCheckSuccess,
    luckyDrawGetList,
    luckyDrawGetListSuccess,
    luckyDrawGetView,
    luckyDrawGetViewSuccess,
    luckyDrawWinnerYn,
    luckyDrawWinnerYnSuccess,
} from "./luckyDraw.slice";

// 리스트
function* getList(action) {
    const { payload: params } = action;

    try {
        const result = yield call(luckyDrawListAPI, params);

        yield put(
            luckyDrawGetListSuccess({
                count: result.count,
                data: result.data,
            }),
        );
    } catch (error) {
        console.error(error);
    }
}

export function* luckyDrawListSaga() {
    yield takeEvery(luckyDrawGetList, getList);
}

// 상세
function* getView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(luckyDrawViewAPI, params);
        yield put(luckyDrawGetViewSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* luckyDrawViewSaga() {
    yield takeEvery(luckyDrawGetView, getView);
}

// 응모 여부 조회
function* getApplyCheck(action) {
    const { payload: params } = action;

    try {
        const data = yield call(luckyDrawApplyCheckAPI, params);
        yield put(luckyDrawGetApplyCheckSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* luckyDrawApplyCheckSaga() {
    yield takeEvery(luckyDrawGetApplyCheck, getApplyCheck);
}

// 상세
function* getWinnerYn(action) {
    const { payload: params } = action;

    try {
        const data = yield call(luckydrawWinYnAPI, params);
        yield put(luckyDrawWinnerYnSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* luckyDrawWinnerYnSaga() {
    yield takeEvery(luckyDrawWinnerYn, getWinnerYn);
}
