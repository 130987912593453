import { createSelector } from 'reselect';

const getStaffState = (state) => state.staff;

export const getTabList = createSelector(
    [getStaffState],
    (staffState) => staffState.menuList.data
);

export const getStaff = createSelector(
    [getStaffState],
    (staffState) => staffState.list
);