import axios from "axios";
import { API_KEY, API_URL } from "../constant/contant";


const request = axios.create({
    baseURL:  API_URL,
    headers: {
        "api-key": API_KEY,
        "Accept": "application/json; charset=UTF-8",
        "Content-Type": "application/json; charset=UTF-8",
    },
    withCredentials: true,
});

// 요청 인터셉터
request.interceptors.request.use(
    // 요청 보내기 전
    (config) => {
        return {
            data: {},
            ...config,
        };
    },
    // 요청 오류 시
    (error) => {
        return Promise.reject(error);
    },
);

// 응답 인터셉터
request.interceptors.response.use(
    // 응답 데이터 있을 경우
    (response) => {
        return response.data;
    },
    // 응답 오류 시
    (error) => {
        return Promise.reject(error);
    },
);

export default request;
