import React from "react";
import {
    handleAppBridge,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import { FULL_URL, TOKEN } from "../../constant/contant";
import { BtnDefault } from "../../components/common/common";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const NextMatch = ({ resData, useListParam }) => {
    const { search } = window.location;
    const { league_detail_id } = resData;
    const navigate = useNavigate();

    const { loginCheck } = useSelector((state) => ({
        loginCheck: state.auth.loginCheck,
    }));

    if (useListParam.league_detail_id === league_detail_id) {
        return nextMatch(resData, search, navigate, loginCheck);
    } else {
        return null;
    }
};

/* NEXT MATCH MARKUP */
const nextMatch = (resData, search, navigate, loginCheck) => {
    const {
        match_date,
        gubun,
        homeTeam,
        oppTeam,
        stadium,
        live_url,
        ticketing_url,
        id: matchId,
        isApply,
        status,
        seat_url,
        final_score,
        home_win,
        home_score,
        oneSidedUseYn,
        oneSidedUrl,
    } = resData;

    const home_team_logo = `${FULL_URL}/file/${homeTeam.team_pic_url}`;
    const away_team_logo = `${FULL_URL}/file/${oppTeam.team_pic_url}`;

    return (
        <div className="macth-banner-wrap">
            <div
                className={
                    gubun === "Y" && status === "MS03"
                        ? "match-banner-box end-home"
                        : gubun === "N" && status === "MS03"
                        ? "match-banner-box end-away"
                        : gubun === "Y" &&
                          (status === "MS02" || status === "MS01")
                        ? "match-banner-box home"
                        : gubun === "N" &&
                          (status === "MS02" || status === "MS01")
                        ? "match-banner-box away"
                        : null
                }
            >
                <div className="match-bn-inr">
                    <div className="match-bn-hd">
                        <div className="match-hd-left">
                            {gubun === "Y" ? (
                                <span className="match-type home">HOME</span>
                            ) : (
                                <span className="match-type away">AWAY</span>
                            )}
                            <span className="match-stadium">{stadium}</span>
                        </div>

                        {/*{seat_url !== null && gubun === "Y" && (*/}
                        {/*    <div className="match-hd-right">*/}
                        {/*        <BtnDefault*/}
                        {/*            text={"3D 좌석보기"}*/}
                        {/*            className={"btn 3dLink"}*/}
                        {/*            onClick={outLinkAppPost}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    <div className="match-bn-container">
                        <div className="match-home match-team">
                            <div className="team-logo home">
                                <img src={home_team_logo} alt="" />
                            </div>
                            <div className="team-name">{homeTeam.owner}</div>
                        </div>
                        <div className="match-vs match-team">
                            {setDateFormat(match_date)}
                            {status === "MS03" && home_score !== null && (
                                <div className="match-total-score">
                                    {home_win ? (
                                        <span className="win-team-type active">
                                            승
                                        </span>
                                    ) : (
                                        <span className="win-team-type">
                                            패
                                        </span>
                                    )}

                                    <span className="next-match-score">
                                        {final_score}
                                    </span>

                                    {home_win ? (
                                        <span className="win-team-type">
                                            패
                                        </span>
                                    ) : (
                                        <span className="win-team-type active">
                                            승
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="match-away match-team">
                            <div className="team-logo home">
                                <img src={away_team_logo} alt="" />
                            </div>
                            <div className="team-name">{oppTeam.owner}</div>
                        </div>
                    </div>
                </div>
            </div>
            {oneSidedUseYn === "Y" && (
                <>
                    <div className="match-btn-box">
                        {markUpLiveAndTicketBtn(
                            status,
                            ticketing_url,
                            live_url,
                            navigate,
                        )}
                        {markUpPartiality(
                            status,
                            oneSidedUseYn,
                            oneSidedUrl,
                            navigate,
                        )}
                    </div>
                    <div className="match-btn-box">
                        {markUpMatchPrediction(
                            status,
                            isApply,
                            matchId,
                            search,
                            navigate,
                            loginCheck,
                        )}
                    </div>
                </>
            )}
            {oneSidedUseYn !== "Y" && (
                <>
                    <div className="match-btn-box">
                        {markUpLiveAndTicketBtn(
                            status,
                            ticketing_url,
                            live_url,
                            navigate,
                        )}
                        {markUpMatchPrediction(
                            status,
                            isApply,
                            matchId,
                            search,
                            navigate,
                            loginCheck,
                        )}
                    </div>
                </>
            )}
        </div>
    );

};

// 라이브 & 티켓버튼 마크업
const markUpLiveAndTicketBtn = (status, ticketing_url, live_url) => {
    const outLinkAppTicket = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: ticketing_url,
        });
    };

    return (
        <>
            {status !== "MS03" && (
                <BtnDefault
                    text={!live_url ? "티켓 예매하기" : "LIVE 경기"}
                    size="block"
                    href={!live_url ? live_url : ''}
                    onClick={
                        !live_url && !ticketing_url 
                        ? () => alertMsg("구매 가능한 티켓 정보가 없습니다.") 
                        : ticketing_url 
                        ? outLinkAppTicket
                        : null
                    }
                    className="btn-gradient-red"
                />
            )}
        </>
    );
};

// 편파판정 마크업
const markUpPartiality = (status, oneSidedUseYn, oneSidedUrl) => {
    return (
        <>
            {status !== "MS03" && oneSidedUseYn === "Y" && (
                <BtnDefault
                    text="편파중계 LIVE"
                    size="block"
                    href={oneSidedUrl}
                    className="btn-gradient-red"
                />
            )}
        </>
    );
};

// 승부예측 버튼 마크업
const markUpMatchPrediction = (
    status,
    isApply,
    matchId,
    search,
    navigate,
    loginCheck,
) => {
    return (
        <>
            {loginCheck.result === "Y" ? (
                <>
                    {isApply === "0" ? (
                        <BtnDefault
                            text={
                                status === "MS01"
                                    ? "승부예측 참여하기"
                                    : "승부예측 현황보기"
                            }
                            size="block"
                            onClick={
                                status === "MS01"
                                    ? () => {
                                          navigate(
                                              `/matchPrediction/${matchId}${search}`,
                                              scrollObj,
                                          );
                                      }
                                    : status === "MS02"
                                    ? () =>
                                          alertMsg(
                                              "현재 게임이 진행 중입니다. \n다음 경기에 참여해 주세요. ",
                                          )
                                    : () =>
                                          alertMsg(
                                              "종료된 경기입니다. \n다음 경기에 참여해 주세요.",
                                          )
                            }
                            className="btn-gradient-black"
                        />
                    ) : (
                        <BtnDefault
                            text="승부예측 현황보기"
                            size="block"
                            onClick={() => {
                                navigate(
                                    `/matchPredictionView/${matchId}${search}`,
                                    scrollObj,
                                );
                            }}
                            className="btn-gradient-black"
                        />
                    )}
                </>
            ) : (
                <BtnDefault
                    text={
                        status === "MS01"
                            ? "승부예측 참여하기"
                            : "승부예측 현황보기"
                    }
                    size="block"
                    onClick={
                        status === "MS01"
                            ? () => {
                                  navigate(
                                      `/matchPrediction/${matchId}${search}`,
                                      scrollObj,
                                  );
                              }
                            : status === "MS02"
                            ? () =>
                                  alertMsg(
                                      "현재 게임이 진행 중입니다. \n다음 경기에 참여해 주세요. ",
                                  )
                            : () =>
                                  alertMsg(
                                      "종료된 경기입니다. \n다음 경기에 참여해 주세요.",
                                  )
                    }
                    className="btn-gradient-black"
                />
            )}
        </>
    );
};

const appLogin = (loginCheck) => {
    if (loginCheck?.data === "N") {
        handleAppBridge({
            fnName: "goLogin",
        });
        return false;
    }
};

const alertMsg = (msg) => {
    alert(msg);
};

export default NextMatch;
