import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./faq.reducer";

const initialState = {
    list: {
        type: "FAQ01",
        data: null,
    },
};

export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers,
});

export const { faqGetList, faqGetListSuccess } = faqSlice.actions;
