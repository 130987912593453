import React, { useEffect, useState } from "react";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useParams } from "react-router";
import { luckyDrawGetView } from "./module";
import { useDispatch, useSelector } from "react-redux";
import LuckyDrawViewItem from "./luckyDrawViewItem";
import { handleAppBridge, scrollError } from "../../libs/js/common";
import { IOS } from "../../constant/contant";
import { useLocation } from "react-router-dom";

const LuckyDrawView = () => {
    const dispatch = useDispatch();
    const { event_id } = useParams();
    const location = useLocation();
    const pageType = location.state?.pageType;
    const { id, data } = useSelector((state) => ({
        id: state.luckyDraw.view.id,
        data: state.luckyDraw.view.data,
    }));

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        /**
         * id : store event ID
         * event_id : 현재 게시물 event ID
         * */

        if (id !== event_id) {
            // store에 저장된 데이터가 없을 경우에만 호출한다.

            dispatch(
                luckyDrawGetView({
                    id: event_id,
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (pageType === "view" && IOS) {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    if (!data || id !== event_id) return false;

    return <LuckyDrawViewItem post={data} />;
};

export default LuckyDrawView;
