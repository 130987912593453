import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { BtnDefault } from "../../components/common/common";

const EventIntro = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const linkType = searchParams.get("link_type");
    const id = searchParams.get("id");

    const redirectApp = () => {
        exeDeepLink(); // 어플 열기
        checkInstallApp();
    };

    useEffect(() => {
        redirectApp(); // 어플 열기
    });

    function exeDeepLink() {
        // 있을경우 어플 열기
        let url = "";
        const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.match("android") !== null) {
            url = `intent://host_aipeppers?link_type=${linkType}&id=${id}#Intent;scheme=aipeppersapp;package=kr.aipepper.app;end`; // 안드로이드 일 경우
        } else {
            // ios 일 경우
            if (linkType === "mvp") {
                // 수훈선수
                url = `aiPeppersApp://?link_type=${linkType}&id=""`;
            } else {
                // 숏츠
                url = `aiPeppersApp://?link_type=${linkType}&id=${id}`;
            }
        }

        window.location.href = url;
    }

    function checkInstallApp() {
        function clearTimers() {
            clearInterval(check);
            clearTimeout(timer);
        }

        function isHideWeb() {
            if (document.webkitHidden || document.hidden) {
                clearTimers();
            }
        }
        const check = setInterval(isHideWeb, 200);

        const timer = setTimeout(function () {
            redirectStore();
        }, 500);
    }

    const redirectStore = () => {
        // 없을경우 스토어 이동
        const ua = navigator.userAgent.toLowerCase();

        if (window.confirm("스토어로 이동하시겠습니까?")) {
            window.location.href =
                ua.indexOf("android") > -1
                    ? "https://play.google.com/store/apps/details?id=kr.aipepper.app"
                    : "https://apps.apple.com/kr/app/ai-peppers/id1664628276";
        }
    };

    return (
        <div className="deeplink-wrap">
            <div className="deeplink-inner">
                <p className="title">
                    <strong>페퍼저축은행</strong> 배구단 앱
                </p>
                <div className="text">
                    페퍼스 선수들과 팬들의{" "}
                    <span>
                        공식 소통 공간
                        <br />
                        AI페퍼스
                    </span>
                    에서 다양한 콘텐츠를 만나보세요!
                </div>
                <div className="button-wrap">
                    <BtnDefault
                        text="APP 열기"
                        onClick={exeDeepLink}
                        size="block"
                    />
                    <BtnDefault
                        text="APP 다운로드"
                        size="block"
                        onClick={redirectStore}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventIntro;
