import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 리스트
export const videoAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/getPepperLive",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 상세
export const videoViewAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/getPepperLiveDetail",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 상세 > 게시글 좋아요
export const videoLikeAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/boardLike",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 상세 > 게시글 댓글 좋아요
export const videoCommentLikeAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/boardCommentLike",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 상세 > 게시글 대댓글 대대댓글 좋아요
export const videoCommentReplyLikeAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/boardCommentReplyLike",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 상세 > 게시글 댓글 , 대댓글 , 대대댓글 등록
export const videoCommentSaveAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/boardCommentSave",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

// 댓글 신고하기
export const videoCommentReportAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "report/reportComment",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
