import { createSlice } from "@reduxjs/toolkit";

export const merchandiseSlice = createSlice({
    name: "merchandise",
    initialState: {
        count: 0,
        data: null,
    },
    reducers: {
        merchandiseGetList: (state) => {
            state.count = 0;
            state.data = null;
        },
        merchandiseGetListSuccess: (state, { payload: { count, data } }) => {
            state.count = count;
            state.data = data;
        },
    },
});

export const { merchandiseGetList, merchandiseGetListSuccess } =
    merchandiseSlice.actions;
