export const NAVIGATION = [
    {
        name: "워크 스페이스",
        path: "/workspace",
    },
    // 로그인
    {
        name: "로그인",
        path: "/login",
    },
    // 럭키드로우
    {
        name: "럭키드로우",
        path: "/luckyDraw",
    },
    {
        name: "럭키드로우",
        path: "/luckyDrawView/:event_id",
    },
    {
        name: "응모하기",
        path: "/luckyDrawApply/:event_id",
    },
    // 선수 소개
    {
        name: "선수소개",
        path: "/playerList",
    },
    {
        name: "선수소개",
        path: "/playerView/:player_id",
    },
    // 매치
    {
        name: "경기일정",
        path: "/match",
    },
    {
        name: "승부예측 이벤트",
        path: "/matchPrediction/:match_id",
    },
    {
        name: "승부예측 확인하기",
        path: "/matchPredictionView/:match_id",
    },
    {
        name: "당첨자 정보 입력",
        path: "/matchEventApply/:match_id/:victory_id",
    },
    // 구단굿즈
    {
        name: "구단상품",
        path: "/merchandise",
    },
    // 구단소개
    {
        name: "구단소개",
        path: "/about",
    },
    // 출석체크
    {
        name: "[EVENT] 출석체크를 잡아라!!",
        path: "/attendance",
    },
    {
        name: "당첨 정보 입력",
        path: "/attendanceApply/:attend_id",
    },
    // 코칭스태프
    {
        name: "코칭스태프",
        path: "/staff",
    },
    // 구단조직
    {
        name: "구단조직",
        path: "/organization",
    },
    // 페퍼 ON, 페퍼 유튜브, 아카이브
    {
        name: "페퍼 ON",
        path: "/video/:type",
    },
    {
        name: "페퍼 ON",
        path: "/video/:type/:id",
    },
    // 페퍼 라이브
    {
        name: "페퍼 LIVE",
        path: "/pepperLive/:type",
    },
    // 이벤트 > 수훈선수
    {
        name: "오늘의 수훈선수를 맞춰라!",
        path: "/event",
    },
    {
        name: "오늘의 수훈선수를 맞춰라!",
        path: "/eventVoting/:match_id",
    },
    {
        name: "오늘의 수훈선수를 맞춰라!",
        path: "/eventApply/:event_match/:player_id",
    },
    {
        name: "이벤트 응모 내역",
        path: "/application",
    },
    {
        name: "티켓",
        path: "/ticket",
    },
    {
        name: "FAQ",
        path: "/faq",
    },
    {
        name: "티켓 할인코드",
        path: "/myTicketCoupon",
    },
    {
        name: "티켓 할인코드 구매",
        path: "/ticketCouponBuy",
    },
    {
        name: "개인정보 취급 방침",
        path: "/policy",
    },
    {
        name: "이용약관",
        path: "/terms",
    },
    {
        name: "AI페퍼스 소식",
        path: "/news/:notice_id",
    },
    {
        name: "공지사항",
        path: "/noticeView/:notice_id",
    },
    // SNS
    {
        name: "SNS",
        path: "/sns",
    },
    {
        name: "SNS",
        path: "/sns/:sns_type",
    },
    // 구단 뉴스
    {
        name: "구단 뉴스",
        path: "/peppernews",
    },
    // 구단 상세
    {
        name: "구단 뉴스",
        path: "/peppernews/:news_id",
    },
    // 편파중계
    {
        name: "편파중계",
        path: "/broadcast",
    },
    // 배너페이지
    {
        name: "상세페이지",
        path: "/banner/:banner_id",
    },
    // 휴면 계정 안내
    {
        name: "휴면 계정 안내",
        path: "/dormantAccount",
    },
    // 멤버십
    {
        name: "멤버십",
        path: "/membership",
    },
    // 이디야 이벤트
    {
        name: "이디야 이벤트",
        path: "/ediya_231011",
    },
    {
        name: "스크롤 이슈",
        path: "/errorTest",
    },
    {
        name: "스크롤 이슈 뷰",
        path: "/errorTestView",
    },
    {
        name: "스크롤 이슈 뷰2",
        path: "/errorTestView1",
    },
    {
        name: "외부 연결 페이지",
        path: "/gateway/:type",
    },
];
