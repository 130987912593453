import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import BtnLike from "../../components/common/btnLike";
import Comment from "../../components/common/comment";
import VideoYoutubeDetail from "./videoYoutubeDetail";
import VideoHlsDetail from "./videoHlsDetail";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { videoGetView } from "./module";
import {
    convertSpecialCharacters,
    handleAppBridge,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import {
    videoCommentLikeAPI,
    videoCommentReplyLikeAPI,
    videoCommentSaveAPI,
    videoLikeAPI,
} from "./API/videoAPI";
import { FULL_URL } from "../../constant/contant";
import useLoginCheck from "../../hooks/useLoginCheck";
import { useSearchParams } from 'react-router-dom';
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import HLS from "hls.js";
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";
import YouTube, { YouTubeProps } from 'react-youtube';

const VideoView = () => {
    const { type, id } = useParams();
    const enterRef = useRef();
    const ref = useRef();
    const video = useRef();
    
    const dispatch = useDispatch();
    const isLogin = useLoginCheck();

    const { code, videoViewData, comments } = useSelector((state) => ({
        code: state.video.view.code,
        videoViewData: state.video.view.data,
        comments: state.video.view.comments,
    }));

    const [isLike, setIsLike] = useState(false); // 좋아요 여부
    const [isEnterActive, setIsEnterActive] = useState(false); // textarea 활성화 여부
    const [author, setAuthor] = useState(null); // 대댓글 작성자
    const [commentValue, setCommentValue] = useState(""); // 작성한 댓글
    const [currentPost, setCurrentPost] = useState(null); // 선택된 댓글 데이터
    const [videoPreText, setVideoPreText] = useState(null);
    const [videoSourceLink, setVideoSourceLink] = useState(false);
    const [commentInfo, setCommentInfo] = useState({
        type: "comment",
        comment_id: null,
        reply_comment_id: null,
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    // 첫 진입 Dispatch
    useEffect(() => {
        dispatch(videoGetView({ id }));
    }, []);

    useEffect(() => {
        if( videoViewData ) {       
            if( gb !== null ) {
                if( gb === 'm1_bplay' ) {                    
                    let preText = 'maintab1_banner_';
                    let fullText = null;
                    let replaceTitle = videoViewData.title.replace(/\s+/g,'');
                    if( replaceTitle.length > 14 ) {                        
                        replaceTitle = replaceTitle.substr(0, 14);
                    }
                    fullText = preText + replaceTitle + "_play";                    
                    setVideoPreText(fullText);
                } else if( gb === 'm2_bplay' || gb === 'm2_b' ) {
                    let preText = 'maintab2_b_';
                    let fullText = null;
                    let replaceTitle = videoViewData.title.replace(/\s+/g,'');
                    if( replaceTitle.length > 20 ) {                        
                        replaceTitle = replaceTitle.substr(0, 19);
                    }
                    fullText = preText + replaceTitle + "_play";                    
                    setVideoPreText(fullText);
                } else {
                    let preText = 'video_m_';
                    let fullText = null;
                    let replaceTitle = videoViewData.title.replace(/\s+/g,'');
                    if( replaceTitle.length > 21 ) {
                        replaceTitle = replaceTitle.substr(0, 20);
                    }
                    fullText = preText + replaceTitle + "_play";                    
                    setVideoPreText(fullText);
                }
            } else {
                let preText = 'video_m_';
                let fullText = null;
                let replaceTitle = videoViewData.title.replace(/\s+/g,'');
                if( replaceTitle.length > 21 ) {
                    replaceTitle = replaceTitle.substr(0, 20);
                }
                fullText = preText + replaceTitle + "_play";                    
                setVideoPreText(fullText);
            }

        }
    }, [videoViewData]);
    
    // 비디오 좋아요 버튼 클릭 시
    const handleVideoLike = () => {
        // API 호출하기
        videoLikeAPI({ board_id: id }) //
            .then((res) => {
                dispatch(videoGetView({ id }));
            });
    };

    // 댓글 정렬 변경 (최신순 or 좋아요순)
    const handleCommentSort = (sortType) => {
        const type = sortType === "latest" ? "latest" : "like";
        dispatch(videoGetView({ id, type }));
    };

    // 댓글 작성 시
    const handleChange = (e) => {
        const { value } = e.target;
        setIsEnterActive(value.length);
        setCommentValue(value);

        // 자동으로 높이 조절
        e.target.style.height = "20px";
        e.target.style.height =
            e.target.scrollHeight > 59 ? "59px" : e.target.scrollHeight + "px";
    };

    // textarea 포커스 아웃 시
    const handleBlur = (e) => {
        const { relatedTarget } = e;
        if (!relatedTarget || relatedTarget.className !== "btn-comment-write") {
            setAuthor(null);
        }
    };

    // 댓글 작성 버튼 클릭 시
    const handleWriteComplete = () => {
        setIsEnterActive(false);
        setCommentValue("");
        enterRef.current.style.height = "20px";

        // 댓글 등록하기
        // API 호출
        handleComment();
    };

    // 댓글 작성하기
    const handleComment = () => {
        let params = {
            board_id: id,
            comment: commentValue,
            comment_id: commentInfo.comment_id,
            reply_comment_id: commentInfo.reply_comment_id,
            type: commentInfo.type, // 일반댓글 : comment / 대댓글 : reply / 대대댓글 : replyreply
        };

        videoCommentSaveAPI(params).then(() => {
            dispatch(videoGetView({ id }));
            setCommentInfo({
                type: "comment",
            });
        });
    };

    // 댓글 좋아요 버튼 클릭 시
    const onCommentLike = (commentId, parentNickname) => {
        // API 호출
        const onLikeAPI = parentNickname
            ? videoCommentReplyLikeAPI
            : videoCommentLikeAPI;

        onLikeAPI({ comment_id: commentId }) //
            .then(() => {
                dispatch(videoGetView({ id }));
            });
    };

    useEffect(() => {
        videoViewData && setIsLike(videoViewData.like === "Y");
        if( videoViewData ) {
            setVideoSourceLink(null);
            setVideoSourceLink(videoViewData.link);
        }
    }, [videoViewData]);

    useEffect(() => {
        if( videoSourceLink && videoSourceLink !== null && videoViewData.link ) {
            let intval = null;
            let time = 0;

            InitializeGoogleAnalytics();
            if( videoViewData.link.indexOf("playlist.m3u8") > -1 ) {
                let ignore = false;
                if (HLS.isSupported()) {
                    if (window.hls) {
                        // setTimeout( async () => {
                        //     await window.hls.stopLoad();
                        //     await window.hls.detachMedia();
                        //     await window.hls.destroy();

                        //     window.hls = new HLS();
                        //     window.hls.loadSource(videoViewData.link);
                        //     window.hls.attachMedia(video.current);
                        // }, 100);
                        
                        window.hls.stopLoad();
                        window.hls.detachMedia();
                        window.hls.destroy();

                        window.hls = new HLS();
                        window.hls.loadSource(videoViewData.link);
                        window.hls.attachMedia(video.current);
                    } else {
                        window.hls = new HLS();
                        window.hls.loadSource(videoViewData.link);
                        window.hls.attachMedia(video.current);
                    }
                } else {
                    video.current.src = videoViewData.link;
                }

                video.current.plyr.on('play', (event) => {
                    intval = setInterval(() => {
                        time++; 
                        if( time === 5) {
                            TrackGoogleAnalyticsEvent(videoPreText+'_5s',videoPreText+'_5s',videoPreText+'_5s');
                        }
                        if( time === 15) {
                            TrackGoogleAnalyticsEvent(videoPreText+'_15s',videoPreText+'_15s',videoPreText+'_15s');
                        }
                        if( time === 30) {
                            TrackGoogleAnalyticsEvent(videoPreText+'_30s',videoPreText+'_30s',videoPreText+'_30s');
                        }
                        if( time === 60) {
                            TrackGoogleAnalyticsEvent(videoPreText+'_60s',videoPreText+'_60s',videoPreText+'_60s');
                        }
                    }, 1000);
                });
        
                video.current.plyr.on('pause', (event) => {
                    clearInterval(intval);
                });
        
            } else {

                // ref.current.plyr.on('play', (event) => {
                //     intval = setInterval(() => {
                //         time++; 
                //         if( time === 5) {
                //             TrackGoogleAnalyticsEvent(videoPreText+'_5s',videoPreText+'_5s',videoPreText+'_5s');
                //         }
                //         if( time === 15) {
                //             TrackGoogleAnalyticsEvent(videoPreText+'_15s',videoPreText+'_15s',videoPreText+'_15s');
                //         }
                //         if( time === 30) {
                //             TrackGoogleAnalyticsEvent(videoPreText+'_30s',videoPreText+'_30s',videoPreText+'_30s');
                //         }
                //         if( time === 60) {
                //             TrackGoogleAnalyticsEvent(videoPreText+'_60s',videoPreText+'_60s',videoPreText+'_60s');
                //         }
                //     }, 1000);
                // });
                
                // ref.current.plyr.on('pause', (event) => {
                //     clearInterval(intval);
                // });
            }
        }
    }, [videoSourceLink]);

    if (!videoViewData) return false;

    const {
        content,
        like,
        likeCnt,
        link,
        regDt,
        thumb,
        title,
        siNm,
        comCnt,
        typeName,
        aspectCd
    } = videoViewData;
    const postTitle = convertSpecialCharacters(title);
    const youtubeLink = link.split("/");
    const youtubeLinkId = youtubeLink[youtubeLink.length-1];

    const onPlayerReady = (event) => {
        event.target.pauseVideo();
    }

    let ytime = 0;
    let yIntval = null;
    const onPlayerPlay = (event) => {
        InitializeGoogleAnalytics();
        yIntval = setInterval(() => {
            ytime++; 
            if( ytime === 5) {
                TrackGoogleAnalyticsEvent(videoPreText+'_5s',videoPreText+'_5s',videoPreText+'_5s');
            }
            if( ytime === 15) {
                TrackGoogleAnalyticsEvent(videoPreText+'_15s',videoPreText+'_15s',videoPreText+'_15s');
            }
            if( ytime === 30) {
                TrackGoogleAnalyticsEvent(videoPreText+'_30s',videoPreText+'_30s',videoPreText+'_30s');
            }
            if( ytime === 60) {
                TrackGoogleAnalyticsEvent(videoPreText+'_60s',videoPreText+'_60s',videoPreText+'_60s');
            }
        }, 1000);
    }
   
    const onPlayerPause = (event) => {
        clearInterval(yIntval);
    }
   

    const el = document.querySelector("#header .page-name");
    if (el) {
        el.textContent = typeName;
    }

    const cmtLogChk = () => {
        if (!isLogin) {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    return (
        <div
            className={classNames(
                "live-posts video-view",
                type && `${type}-video`,
            )}
        >
            <div className="inner">
                <div className="live-post">
                    <div
                        className={classNames(
                            "iframe-wrap",
                            aspectCd === "PORTRAIT" ? "archive-iframe-wrap" : "youtube-iframe-wrap",
                        )}
                    >
                        {link.indexOf("playlist.m3u8") > -1 ? ( 
                            <Plyr ref={video} source={link} playsInline />
                        ) : ( 
                            // <Plyr
                            //     ref={ref}
                            //     source={{
                            //         type: "video",
                            //         sources: [
                            //             {
                            //                 src: youtubeLinkId,
                            //                 provider: "youtube"
                            //             }
                            //         ]
                            //     }}
                            //     data-plyr-provider="youtube" 
                            //     data-plyr-embed-id={youtubeLinkId}
                            // />    
                            
                            <YouTube className="youtube_box" key={youtubeLinkId} videoId={youtubeLinkId} onReady={onPlayerReady} onPlay={onPlayerPlay} onPause={onPlayerPause} onEnd={onPlayerPause} />

                        )}
                        
                    </div>
                    {/*<div className="thumb">
                        <img
                            src={`${FULL_URL}/file/${thumb}`}
                            alt={postTitle}
                        />
                    </div>*/}
                    <p className="title">{postTitle}</p>
                    <div className="live-info">
                        <span>{siNm}</span>
                        <span>{setDateFormat(regDt, "short")}</span>
                    </div>
                    <ul className="video-action-bar">
                        <li>
                            <BtnLike
                                count={likeCnt}
                                isLike={isLike}
                                id="likeVideo"
                                onChange={handleVideoLike}
                            />
                        </li>
                        <li>
                            <div className="view-comment">{comCnt}</div>
                        </li>
                    </ul>
                </div>
                <div className="video-comment">
                    <div className="comment-head">
                        <h2 className="comment-title">
                            댓글 <span className="comment-total">{comCnt}</span>
                        </h2>
                        <div className="comment-sort">
                            <label>
                                <input
                                    type="radio"
                                    name="sort"
                                    value="latest"
                                    defaultChecked={true}
                                    onChange={(e) =>
                                        handleCommentSort(e.target.value)
                                    }
                                />
                                <span className="text">최신순</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="sort"
                                    value="like"
                                    onChange={(e) =>
                                        handleCommentSort(e.target.value)
                                    }
                                />
                                <span className="text">좋아요순</span>
                            </label>
                        </div>
                    </div>
                    <div className="comment-body">
                        {comments.length > 0 ? (
                            <Comment
                                boardId={id}
                                currentPost={currentPost}
                                setCurrentPost={setCurrentPost}
                                setAuthor={setAuthor}
                                enterRef={enterRef}
                                comments={comments}
                                setCommentInfo={setCommentInfo}
                                onCommentLike={onCommentLike}
                            />
                        ) : (
                            <div className="none-result-comment">
                                <p className="title">등록된 댓글이 없습니다.</p>
                            </div>
                        )}
                    </div>
                    <div className="comment-foot">
                        <div className="enter-form">
                            <textarea
                                ref={enterRef}
                                placeholder={
                                    author
                                        ? `@${author}`
                                        : "댓글을 입력해 주세요."
                                }
                                value={commentValue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={cmtLogChk}
                            />
                            <button
                                type="button"
                                className={classNames(
                                    "btn-enter-comment",
                                    isEnterActive && "active",
                                )}
                                onClick={handleWriteComplete}
                            >
                                작성
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoView;
