import ReactGA4 from "react-ga4";
const InitializeGoogleAnalytics = () => {    
  ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID);
};

const TrackGoogleAnalyticsEvent = (
  category,
  action,
  label
) => {
  // Send GA4 Event
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };