import React, { useEffect, useState } from "react";
import { handleAppBridge } from "../../libs/js/common";
import { useDispatch, useSelector } from "react-redux";
import { gatewayGetDetail } from "./module";
import { useParams } from "react-router";
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Gateway = () => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({        
        data: state.gateway.list.data,
    }));

    const { type } = useParams();

    useEffect(() => {
        dispatch(
            gatewayGetDetail({
                "code" : type
            }),
        );
    }, []);

    useEffect(() => {
        if (data) {
            InitializeGoogleAnalytics();
            TrackGoogleAnalyticsEvent(data.cdNm,data.cdNm,data.cdNm);

            setTimeout(() => {
                window.location.href = data.url;
            }, 500);
        }
    }, [data]);

    return (
        <div className="out-link-wrap">
            <div className="cont">
                <strong>외부 연결중입니다.</strong>
                <p>잠시만 기다려주세요!</p>
            </div>
        </div>
    );
};

export default Gateway;
