export const reducers = {
    pepperGetList: (state, { payload: { type } }) => {
        state.list.type = type;
        state.list.count = 0;
        state.list.data = null;
    },
    pepperGetListSuccess: (state, { payload: { count, data } }) => {
        state.list.count = count;
        state.list.data = data;
    },
};
