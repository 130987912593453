
const convertHtml = (data) => {
    data = data.replace(/&lt;/g, "<");
    data = data.replace(/&gt;/g, ">");
    data = data.replace(/&quot;/g, '"');
    data = data.replace(/&nbsp;/g, " ");
    data = data.replace(/\n/g, "<br />");
    return data;
};

export default convertHtml;