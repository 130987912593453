import { put, takeEvery, call } from "redux-saga/effects";
import { eventListAPI, newsListAPI, newsViewAPI } from "../API/newsAPI";
import {
    eventGetList,
    eventGetListSuccess,
    noticeGetList,
    noticeGetListSuccess,
    noticeGetView,
    noticeGetViewSuccess,
} from "./news.slice";

// notice List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(newsListAPI, params);
        yield put(noticeGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* noticeListSaga() {
    yield takeEvery(noticeGetList, getList);
}

// notice View
function* getView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(newsViewAPI, params);
        yield put(noticeGetViewSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* noticeViewSaga() {
    yield takeEvery(noticeGetView, getView);
}

// event List
function* getEventList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(eventListAPI, params);
        yield put(eventGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* eventListSaga() {
    yield takeEvery(eventGetList, getEventList);
}
