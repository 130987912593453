import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const matchAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/getScheduleList",
            data: params,
        });

        const { code, msg } = response;

        // 로그인 체크 여부
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        // 시스템 얼럿
        if (code === "F500") {
            handleError();
        }

        return response.data;
    } catch (e) {
        if (e.response.status > 202) {
            handleError();
        }
    }
};

export const nextMatchAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/nextMatchBanner",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        // 시스템 얼럿
        if (code === "F500") {
            handleError();
        }

        return response;
    } catch (e) {
        if (e.response.status > 202) {
            handleError();
        }
    }
};

export const leagueSortAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/getLeagues",
            data: params,
        });

        const { code, msg } = response;
        // 시스템 얼럿
        if (code === "F500") {
            handleError();
        }

        return response.data;
    } catch (e) {
        if (e.response.status > 202) {
            handleError();
        }
    }
};
