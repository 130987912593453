import React, { useState } from "react";
import { BtnOutline02 } from "../../components/common/common";
import { Swiper, SwiperSlide } from "swiper/react";

const MerchandiseTabs = ({ activeTab, onTabClick }) => {
    const [isUpdate, setIsUpdate] = useState(false);

    const tabList = [
        {
            type: "all",
            text: "전체",
        },
        {
            type: "MD01",
            text: "유니폼",
        },
        {
            type: "MD02",
            text: "의류/모자",
        },
        {
            type: "MD03",
            text: "배구공/용품",
        },
        {
            type: "MD04",
            text: "응원/잡화",
        },
        {
            type: "MD05",
            text: "기념상품",
        },
    ];

    const handleSwiperUpdate = (swiper) => {
        if (!isUpdate) {
            swiper.updateSlides();
            setIsUpdate(true);
        }
    };

    return (
        <div className="tab-swiper">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                slideToClickedSlide={true}
                onClick={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onTouchMove={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
            >
                {tabList.map(({ type, text }) => {
                    return (
                        <SwiperSlide key={type}>
                            <BtnOutline02
                                name={type}
                                text={text}
                                active={activeTab === type}
                                onClick={() => onTabClick(type)}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default MerchandiseTabs;
