export const reducers = {
    match: {
        reducer: (state, param) => {
            const { data } = param;
            state.matchList = data;
        },
    },
    nextMatch: {
        reducer: (state, param) => {
            const { data } = param;
            state.matchNext = data;
        },
    },

    leagueSort: {
        reducer: (state, param) => {
            const { data } = param;
            state.leagueSort = data;
        },
    },
};
