import React, { useEffect, useState } from "react";
import { ediyaCpnCnt } from "./module";
import { useDispatch, useSelector } from "react-redux";

const EdiyaCpnCnt = ({ cpnCnt }) => {
    return (
        <div className="inner">
            <dl className="remain-cpn-stat">
                <dt>쿠폰 잔여 수량 :</dt>
                <dd>
                    <strong>{cpnCnt.leftCnt}</strong> / {cpnCnt.totCnt}
                </dd>
            </dl>
        </div>
    );
};

export default EdiyaCpnCnt;
