import React from "react";
import { setDateFormat } from "../../libs/js/common";
import { FULL_URL } from "../../constant/contant";

const MatchInfoBn = ({ matchInfoRes }) => {
    const { AwayTeam, HomeTeam, MatchSchedule } = matchInfoRes;
    const { gubun, stadium_nm, match_date } = MatchSchedule;

    const home_team_logo = `${FULL_URL}/file/${HomeTeam.team_pic_url}`;
    const away_team_logo = `${FULL_URL}/file/${AwayTeam.team_pic_url}`;

    return (
        <div className="match-p-info">
            <ul className="match-poster-ul">
                <li className="match-li">
                    <div className="match-inr">
                        <div className="match-list-head">
                            <div className="match-hd-left">
                                {gubun === "Y" ? (
                                    <span className="match-type home">
                                        HOME
                                    </span>
                                ) : (
                                    <span className="match-type away">
                                        AWAY
                                    </span>
                                )}
                                <span className="match-stadium">
                                    {stadium_nm}
                                </span>
                            </div>
                        </div>

                        <div className="match-content">
                            <div className="match-home match-team">
                                <div className="team-logo home">
                                    <img src={home_team_logo} alt="" />
                                </div>
                                <div className="team-name">
                                    {HomeTeam.owner}
                                </div>
                            </div>
                            <div className="match-info match-team">
                                <p className="match-info-date">
                                    {setDateFormat(match_date)}
                                </p>
                                <div className="match-play-type">승부예측</div>
                            </div>
                            <div className="match-away match-team">
                                <div className="team-logo home">
                                    <img src={away_team_logo} alt="" />
                                </div>
                                <div className="team-name">
                                    {AwayTeam.owner}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default MatchInfoBn;
