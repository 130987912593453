import React from "react";
import { Link } from "react-router-dom";
import Membership from "../membership/membership";

const WorkStatus = ({ type }) => {
    const getWorkType = () => {
        switch (type) {
            case "수정":
                return "modify";
            case "완료":
                return "complete";
            case "진행중":
                return "ongoing";
            default:
                return "ongoing";
        }
    };

    return (
        <td className={`workspace-status ${getWorkType()}`}>
            {type ? type : ""}
        </td>
    );
};

const ApiStatus = ({ isChange }) => {
    const isApiStatus = () => {
        switch (isChange) {
            case "O":
                return "O";
            case "X":
                return "X";
            case "-":
                return "-";
            default:
                return "미사용";
        }
    };

    return <td className="api-change">{isApiStatus()}</td>;
};

const LiveServer = ({ isChange }) => {
    const liveServer = () => {
        switch (isChange) {
            case "O":
                return "O";
            case "X":
                return "X";
            case "-":
                return "-";
            default:
                return "미사용";
        }
    };

    return <td className="api-change">{liveServer()}</td>;
};

const Workspace = () => {
    return (
        <>
            <div className="workspace-table-wrap">
                <table className="workspace-table">
                    <colgroup>
                        <col span="2" className="depth" />
                        <col className="page-link" />
                        <col className="worker" />
                        <col className="status" />
                        <col className="status" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="depth">1Depth</th>
                            <th className="depth">2Depth</th>
                            <th className="page-link">링크</th>
                            <th className="worker">작업자</th>
                            <th className="status">작업현황</th>
                            <th className="api">Java API 적용 여부</th>
                            <th className="api">미플즈서버 적용</th>
                            <th className="ext">비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>로그인</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/login">
                                    login
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="-" />
                            <LiveServer isChange="-" />
                            <td rowSpan={2}>개발자 전용</td>
                        </tr>
                        <tr>
                            <td>가이드</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/guide">
                                    guide
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="-" />
                            <LiveServer isChange="-" />
                        </tr>
                        <tr>
                            <td rowSpan={3}>럭키드로우</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/luckyDraw">
                                    luckyDraw
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/luckyDrawView/2">
                                    luckyDrawView
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>응모하기</td>
                            <td>
                                <Link target="_blank" to="/luckyDrawApply/2">
                                    luckyDrawApply
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>구단굿즈</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/merchandise">
                                    merchandise
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>소개</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/about">
                                    about
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td rowSpan={2}>출석체크</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/attendance">
                                    attendance
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="수정" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                            <td>작업 현황 메모장 참조</td>
                        </tr>
                        <tr>
                            <td>응모하기</td>
                            <td>
                                <Link target="_blank" to="/attendanceApply/2">
                                    attendanceApply
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="수정" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                            <td>작업 현황 메모장 참조</td>
                        </tr>
                        <tr>
                            <td>코칭스태프</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/staff">
                                    staff
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>구단조직</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/organization">
                                    organization
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>마케팅 수신 동의 (팝업)</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/marketing">
                                    marketing
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td rowSpan={2}>선수소개</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/playerList">
                                    playerList
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/playerView/1">
                                    playerView
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td rowSpan={2}>페퍼 ON</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/video/on">
                                    video/on
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                            <td rowSpan={7}>2차 신규 페이지</td>
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/videoView/on/1">
                                    videoView/on/1
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                        </tr>
                        <tr>
                            <td rowSpan={2}>페퍼 유튜브</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/video/youtube">
                                    video/youtube
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/videoView/youtube/1">
                                    videoView/youtube/1
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                        </tr>
                        <tr>
                            <td rowSpan={2}>아카이브</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/video/archive">
                                    video/archive
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                        </tr>

                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/videoView/archive/1">
                                    videoView/archive/1
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="-" />
                        </tr>
                        <tr>
                            <td>SNS</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/sns">
                                    sns
                                </Link>
                            </td>
                            <td>김다미</td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                        </tr>
                        <tr>
                            <td rowSpan={3}>AI페퍼스 LIVE</td>
                            <td>페퍼 오리지널</td>
                            <td>
                                <Link target="_blank" to="/pepperLive/PL03">
                                    pepperLive/PL03
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="2차에서 삭제" />
                            <ApiStatus isChange="X" />
                            <LiveServer isChange="x" />
                            <td rowSpan={3}>2차 삭제된 페이지</td>
                        </tr>
                        <tr>
                            <td>페퍼 LIVE</td>
                            <td>
                                <Link target="_blank" to="/pepperLive/PL02">
                                    pepperLive/PL02
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="2차에서 삭제" />
                            <ApiStatus isChange="X" />
                            <LiveServer isChange="x" />
                        </tr>
                        <tr>
                            <td>세로직캠</td>
                            <td>
                                <Link target="_blank" to="/pepperLive/PL01">
                                    pepperLive/PL01
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="2차에서 삭제" />
                            <ApiStatus isChange="X" />
                            <LiveServer isChange="x" />
                        </tr>
                        <tr>
                            <td rowSpan="4">수훈선수</td>
                            <td>인트로</td>
                            <td>
                                <Link target="_blank" to="/eventIntro">
                                    eventIntro
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/event">
                                    event
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>투표하기</td>
                            <td>
                                <Link target="_blank" to="/eventVoting/5">
                                    eventVoting
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>응모하기</td>
                            <td>
                                <Link target="_blank" to="/eventApply/5">
                                    eventApply
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>티켓</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/ticket">
                                    ticket
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>이벤트 응모 내역</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/application">
                                    application
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>FAQ</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/faq">
                                    faq
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>이용약관</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/terms">
                                    terms
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>개인정보 취급 방침</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/policy">
                                    policy
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="" />
                            <LiveServer isChange="O" />
                            <td></td>
                        </tr>
                        <tr>
                            <td rowSpan="2">AI페퍼스 소식</td>
                            <td>이벤트</td>
                            <td>
                                <Link target="_blank" to="/news/event">
                                    news/event
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>공지사항</td>
                            <td>
                                <Link target="_blank" to="/news/notice">
                                    news/notice
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>티켓 할인코드</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/myTicketCoupon">
                                    myTicketCoupon
                                </Link>
                            </td>
                            <td>유선영</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>경기일정</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/match">
                                    match
                                </Link>
                            </td>
                            <td>이용준</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td></td>
                        </tr>
                        <tr>
                            <td rowSpan={3}>승부예측</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/matchPrediction">
                                    matchPrediction
                                </Link>
                            </td>
                            <td>이용준</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link
                                    target="_blank"
                                    to="/matchPredictionView/48"
                                >
                                    matchPredictionView
                                </Link>
                            </td>
                            <td>이용준</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td>응모하기</td>
                            <td>
                                <Link target="_blank" to="/matchEventApply/48">
                                    matchEventApply
                                </Link>
                            </td>
                            <td>이용준</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td />
                        </tr>
                        <tr>
                            <td rowspan={2}>구단뉴스</td>
                            <td>리스트</td>
                            <td>
                                <Link target="_blank" to="/peppernews">
                                    pepperNews
                                </Link>
                            </td>
                            <td>문진욱</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="X" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>상세</td>
                            <td>
                                <Link target="_blank" to="/peppernews/151">
                                    pepperNewsView
                                </Link>
                            </td>
                            <td>문진욱</td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="X" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>편파중계</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/broadcast">
                                    broadcast
                                </Link>
                            </td>
                            <td></td>
                            <WorkStatus type="미작업" />
                            <ApiStatus isChange="X" />
                            <LiveServer isChange="X" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>배너페이지</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/banner/35">
                                    banner
                                </Link>
                            </td>
                            <td></td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>휴면 계정 안내</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/dormantAccount">
                                    dormantAccount
                                </Link>
                            </td>
                            <td></td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>멤버십</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/membership">
                                    membership
                                </Link>
                            </td>
                            <td></td>
                            <WorkStatus type="완료" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td>2차 추가 페이지</td>
                        </tr>
                        <tr>
                            <td>이디야 이벤트</td>
                            <td />
                            <td>
                                <Link target="_blank" to="/ediya_231011">
                                    ediya_231011
                                </Link>
                            </td>
                            <td></td>
                            <WorkStatus type="진행중" />
                            <ApiStatus isChange="O" />
                            <LiveServer isChange="O" />
                            <td>2차 추가 페이지</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Workspace;
