import { put, takeEvery, call } from "redux-saga/effects";
import { applicationAPI } from "../API/applicationAPI";
import {
    applicationGetList,
    applicationGetListSuccess,
} from "./application.slice";

// Event List
function* getList(action) {
    const { payload: params } = action;

    try {
        const result = yield call(applicationAPI, params);
        yield put(
            applicationGetListSuccess({
                count: result.count,
                data: result.data,
                code: result.code
            }),
        );
    } catch (err) {
        console.error(err);
    }
}

export function* applicationSaga() {
    yield takeEvery(applicationGetList, getList);
}
