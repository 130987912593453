import React, { useState } from "react";
import PepperLiveTabs from "./pepperLiveTabs";
import PepperLiveItem from "./pepperLiveItem";
import { useSelector } from "react-redux";
import { pepperGetList } from "./module";
import Infinite from "../../components/infinite/infinite";

const PepperLive = ({ activeType }) => {
    const { count, data } = useSelector((state) => ({
        count: state.pepperLive.list.count,
        data: state.pepperLive.list.data,
    }));

    const [activeTab, setActiveTab] = useState(activeType);

    const tabList = [
        { type: "PL03", text: "페퍼 유튜브" },
        { type: "PL02", text: "페퍼 ON" },
        { type: "PL01", text: "페퍼 아카이브" },
    ];

    // 탭 클릭 시
    const handleTabClick = (type) => {
        if (activeTab !== type) {
            setActiveTab(type);
        }
    };

    // 활성화된 탭 이름
    const onGetTabName = () =>
        tabList.find((item) => item.type === activeTab)?.text;

    // 리스트 아이템
    const onItemComponent = (post) => (
        <PepperLiveItem key={post.id} post={post} onGetTabName={onGetTabName} />
    );

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="none-result">
            <span className="description">
                등록된 영상이 없습니다.
            </span>
        </p>
    );

    return (
        <div className="pepper-live">
            <div className="inner">
                <PepperLiveTabs
                    tabList={tabList}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                />
                <div
                    className={`live-list-wrap ${
                        activeTab === "PL01" ? "vertical" : ""
                    }`}
                >
                    <Infinite
                        count={count}
                        data={data}
                        activeTab={activeTab}
                        onGetList={pepperGetList}
                        onItemComponent={onItemComponent}
                        onNoneResultComponent={onNoneResultComponent}
                    />
                </div>
            </div>
        </div>
    );
};

export default PepperLive;
