import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAppBridge } from "../../libs/js/common";
import { ediyaCpnIssue } from "./module";
import useLoginCheck from "../../hooks/useLoginCheck";

const EdiyaIssue = (props) => {
    const dispatch = useDispatch();
    const [isApply, setIsApply] = useState(false); // 응모 여부
    const isLogin = useLoginCheck();

    //쿠폰받기 클릭
    const handleCoupon = () => {
        if (isLogin) {
            //쿠폰발급하기
            setIsApply(true);
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
            // handleAppBridge({
            //     fnName: "goLogout",
            //     param: ["로그인 후 이용 가능합니다.", "refresh"],
            // });
        }
    };
    //쿠폰발급하기
    useEffect(() => {
        if (isApply === true) {
            dispatch(ediyaCpnIssue());
        }
    }, [isApply]);

    const { data, code } = useSelector((state) => ({
        data: state.ediya.ediyaCpnIssue.data,
        code: state.ediya.ediyaCpnIssue.code,
    }));

    useEffect(() => {
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogin",
            });
        } else if (code === "E142") {
            // console.log("이미 참여한 이벤트");
        }

        if (data !== null) {
            // console.log(" ISSUE CODE ", data.code);
            props.onIssueCode(data.code);
            alert(
                "쿠폰이 성공적으로 발급되었습니다.\n하단 쿠폰 번호를 확인해 주세요.",
            );
        }
    }, [data]);

    return (
        <button className="active" type="button" onClick={handleCoupon}>
            이디야 아메리카노(L) 쿠폰받기
        </button>
    );
};

export default EdiyaIssue;
