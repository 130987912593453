import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const playerListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/playerList",
            data: params,
        });

        return response.data;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
