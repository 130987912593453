import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 경기 정보 API
export const matchInfoAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/aipeppers/getScheduleDetail",
            data: params,
        });

        return response.data;
    } catch (e) {
        if (e.response.status > 202) {
            handleError();
        }
    }
};

// 승부예츨 참가
export const postMatchVictoryAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/postMatchVictory",
            data: params,
        });

        const { code, msg } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (e) {
        console.error(e);
    }
};

// 승부예측 뷰페이지
export const getMatchVictoryAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/getMatchVictory",
            data: params,
        });

        const { code, msg } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response.data;
    } catch (e) {
        console.error(e);
    }
};

//승부예측 응모 여부 체크
export const matchVictoryInsertInfoAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/event/matchVictoryInsertInfo",
            data: params,
        });

        const { code, msg } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (e) {
        console.error(e);
    }
};

//승부예측 응모하기
export const checkMatchVictoryApplyAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/checkMatchVictoryApply",
            data: params,
        });

        const { code, msg } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (e) {
        console.error(e);
    }
};
