import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 투표하기
export const votePlayerAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/aipeppers/votePlayer",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 투표 참여 여부 체크
export const isMyVoteAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/isMyVote",
            data: params,
        });

        const { code, msg } = response;

        // if (code === "E101") {
        //     handleAppBridge({
        //         fnName: "goLogout",
        //         param: [msg, "refresh"],
        //     });
        // }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 투표 저장된 정보 불러오기
export const getLeaguesAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/aipeppers/getVoteMatch",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 투표 참여자 정보 저장 (APPLY)
export const getApplyEventAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/setMvpEventInfo",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }
        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

//이디야 쿠폰발급수
export const getEdiyaCpnCntAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getEdiyaCnt",
            data: params,
        });
        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

//이디야 쿠폰받기
export const getEdiyaCpnIssueAPI = async (params) => {
    console.log(" params ", params);
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getEdiyaCode",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

//이디야 쿠폰발급여부
export const getMyEdiyaCpnCntAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getEdiyaIssueCnt",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

//이디야 쿠폰발급번호 조회
export const getMyEdiyaCpnAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getMyEdiyaCodeInfo",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
