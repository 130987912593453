import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
    handleAppBridge,
    scrollObj,
    setLocalStorage,
} from "../../libs/js/common";
import PepperItem from "./pepperItem";
import { useLocation } from "react-router-dom";
import Infinite from "../../components/infinite/infinite";
import { pepperNewsGetList } from "./module";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const PepperNews = () => {
    //구단소식 saga 연결 response data
    const { count, data } = useSelector((state) => ({
        count: state.pepperNews.list.count,
        data: state.pepperNews.list.data,
    }));

    const navigate = useNavigate();
    const location = useLocation();
    //const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button6_AI페퍼스_구단뉴스_click','AI_peppers_button6_AI페퍼스_구단뉴스_click','AI_peppers_button6_AI페퍼스_구단뉴스_click');
            } else if( gb === 'm1_ne' ) {
                TrackGoogleAnalyticsEvent('maintab1_news_뉴스_더보기_click','maintab1_news_뉴스_더보기_click','maintab1_news_뉴스_더보기_click');
            }
        }
    }, []);

    const [newsType, setNewsType] = useState(null);

    /*useEffect(() => {
        //구단소식 dispatch API호출
        dispatch(pepperNewsGetList());
    }, []);*/

    const handleNextPage = (id, contentCd, link) => {
        if (contentCd === "NE02") {
            const { search } = window.location;
            navigate(`/peppernews/${id}${search}`, scrollObj);
            setLocalStorage("scrollY", window.scrollY);
        } else {
            // 외부 링크 오픈
            handleAppBridge({
                fnName: "onOpenPage",
                param: link,
            });
        }
    };

    // 리스트 아이템
    const onItemComponent = (post) => (
        <PepperItem key={post.id} post={post} onClick={handleNextPage} />
    );

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="none-result">
            <span className="description">등록된 게시물이 없습니다.</span>
        </p>
    );

    useEffect(() => {
        const getNewsType = () => {
            return "BD02";
        };
        setNewsType(getNewsType());
    }, []);

    if (!newsType) return false;
    return (
        <div className="team_news_wrap">
            <div className="team_news_list">
                <ul>
                    <Infinite
                        count={count}
                        data={data}
                        activeTab={newsType}
                        onGetList={pepperNewsGetList}
                        onItemComponent={onItemComponent}
                        onNoneResultComponent={onNoneResultComponent}
                    />
                </ul>
            </div>
        </div>
    );
};

export default PepperNews;
