import React, { useState, useRef, useCallback, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BtnOutline02 } from "components/common/common";

const AboutTabs = React.memo(({ tabList, activeTab, handleActiveTab, clicked ,setClicked }) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const tabRef = useRef(null);
    const [init,setInit] = useState(false);
    const swiperRef = useRef(null);

    const handleSwiperUpdate = useCallback((swiper) => { // 스와이퍼 업데이트
        if (!isUpdate) {
            swiper.updateSlides();
            setIsUpdate(true);
        }
    }, [isUpdate]);

    const handleTabClickMove = useCallback(( val) => { // 클릭 시 value 값 전달 , 버튼 가운데로 이동
        if (clicked) return;
        handleActiveTab(val);
        setClicked(true);
        const element = document.getElementById(val);

        if (element && tabRef.current) {
            const scrollView = document.querySelector('.pepper-about')

            const elementRect = element.getBoundingClientRect();
            const scrollViewRect = scrollView.getBoundingClientRect();
            const tabHeight = tabRef.current.offsetHeight;
            const offsetPosition = elementRect.top - scrollViewRect.top + scrollView.scrollTop - tabHeight - 0; // 마지막은 여백의 정도

            if (element) {
                scrollView.scroll({
                    top: offsetPosition,
                    behavior: 'smooth'
                });

                const checkScrollEnd = (callback) => {
                    let isScrolling;

                    scrollView.addEventListener('scroll', () => {
                        window.clearTimeout(isScrolling);

                        isScrolling = setTimeout(() => {
                            // 스크롤이 멈췄을 때 실행할 코드
                            callback();
                        }, 100); // 스크롤이 멈춘 후 100ms 후에 실행
                    }, { passive: true });
                };

                checkScrollEnd(() => {
                    setClicked(false)
                });
            }
        }

        

    }, [clicked, handleActiveTab, setClicked]);

    useEffect(()=>{
        if (swiperRef.current && swiperRef.current.swiper && init) {
            const swiper = swiperRef.current.swiper;
            const target = document.querySelector(`button[name=${activeTab}]`).parentElement;
            const boxHarf = swiperRef.current.getBoundingClientRect().width / 2
            let leftSibling = target.previousElementSibling;
            let targetLeft = 0;

            while (leftSibling) {
                targetLeft += leftSibling.getBoundingClientRect().width;
                targetLeft += parseFloat(leftSibling.style.marginLeft) || 0;
                targetLeft += parseFloat(leftSibling.style.marginRight) || 0;

                leftSibling = leftSibling.previousElementSibling;
            }

            setTimeout(() => {
                swiper.translateTo(-((targetLeft - boxHarf + target.getBoundingClientRect().width / 2)), 500)
            }, 100)

        }
        
    }, [activeTab, init])



    function swiperInit(swiper,attempt = 0){
        const maxAttempts = 10;
        const delay = 100;
        const lastSlide = swiper.slides[swiper.slides.length - 1];

        if (attempt >= maxAttempts) return;

        if(lastSlide){
            setInit(true);
        }else{
            setTimeout(()=>{
                swiperInit(swiper, attempt + 1);
            },delay)
        }
        
    }

    if (!tabList) return null;

    return (
        <div className="tab-swiper" ref={tabRef}>
            <Swiper
                ref={swiperRef}
                slidesPerView={"auto"}
                spaceBetween={10}
                onClick={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onTouchMove={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onInit={swiperInit}
            >
                {tabList.map(({ cdId, cdNm }, index) => (
                    <SwiperSlide key={cdNm}>
                        <BtnOutline02
                            text={cdNm}
                            name={cdId}
                            active={activeTab === cdId}
                            type="button"
                            onClick={() => handleTabClickMove(cdId)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
});

export default AboutTabs;
