import React from "react";
import { BtnRound } from "../../components/common/common";
import { CLOSE, ONGOING, PREPARING } from "../../constant/contant";

const LuckyDrawTabs = ({ activeTab, onTabClick }) => {
    const tabList = [
        {
            type: PREPARING,
            text: "#예정",
        },
        {
            type: ONGOING,
            text: "#진행중",
        },
        {
            type: CLOSE,
            text: "#종료",
        },
    ];

    return (
        <ul className="tab-basic">
            {tabList.map(({ type, text }) => (
                <li key={type}>
                    <BtnRound
                        name={type}
                        text={text}
                        active={activeTab === type}
                        onClick={() => onTabClick(type)}
                    />
                </li>
            ))}
        </ul>
    );
};

export default LuckyDrawTabs;
