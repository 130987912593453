import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAppBridge } from "../../libs/js/common";
import { privacyGetHtml, privacyGetList } from "./module";
import SelectComponent from "./select";

const Policy = (props) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.privacy.list.data);
    const htmlData = useSelector((state) => state.privacy.html.data);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(privacyGetList({ name: "privacyPolicy" }));
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            const sorted = data.slice().sort((pr, nt) => new Date(nt.regDt) - new Date(pr.regDt));
            const initialOption = sorted[0];
            setSelectedOption(JSON.stringify(initialOption));
            dispatch(privacyGetHtml({ id: initialOption.id, name: initialOption.name }));
        }
    }, [data, dispatch]);

    useEffect(() => {
        setIsLoading(!htmlData);
    }, [htmlData]);

    useEffect(() => {
        handleAppBridge({ fnName: isLoading ? "startLoading" : "stopLoading" });
    }, [isLoading]);

    const handleSeleted = useCallback((event) => {
        const value = event.target.value;
        setSelectedOption(value);
        const parsedValue = JSON.parse(value);
        dispatch(privacyGetHtml({ id: parsedValue.id, name: parsedValue.name }));
    }, [dispatch]);

    if (!htmlData || !data) return null;

    return (
        <div className="policy-wrap">
            <div className="inner">
                <SelectComponent data={data} onChange={handleSeleted} selectedValue={selectedOption} type="privacyPolicy" />
                <div className="cont" dangerouslySetInnerHTML={{ __html: htmlData[0].text }} />
            </div>
        </div>
    );
};

export default Policy;
