import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
    handleAppBridge,
    scrollObj,
    setLocalStorage,
} from "../../libs/js/common";
import VideoItem from "./videoItem";
import { useLocation, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Infinite from "../../components/infinite/infinite";
import { videoGetList } from "./module";
import { useSelector } from "react-redux";
import useLoginCheck from "../../hooks/useLoginCheck";
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Video = () => {
    const { count, data, sort, typeName, aspectCode } = useSelector((state) => ({
        count: state.video.list.count,
        data: state.video.list.data,
        sort: state.video.list.sort,
        typeName: state.video.list.typeName,
        aspectCode: state.video.list.aspectCode
    }));

    const el = document.querySelector("#header .page-name");
    if (el) {
        el.textContent = typeName;
    }
    
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { type, contentCd } = useParams();

    const [series, setSeries] = useState(null);
    const [videoType, setVideoType] = useState(null);

    const isLogin = useLoginCheck();

    const gb = searchParams.get("gb");


    useEffect(() => {
        InitializeGoogleAnalytics();
        
        if( typeName ) {            
            if( gb !== null ) {
                if( gb === 'ca' ) {
                    let preText = 'pepper_TV_button';
                    let fullText = null;
                    const replaceTitle = typeName.replace(/\s+/g,'');
    
                    if( replaceTitle === '페퍼숏폼' ) {
                        fullText = preText + '1_페퍼TV_아카이브_click';
                    } else if( replaceTitle === '독점영상' ) {
                        fullText = preText + '2_페퍼TV_'+ replaceTitle +'_click';
                    } else if( replaceTitle === '슬로우모션' ) {
                        fullText = preText + '3_페퍼TV_'+ replaceTitle +'_click';
                    } else if( replaceTitle === '세로직캠' ) {
                        fullText = preText + '4_페퍼TV_'+ replaceTitle +'_click';
                    } else if( replaceTitle === '유튜브' ) {
                        fullText = preText + '5_페퍼TV_'+ replaceTitle +'_click';
                    } else if( replaceTitle === '유튜브숏츠' ) {
                        fullText = preText + '6_페퍼TV_'+ replaceTitle +'_click';
                    } else {
                        fullText = preText + '_페퍼TV_'+ replaceTitle +'_click';
                    }
    
                    TrackGoogleAnalyticsEvent(fullText,fullText,fullText);
                } else if( gb === 'm1_b' ) {
                    TrackGoogleAnalyticsEvent('maintab1_banner_페퍼TV_Original_더보기_click','maintab1_banner_페퍼TV_Original_더보기_click','maintab1_banner_페퍼TV_Original_더보기_click');
                } else if( gb === 'm1_b5' ) {
                    TrackGoogleAnalyticsEvent('maintab1_banner5_시리즈_더보기_link_click','maintab1_banner5_시리즈_더보기_link_click','maintab1_banner5_시리즈_더보기_link_click');
                } else if( gb === 'm1_b6' ) {
                    TrackGoogleAnalyticsEvent('maintab1_banner6_페퍼숏폼Original_더보기_click','maintab1_banner6_페퍼숏폼Original_더보기_click','maintab1_banner6_페퍼숏폼Original_더보기_click');
                } else if( gb === 'm2_b' ) {
                    if( contentCd !== undefined && contentCd !== null ) {
                        if( sort ) {
                            let preSortText = 'maintab2_b_';
                            let fullSortText = null;
                            let seriesTitle = null;

                            for( let i = 0; i < sort.length; i++ ) {                
                                if( sort[i].cdId === contentCd ) {
                                    seriesTitle = sort[i].cdNm;
                                    break; 
                                }
                            }
                            fullSortText = preSortText + seriesTitle + "_더보기_click";
                            TrackGoogleAnalyticsEvent(fullSortText,fullSortText,fullSortText);
                        }
                    } else {
                        if( type === 'archive' ) {
                            TrackGoogleAnalyticsEvent('maintab2_b_페퍼숏폼_더보기_click','maintab2_b_페퍼숏폼_더보기_click','maintab2_b_페퍼숏폼_더보기_click');
                        }
                    }
                }
            }
        }
    }, [typeName]);

    const handleChange = (e) => {
        //searchParams.delete("series");
        //setSearchParams(searchParams);
        // TODO API 재호출하기 @김다미

        setSeries(e.target.value);
    };

    const sortSelectList = () => {
        const sorts = sort.map((item, key) => {
            const { cdId, cdNm } = item;
            return (
                <option value={cdId} key={key}>
                    {cdNm}
                </option>
            );
        });

        return (
            <>
                <option value="">ALL</option>
                {sorts}
            </>
        );
    };

    const handleNextPage = (
        id,
        contentCd,
        videoTypeCd,
        link,
        contentTypeCd,
    ) => {
        const { search } = window.location;
        if (contentTypeCd === "ICON_ORIGINAL") {
            if (isLogin) {
                navigate(
                    `/video/${type}/${contentCd}/${id}${search}`,
                    scrollObj,
                );
            } else {
                handleAppBridge({
                    fnName: "goLogin",
                });
            }
        } else {
            navigate(`/video/${type}/${contentCd}/${id}${search}`, scrollObj);
        }
        setLocalStorage("scrollY", window.scrollY);
    };

    // 리스트 아이템
    const onItemComponent = (post) => (
        <VideoItem
            key={post.id}
            post={post}
            isPlayList={true}
            aspectCode={aspectCode}
            onClick={handleNextPage}
        />
    );

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="none-result">
            <span className="description">등록된 영상이 없습니다.</span>
        </p>
    );

    useEffect(() => {
        const seriesCheck = contentCd;
        if (!seriesCheck) {
            setSeries("");
        } else {
            setSeries(seriesCheck);
        }

        setVideoType("/video/" + type);        
    }, []);

    if (!videoType) return false;
    return (
        <div
            className={classNames(
                "live-posts",
                "video-list",
                aspectCode === "PORTRAIT" ? "archive-post" : `youtube-post`                
            )}
        >
            <div className="inner">
                <div className="video-select-wrap">
                    {sort && (
                        <select
                            defaultValue={series}
                            className="video-select"
                            onChange={(e) => handleChange(e)}
                        >
                            {sortSelectList()}
                        </select>
                    )}
                </div>
                <Infinite
                    listClass="live-post-list"
                    count={count}
                    data={data}
                    activeTab={videoType}
                    onGetList={videoGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                    selectType={series}
                />
            </div>
        </div>
    );
};

export default Video;
