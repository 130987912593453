import React, { useEffect, useState, useRef } from "react";
import AttendanceItem from "./attendanceItem";
import { useDispatch, useSelector } from "react-redux";
import {
    modalClose,
    modalOpen,
} from "../../components/layerModal/module/layerModal";
import LayerModal from "../../components/layerModal/layerModal";
import { attendanceCheck, attendanceGetList } from "./module";
import { useNavigate } from "react-router";
import { handleAppBridge } from "../../libs/js/common";
import { BtnDefault } from "../../components/common/common";
import { APPLE_EVENT_MESSAGE, IOS, TOKEN } from "../../constant/contant";
import useLoginCheck from "../../hooks/useLoginCheck";
import { createBrowserRouter } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

export const attendanceNoteList = [
    `출석은 월 단위로 진행되며, 매월 1일 초기화 됩니다.`,
    `무작위 추첨으로 당첨자를 선별하며, 운영자는 당첨자 추첨에 일절 관여하지 않습니다.`,
    `당첨 메시지 발송 이후 7일 이내에 회신이 없을 경우, 당첨이 자동 취소 됩니다.`,
    `당첨자에 한하여 개별 메시지 발송 등의 연락이 진행되며, 미당첨자에게는 연락이 가지 않습니다.`,
    `5만원 초과 경품의 제세공과금(22%)은 당첨자 본인 부담입니다.`,
    `제세공과금 납부와 관련한 세부사항은 소득세법, 지방세법 및 기타 관련 법령의 내용에 따라 처리합니다.`,
    `고객 귀책 (고객 정보 오전달)으로 인한 이벤트 경품 미수령, 오배송, 반송에 대한 재발송 처리가 불가합니다.`,
    `휴대폰 번호가 확인이 되지 않을 경우, 연락 불가로 인해 당첨이 취소될 수 있으며 경품 수령이 불가할 수 있습니다.`,
    `부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며, 법에 따른 처벌 대상이 될 수 있습니다.`,
    `고객 과실로 인한 경품 분실 시, 재발송은 불가합니다.`,
    `경품 수령 후 반품 처리 불가합니다.`,
    `해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 내용 변경 또는 조기 종료 될 수 있습니다.`,
    `경품의 단종, 품절 등과 같은 당사 및 공급사 사정으로 인해 경품 지급이 어려울 경우, 다른 경품으로 대체될 수 있습니다.`,
];

const Attendance = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLogin = useLoginCheck();

    const handleClick = () => {
        modalClose(dispatch);
    };

    const { data, checkData } = useSelector((state) => ({
        data: state.attendance.list.data,
        checkData: state.attendance.check.data,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button2_이벤트_출석체크_click','event_button2_이벤트_출석체크_click','event_button2_이벤트_출석체크_click');
            } else if ( gb === 'm1_e' ) {
                TrackGoogleAnalyticsEvent('maintab1_event_출석체크이벤트_click','maintab1_event_출석체크이벤트_click','maintab1_event_출석체크이벤트_click');
            } else if ( gb === 'm3_b' ) {
                TrackGoogleAnalyticsEvent('maintab3_banner_출석체크이벤트_click','maintab3_banner_출석체크이벤트_click','maintab3_banner_출석체크이벤트_click');
            }
        }
    }, []);

    const [posts, setPosts] = useState(null);
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const AttendanceModal = () => {
        // 포인트 애니 컨트롤
        const [pointClass, setPointClass] = useState("point-ani");

        useEffect(() => {
            const pointTimer = setInterval(() => {
                setPointClass("point");
            }, 2000);

            return () => {
                clearTimeout(pointTimer);
                setPointClass("point-ani");
            };
        }, []);

        // 쿠키 애니 컨트롤
        const [cookieClass, setCookieClass] = useState("cookie-ani");

        useEffect(() => {
            const timer = setInterval(() => {
                // console.log(" clickRender ", clickRender);
                setCookieClass("cookie");
            }, 2000);

            return () => {
                clearTimeout(timer);
                setCookieClass("cookie-ani");
            };
        }, []);

        // 선물 애니 컨트롤
        const [giftClass, setGiftClass] = useState("gift-ani");

        useEffect(() => {
            const giftTimer = setInterval(() => {
                setGiftClass("gift");
            }, 3000);

            return () => {
                clearTimeout(giftTimer);
                setGiftClass("gift-ani");
            };
        }, []);

        if (post) {
            const { attend_product_type, attend_gift_open } = post;
            if (attend_product_type === "PT01") {
                return (
                    <LayerModal
                        modalName="attendance"
                        modalBody={pointClass}
                        bottomButton={true}
                        content={<PointModal />}
                    />
                );
            } else if (attend_product_type === "PT02") {
                return (
                    <LayerModal
                        modalName="attendance"
                        modalBody={cookieClass}
                        bottomButton={true}
                        content={<CookieModal />}
                    />
                );
            } else if (attend_product_type === "PT03") {
                const { type } = checkData.data?.attendGift;

                return (
                    <LayerModal
                        modalName="attendance"
                        modalBody={giftClass}
                        bottomButton={true}
                        content={<GiftModal />}
                        text={type !== "P" ? "정보입력하기" : "확인"}
                        onClick={type !== "P" ? onGiftModal : handleClick}
                        disabled={attend_gift_open === "Y"}
                        type={type}
                    />
                );
            }
        }
    };

    const CookieModal = () => {
        const { fortune_message, fortune_name } = checkData.data;
        const [cookieToast, setCookieToast] = useState({
            cookieAniToast: false,
            cookiePopToast: false,
        });

        const { cookieAniToast, cookiePopToast } = cookieToast;

        useEffect(() => {
            setCookieToast({
                ...cookieToast,
                cookieAniToast: true,
            });
            const timer = setTimeout(() => {
                setCookieToast({
                    ...cookieToast,
                    cookieAniToast: false,
                });
                if (!cookieAniToast) {
                    setCookieToast({
                        ...cookieToast,
                        cookiePopToast: true,
                    });
                }
                if (cookiePopToast) {
                    setCookieToast({
                        ...cookieToast,
                        cookiePopToast: false,
                    });
                }
            }, 2000);

            return () => {
                clearTimeout(timer);
                setCookieToast({
                    ...cookieToast,
                    cookieAniToast: false,
                    cookiePopToast: false,
                });
            };
        }, []);

        return (
            <>
                {cookiePopToast && (
                    <div className="new-rwd-pop cookie">
                        <div className="top"></div>
                        <div className="cont">
                            <strong>오늘은 포춘 쿠키!</strong>
                            <div className="cookie-txt-bg">
                                {fortune_message}
                                {/*{fortune_name && (*/}
                                {/*    <p className="name">{fortune_name}</p>*/}
                                {/*)}*/}
                            </div>
                            <p>
                                <b>포인트 {post.attend_point}점</b>에
                                당첨되었습니다.
                                <br />
                                ‘내 활동’에서 지급된 포인트를 확인해 보세요!
                            </p>
                        </div>
                    </div>
                )}
                {cookieAniToast && (
                    <>
                        <div className="ani_pop cookie_ani"></div>
                    </>
                )}

                {/*<div className="cookie-area">
                    <div className="content">
                        <p className="sub-title">+{post.attend_point}P</p>
                        <h2 className="title">“{fortune_message}”</h2>
                        {fortune_name && <p className="name">{fortune_name}</p>}
                    </div>
                </div>*/}
            </>
        );
    };

    const GiftModal = () => {
        const { type, name, point } = checkData.data?.attendGift;
        const [giftToast, setGiftToast] = useState({
            giftAniToast: false,
            giftPopToast: false,
        });

        const { giftAniToast, giftPopToast } = giftToast;

        useEffect(() => {
            setGiftToast({
                ...giftToast,
                giftAniToast: true,
            });
            const timer = setTimeout(() => {
                setGiftToast({
                    ...giftToast,
                    giftAniToast: false,
                });
                if (!giftAniToast) {
                    setGiftToast({
                        ...giftToast,
                        giftPopToast: true,
                    });
                }
                if (giftPopToast) {
                    setGiftToast({
                        ...giftToast,
                        giftPopToast: false,
                    });
                }
            }, 3000);

            return () => {
                clearTimeout(timer);
                setGiftToast({
                    ...giftToast,
                    giftAniToast: false,
                    giftPopToast: false,
                });
            };
        }, []);

        /*
         * GT01 : 포인트
         * GT02 : 온라인 상품
         * GT03 : 배송 상품
         * */

        return (
            <>
                {giftPopToast && (
                    <>
                        <div className="new-rwd-pop gift">
                            <div className="top"></div>
                            <div className="cont">
                                <strong>축하합니다!</strong>
                                <p>
                                    <b>
                                        {type === "GT01" || type === "P"
                                            ? `포인트 ${point}점`
                                            : name}
                                    </b>
                                    에 당첨되었습니다.
                                </p>
                            </div>
                        </div>

                        {/*<div className="gift-area">
                            <div className="content">
                                <p className="sub-title">
                                    {type === "GT01"
                                        ? `포인트 ${point}점`
                                        : name}
                                </p>
                                <h2 className="title">“당첨되셨습니당.”</h2>
                            </div>
                        </div>*/}
                    </>
                )}
                {giftAniToast && (
                    <>
                        <div className="ani_pop gift_ani"></div>
                    </>
                )}
            </>
        );
    };

    const PointModal = () => {
        const [pointToast, setPointToast] = useState({
            pointAniToast: false,
            pointPopToast: false,
        });

        const { pointAniToast, pointPopToast } = pointToast;

        useEffect(() => {
            setPointToast({
                ...pointToast,
                pointAniToast: true,
            });
            const pointTimer = setTimeout(() => {
                setPointToast({
                    ...pointToast,
                    pointAniToast: false,
                });
                if (!pointAniToast) {
                    setPointToast({
                        ...pointToast,
                        pointPopToast: true,
                    });
                }
                if (pointPopToast) {
                    setPointToast({
                        ...pointToast,
                        pointPopToast: false,
                    });
                }
            }, 2000);

            return () => {
                clearTimeout(pointTimer);
                setPointToast({
                    ...pointToast,
                    pointAniToast: false,
                    pointPopToast: false,
                });
            };
        }, []);

        return (
            <>
                {pointPopToast && (
                    <div className="new-rwd-pop point">
                        <div className="top"></div>
                        <div className="cont">
                            <strong>출석체크 완료!</strong>
                            <p>
                                <b>{post.attend_point} 포인트</b>지급이
                                완료되었습니다.
                                <br />
                                매일 출석체크하고 포인트 받아 가세요!
                            </p>
                        </div>
                    </div>
                )}
                {pointAniToast && (
                    <>
                        <div className="ani_pop point_ani"></div>
                    </>
                )}
            </>
        );
    };

    const onGiftModal = () => {
        if (checkData.code === "200") {
            const { search } = window.location;
            const { attend_id } = checkData.data;

            navigate(`/attendanceApply/${attend_id}${search}`);
        }
    };

    window.onpopstate = function (event) {
        window.location.reload();
    };

    // 출석체크 버튼 클릭 시
    const onAttendanceCheck = (post) => {
        setPost(post);
        const { giftType, submitInfo, attendCheckId } = post;

        if (giftType === "B" && submitInfo === "0") {
            //정보입력하기
            const { search } = window.location;
            navigate(`/attendanceApply/${attendCheckId}${search}`);

            // handleAppBridge({
            //     fnName: "onOpenPage",
            //     param: `/attendanceApply/${attendCheckId}${search}`,
            // });
        } else {
            // 출석체크 API 호출
            dispatch(
                attendanceCheck({
                    date: post.date,
                }),
            );
        }
    };

    // 인터파크 할인권 교환 버튼 클릭 시
    const handleExchange = () => {
        if (isLogin) {
            const { search } = window.location;
            navigate(`/ticketCouponBuy${search}`, {
                state: {
                    pageType: "view",
                },
            });
            // handleAppBridge({
            //     fnName: "onOpenPage",
            //     param: "https://kovo.co.kr/aipeppers/ticket/ticket-buy",
            // });
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 마운트 시
    useEffect(() => {
        dispatch(attendanceGetList());
    }, [dispatch]);

    // 리스트 데이터 커스텀
    useEffect(() => {
        if (data) {
            //console.log("data", data);
            //console.log("Array.from(data)", data.AttendSetting);
            const attdata = data.AttendSetting;
            const newData = attdata
                .filter((item, i) => i % 4 === 0)
                .map((item) => {
                    const idx = attdata.indexOf(item);
                    return attdata.slice(idx, idx + 4);
                });
            //console.log("newData", newData);
            setPosts(newData);
            setIsLoading(false);
        }
    }, [data]);

    // 출석 체크 완료 시
    useEffect(() => {
        if (checkData?.code === "200" && post) {
            // const { attend_product_type, attend_point } = post;

            // if (attend_product_type === "PT01") {
            //     setTimeout(() => {
            //         alert(`+${attend_point}P 지급되었습니다.`);
            //     }, 510);
            // } else {
            modalOpen(dispatch);
            // }
        }
    }, [checkData]);

    // 로딩 스피너
    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    if (!posts) return false;

    return (
        <>
            <div className="attendance">
                <div className="attendance-list-wrap">
                    {/*<div className="attendance-notice">
                        <div className="notice-box">
                            <h2 className="title">이벤트 진행 방식</h2>
                            <p className="description">
                                출석 횟수 <em>5</em> 회 마다, 미스터리 박스 추첨
                            </p>
                        </div>
                        <div className="notice-box">
                            <h2 className="title">나의 출석 횟수</h2>
                            <p className="description">
                                <em>5</em> 회 (누적)
                            </p>
                        </div>
                    </div>*/}
                    <ul className="attendance-list">
                        {posts.map((post, i) => (
                            <li key={i}>
                                {post.map((item) => {
                                    return (
                                        <AttendanceItem
                                            key={item.date}
                                            post={item}
                                            onAttendanceCheck={
                                                onAttendanceCheck
                                            }
                                        />
                                    );
                                })}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="attendance-guide">
                    <h2 className="title">출석체크 유의사항</h2>
                    <ul className="text-list-dot">
                        {attendanceNoteList.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                        {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                    </ul>
                </div>
                <div className="attendance-button-wrap">
                    <BtnDefault
                        text="티켓 할인권 교환"
                        size="block"
                        onClick={handleExchange}
                        className="btn-gradient-red"
                    />
                </div>
            </div>
            {checkData?.code === "200" && <AttendanceModal />}
            {/*{checkData?.code === "200" &&
                post?.attend_product_type !== "PT01" && <AttendanceModal />}
            {checkData?.code === "200" &&
                post?.attend_product_type === "PT01" && <PointModal />}*/}
        </>
    );
};

export default Attendance;
