import React, { useState } from "react";
import MyTicketItem from "./myTicketItem";
import { useSelector } from "react-redux";
import Infinite from "../../components/infinite/infinite";
import { myTicketGetList } from "./module";

const MyTicketCoupon = () => {
    const { count, data } = useSelector((state) => ({
        count: state.myTicket.list.count,
        data: state.myTicket.list.data,
    }));
    const [currentId, setCurrentId] = useState(null);
    const handleToggle = (id) => {
        setCurrentId((currentId) => (currentId === id ? null : id));
    };

    const onItemComponent = (post) => {
        return (
            <MyTicketItem
                key={post.id}
                data={post}
                onToggle={() => handleToggle(post.id)}
                active={currentId === post.id}
            />
        );
    };

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="no-purchase-result">
            <strong>구매내역이 없습니다.</strong>
            <span>
                AI PEPPERS에서 준비한 <br />
                다양한 이벤트에 참여해 보세요.
            </span>
        </p>
    );

    return (
        <div className="my-ticket-wrap">
            <div className="inner">
                <p className="top-description">
                    최근 6개월간의 구매내역만 보입니다.
                </p>
                <Infinite
                    listClass="ticket-list-wrap"
                    count={count}
                    data={data}
                    onGetList={myTicketGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                />
            </div>
        </div>
    );
};

export default MyTicketCoupon;
