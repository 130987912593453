import React from "react";
import classNames from "classnames";
// 기본 버튼
export const BtnDefault = ({
    name,
    href,
    text,
    size,
    disabled,
    onClick,
    className,
    target,
    id,
    second,
}) => {
    if (!href || (href && disabled)) {
        return (
            <button
                type="button"
                name={name}
                className={classNames(
                    "btn",
                    "btn-default",
                    size,
                    className,
                    second && "second",
                )}
                disabled={disabled}
                onClick={onClick}
                {...(id && { id })}
            >
                {text}
            </button>
        );
    }

    return (
        <a
            href={href}
            name={name}
            className={classNames("btn", "btn-default", size, className)}
            target={!!target ? target : "_blank"}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </a>
    );
};

// 기본 버튼
export const BtnSecondary = ({
    name,
    text,
    size,
    disabled,
    onClick,
    className,
    id,
}) => {
    return (
        <button
            type="button"
            name={name}
            className={classNames("btn", "btn-secondary", size, className)}
            disabled={disabled}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </button>
    );
};

// 기본 버튼
export const BtnUnderLine = ({
    name,
    text,
    size,
    disabled,
    onClick,
    className,
    id,
    active,
}) => {
    return (
        <button
            type="button"
            name={name}
            className={classNames(
                "btn",
                "btn-underline",
                active && "active",
                size,
                className,
            )}
            disabled={disabled}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </button>
    );
};

// 아웃라인 버튼
export const BtnOutline = ({
    name,
    text,
    size,
    disabled,
    onClick,
    className,
    id,
}) => {
    return (
        <button
            type="button"
            name={name}
            className={classNames("btn", "btn-outline", size, className)}
            disabled={disabled}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </button>
    );
};

// 아웃라인 버튼 02
export const BtnOutline02 = ({
    name,
    text,
    size,
    disabled,
    onClick,
    className,
    id,
    active,
}) => {
    return (
        <button
            type="button"
            name={name}
            className={classNames(
                "btn",
                "btn-outline-02",
                size,
                className,
                active && "active",
            )}
            disabled={disabled}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </button>
    );
};

// 라운드 버튼
export const BtnRound = ({
    name,
    text,
    size,
    disabled,
    onClick,
    className,
    id,
    active,
}) => {
    return (
        <button
            type="button"
            name={name}
            className={classNames(
                "btn",
                "btn-round",
                size,
                className,
                active && "active",
            )}
            disabled={disabled}
            onClick={onClick}
            {...(id && { id })}
        >
            {text}
        </button>
    );
};

// Loader
export const Loader = () => (
    <div className="loader">
        <div className="sprite" />
    </div>
);

// Loading Spinner
export const LoadingSpinner = () => <div className="loading-spinner" />;

const Common = () => {
    return <></>;
};

export default Common;
