import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const newsListAPI = async (params) => {
    try {
        return await request({
            method: "post",
            url: "/category/aipeppers/searchNoticeList",
            data: params,
        });
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

export const newsViewAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/searchNoticeDetail",
            data: params,
        });

        return response.data;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

export const eventListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/eventList",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
