import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAppBridge } from "../../libs/js/common";
import useLoginCheck from "../../hooks/useLoginCheck";
import {
    ediyaCpnCnt,
    ediyaCpnCode,
    ediyaCpnIssue,
    myEdiyaCpn,
    myEdiyaCpnCnt,
} from "./module";
import EdiyaIssue from "./ediyaIssue";
import EdiyaCpnCnt from "./ediyaCpnCnt";

import topBnr from "../../libs/images/event/ediya_231011/top_bnr.png";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const EdiyaEvent = () => {
    const dispatch = useDispatch();
    const isLogin = useLoginCheck();
    const [cpnCnt, setCpnCnt] = useState({
        totCnt: 0,
        leftCnt: 0,
    });
    const { totCnt, leftCnt } = cpnCnt;
    const [ediyaCode, setEdiyaCode] = useState(null);
    const [isEdiyaIssue, setIsEdiyaIssue] = useState(false);
    const [copyCpn, setCopyCpn] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button4_이벤트_이디아이벤트_click','event_button4_이벤트_이디아이벤트_click','event_button4_이벤트_이디아이벤트_click');
            }
        }
    }, []);

    // console.log(" isLogin ", isLogin);
    useEffect(() => {
        dispatch(ediyaCpnCnt()); //쿠폰발급수, 총수
    }, []);

    //총쿠폰수, 발급수
    const { data, code } = useSelector((state) => ({
        data: state.ediya.ediyaCpnCnt.data,
        code: state.ediya.ediyaCpnCnt.code,
    }));

    //쿠폰잔여수, 총수 렌더링
    useEffect(() => {
        if (data !== null) {
            setCpnCnt({
                ...cpnCnt,
                totCnt: data.totCnt,
                leftCnt: data.totCnt - data.cnt,
            });
        }
    }, [data]);

    useEffect(() => {
        if (isLogin) {
            dispatch(myEdiyaCpnCnt()); //회원쿠폰 발급여부
        }
    }, [isLogin]);

    const { myEdiyaCnt, myEdiyaCntCode } = useSelector((state) => ({
        myEdiyaCnt: state.ediya.myEdiyaCpnCnt.data,
        myEdiyaCntCode: state.ediya.myEdiyaCpnCnt.code,
    }));

    //쿠폰 발급여부 렌더링
    useEffect(() => {
        if (myEdiyaCnt !== null) {
            // console.log(" myEdiyaCnt ", myEdiyaCnt.issueCnt);
            if (myEdiyaCnt.issueCnt > 0) {
                setIsEdiyaIssue(true);
                dispatch(myEdiyaCpn());
            }
        }
    }, [myEdiyaCnt]);

    const { myEdiyaCpnNo, myEdiyaCpnNoCode } = useSelector((state) => ({
        myEdiyaCpnNo: state.ediya.myEdiyaCpn.data,
        myEdiyaCpnNoCode: state.ediya.myEdiyaCpn.code,
    }));

    useEffect(() => {
        if (myEdiyaCpnNo !== null) {
            setEdiyaCode(myEdiyaCpnNo.code);
        }
    }, [myEdiyaCpnNo]);

    const handleIssueCode = (issueCode) => {
        // console.log(" handleIssueCode issueCode ", issueCode);
        setEdiyaCode(issueCode);
        setIsEdiyaIssue(true);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopyCpn(false);
        }, 1500);
        return () => {
            clearTimeout(timer);
        };
    }, [copyCpn]);
    //쿠폰코드 복사
    const handleCopyCode = (e) => {
        if (ediyaCode !== null) {
            // setCopyCpn(true);
            handleAppBridge({
                fnName: "setCopy",
                param: [ediyaCode, "쿠폰코드가 복사되었습니다."],
            });
        }
    };

    //쿠폰등록하러가기
    const handleCouponRegist = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: "https://m.site.naver.com/1b6Do",
            // param: "https://members.ediya.com/deepLink/",
        });
    };

    return (
        <div className="ediya-wrap">
            <div className="ediya-top">
                <img src={topBnr} />
                <EdiyaCpnCnt cpnCnt={cpnCnt} />
            </div>
            <div className="ediya-mid">
                <div className="inner">
                    <div className="mid-each">
                        <h3 className="guide">이벤트 안내</h3>
                        <div className="cont-bg">
                            <dl>
                                <dt>이벤트 대상 :</dt>
                                <dd>모든 회원</dd>
                            </dl>
                            <dl>
                                <dt>발급 가능 매수 :</dt>
                                <dd>1인당 1회 발급 가능</dd>
                            </dl>
                            <dl>
                                <dt>쿠폰 수량 :</dt>
                                <dd>10,000개 (소진 시 종료)</dd>
                            </dl>
                            <dl>
                                <dt>쿠폰 유효 기간 :</dt>
                                <dd>등록일로부터 90일</dd>
                            </dl>
                            <dl>
                                <dt>쿠폰 등록 기간 :</dt>
                                <dd>2023.10.05 ~ 2024.03.16</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="mid-each">
                        <h3 className="usage">쿠폰 사용 방법</h3>
                        <div className="cont-bg">
                            <ol>
                                <li>
                                    1. 하단의{" "}
                                    <b>‘이디야 아메리카노(L)’ 쿠폰받기</b>
                                </li>
                                <li>
                                    2. 발급받은 <b>쿠폰 번호 복사하기</b>
                                </li>
                                <li>
                                    3. <b>이디야 멤버스 APP 가입</b> 후 쿠폰
                                    등록하기
                                </li>
                            </ol>
                            <p>
                                *경로 : 쿠폰 -> 우상단의 + 버튼 클릭 -> 쿠폰
                                번호 입력하기
                            </p>
                        </div>
                    </div>
                    {!isEdiyaIssue ? (
                        <EdiyaIssue onIssueCode={handleIssueCode} />
                    ) : null}
                </div>
            </div>
            {isEdiyaIssue ? (
                <div className="ediya-cpn-regi">
                    <div className="inner">
                        <h3>쿠폰 발급 완료!</h3>
                        <p>이디야 멤버스 APP에서 쿠폰을 등록해 주세요.</p>
                        <div className="sr-num-copy">
                            {/*<p>6NJDIKFGJH343222</p>*/}
                            <p>{ediyaCode}</p>
                            <button type="button" onClick={handleCopyCode}>
                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    onCopy={(e) => handleCopyCode}*/}
                                {/*>*/}
                                복사
                            </button>
                        </div>
                        <button type="button" onClick={handleCouponRegist}>
                            쿠폰 등록하러 가기
                        </button>
                    </div>
                </div>
            ) : null}

            <div className="cpn-warn">
                <div className="inner">
                    <strong>쿠폰 유의사항</strong>
                    <ul>
                        <li>
                            본 쿠폰은 AI페퍼스 배구단에서 제공하는 쿠폰입니다.
                        </li>
                        <li>
                            본 쿠폰은 이디야 전국 매장에서 사용 가능합니다.
                            (일부 특수 매장 불가)
                        </li>
                        <li>
                            본 쿠폰은 NEW 아메리카노(L)를 무료로 이용하실 수
                            있습니다. (타 제품 쿠폰적용 가능)
                        </li>
                        <li>
                            본 쿠폰 금액보다 낮은 금액의 제품 이용 시에 잔액은
                            반환되지 않으며, 높은 금액의 제품 이용 시에는 추가
                            금액을 지불해야 합니다.
                        </li>
                        <li>본 쿠폰은 온라인 주문에서도 사용이 가능합니다.</li>
                        <li>
                            본 쿠폰의 유효 기간 연장은 불가하며, 유효 기간이
                            지나면 쿠폰은 자동 소멸됩니다.
                        </li>
                        <li>본 쿠폰 사용 시 캡처 화면은 이용이 제한됩니다.</li>
                    </ul>
                </div>
            </div>
            {copyCpn ? (
                <div className="cpn-copy-comp">
                    <div className="inner">
                        <p>쿠폰코드가 복사되었습니다.</p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default EdiyaEvent;
