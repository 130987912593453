import { createSlice } from "@reduxjs/toolkit";

export const snsSlice = createSlice({
    name: "sns", // slice name
    initialState: {
        list: {
            count: 0,
            data: null,
        },
        view: {
            data: null,
        },
    },
    reducers: {
        snsGetList: (state) => {
            state.list.count = 0;
            state.list.data = null;
        },
        snsGetView: (state) => {
            state.view.data = null;
        },
        snsGetListSuccess: (state, { payload: { count, data } }) => {
            state.list.count = count;
            state.list.data = data;
        },
        snsGetViewSuccess: (state, { payload: { count, data } }) => {
            state.view.data = data;
        },
    },
});

export const { snsGetList, snsGetView, snsGetListSuccess, snsGetViewSuccess } =
    snsSlice.actions;
