import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./about.reducer";

const initialState = {
    list: {
        loading:true,
        data: {},
        error:null
    },
    menuList: {
        typeCd: "",
        data: [],
    },
};

export const aboutSlice = createSlice({
    name: "about",
    initialState,
    reducers,
});

export const { aboutGetMenuList, aboutGetMenuListSuccess, aboutGetList, aboutGetListSuccess, aboutGetListFailure } = aboutSlice.actions;
