import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IOS, TOKEN } from "../../constant/contant";
import { eventVoteCheck } from "./module";
import { handleAppBridge, scrollError } from "../../libs/js/common";
import { playerGetList } from "../player/module";
import PlayerTabs from "../player/playerTabs";
import Athlete from "../player/athlete";
import { BtnDefault } from "../../components/common/common";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import useLoginCheck from "../../hooks/useLoginCheck";

const VoteList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { event_match } = useParams();
    const location = useLocation();
    const pageType = location.state?.pageType;

    const { type, data } = useSelector((state) => ({
        type: state.player.list.type,
        data: state.player.list.data,
    }));
    const { eventData } = useSelector((state) => ({
        eventData: state.event.voteCheck.data,
    }));

    const [playerPosition, setPlayerPosition] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState({
        player_id: null,
        name: null,
        match_id: event_match,
    });

    const isLogin = useLoginCheck();

    // tab
    const handleTabClick = (name) => {
        if (playerPosition !== name) {
            if (name === "all") {
                setPlayerPosition("");
            } else {
                setPlayerPosition(name);
            }
            setIsLoading(true);
        }
    };

    // 이벤트 > 투표하기
    const handleVotingClick = () => {
        if (isLogin) {
            const { name, match_id, player_id } = userInfo;
            const message = `수훈 예측선수 : ${name}\n입력하신 정보가 맞으신가요?`;
            const { search } = window.location;

            if (name) {
                // 확인
                if (window.confirm(message)) {
                    navigate(`/eventApply/${match_id}/${player_id}${search}`);
                }
            } else {
                alert("예상 수훈선수를 선택해 주세요.");
            }
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    useEffect(() => {
        if (pageType === "view" && IOS) {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    useEffect(() => {
        dispatch(
            playerGetList({
                type: playerPosition,
            }),
        );
    }, [playerPosition]);

    // match id
    useEffect(() => {
        dispatch(
            eventVoteCheck({
                match_id: event_match,
            }),
        );
    }, [event_match]);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    if (!eventData) return false;

    console.log(eventData);
    console.log(data);
    return (
        <div className="event-voting">
            <div
                className={classNames(
                    "inner",
                    eventData?.player_id && "voting",
                )}
            >
                <PlayerTabs
                    type={playerPosition}
                    handleTabClick={handleTabClick}
                />
                <ul className="player-list-wrap">
                    {data?.length && (
                        <>
                            {data.map((post) => (
                                <Athlete
                                    post={post}
                                    key={post.id}
                                    userInfo={userInfo}
                                    setUserInfo={setUserInfo}
                                    type="event"
                                    playerId={eventData?.data.player_id}
                                />
                            ))}
                        </>
                    )}
                    {!data?.length && (
                        <span className="description">
                            선수리스트가 없습니다.
                        </span>
                    )}
                </ul>
                {!eventData?.data.player_id && (
                    <div className="button-wrap floating">
                        <BtnDefault
                            text="투표 참여하기"
                            className="btn-gradient-red"
                            size="block"
                            onClick={handleVotingClick}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default VoteList;
