import React, { useEffect, useState } from "react";
import TicketCouponBuy from "./ticketCouponBuy";
import { useDispatch, useSelector } from "react-redux";
import { getMyPoint } from "./module";

const TicketCouponBuyCon = () => {
    const dispatch = useDispatch();
    const [useRefresh, setRefresh] = useState(true);
    const [useCode, setCode] = useState({ code: null });

    // point store
    const { data: getMyPointArr, code } = useSelector((state) => {
        return state.myTicket.buyList;
    });

    // buy store
    const buySuccessArr = useSelector((state) => {
        return state.myTicket.buyCode;
    });

    useEffect(() => {
        dispatch(getMyPoint());
    }, []);

    useEffect(() => {
        if (buySuccessArr?.data && buySuccessArr?.code === "200") {
            const code = buySuccessArr.data.code;
            setCode({ ...useCode, code });
        } else {
            if (buySuccessArr?.code === "E156") {
                alert(buySuccessArr?.msg);
            }
        }
    }, [buySuccessArr]);

    if (!getMyPointArr) return false;

    return (
        <TicketCouponBuy
            getMyPointArr={getMyPointArr}
            buySuccessArr={buySuccessArr.data}
            setRefresh={setRefresh}
            useRefresh={useRefresh}
            dispatch={dispatch}
            useCode={useCode}
        />
    );
};

export default TicketCouponBuyCon;
