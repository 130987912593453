import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./privacy.reducer";

export const privacySlice = createSlice({
    name: "privacy",
    initialState: {
        list: {
            name: "",
            data: null,
        },
        html: {
            id: null,
            name:"",
            text: "",
        }
    },
    reducers,
});

export const { privacyGetList, privacyGetListSuccess, privacyGetHtml, privacyGetHtmlSuccess } = privacySlice.actions;
