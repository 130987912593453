import React from "react";
import Apply from "../apply/apply";
import { luckyDrawNoteList } from "./luckyDrawViewItem";

const LuckyDrawApply = () => {
    return (
        <Apply
            noteTitle="개인정보 취급 및 유의사항 동의"
            noteList={luckyDrawNoteList}
        />
    );
};

export default LuckyDrawApply;
