import React, { useEffect, useState } from "react";
import MatchPredictionView from "./matchPredictionView";
import { useDispatch, useSelector } from "react-redux";
import { checkApply, getMatchView } from "./module";
import { useParams } from "react-router";
import { handleAppBridge } from "../../libs/js/common";
const MatchPredictionViewCon = () => {
    const { match_id } = useParams();
    const dispatch = useDispatch();

    const getMatchViewRes = useSelector((state) => {
        return state.matchPrediction.getMatchView;
    });

    const applyCheck = useSelector((state) => {
        return state.matchPrediction.checkApply;
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(
            getMatchView({
                match_id: match_id,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (getMatchViewRes) {
            if (getMatchViewRes)
                dispatch(
                    checkApply({
                        matchId: match_id,
                        victoryId: getMatchViewRes.MatchVictory.id,
                    }),
                );
        }
    }, [getMatchViewRes]);

    useEffect(() => {
        if (getMatchViewRes) {
            handleAppBridge({
                fnName: "startLoading",
            });
            setIsLoading(false);
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    if (!getMatchViewRes || getMatchViewRes?.length <= 0) return false;

    return (
        <MatchPredictionView
            getMatchViewRes={getMatchViewRes}
            applyCheck={applyCheck}
        />
    );
};

export default MatchPredictionViewCon;
