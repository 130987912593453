import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {
        type: "",
        count: 0,
        data: null,
        sort: null,
        code: "",
        typeName: "",
    },
    view: {
        code: "",
        data: null,
        comments: null,
    },
};

export const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        videoGetList: (state, { payload: { type } }) => {
            state.list.type = type;
            state.list.count = 0;
            state.list.data = null;
        },
        videoGetListSuccess: (state, { payload: { data, code } }) => {
            state.list.count = data.count;
            state.list.data = data.boardList;
            state.list.sort = data.typeList;
            state.list.code = code;
            state.list.typeName = data.typeName;
            state.list.aspectCode = data.aspectCd;
        },
        videoGetView: (state) => {
            // state.view.code = null;
            // state.view.data = null;
            // state.view.comments = null;
        },
        videoGetViewSuccess: (state, { payload: { data, code } }) => {
            state.view.code = code;
            state.view.data = data.board;
            state.view.comments = data.comment;
        },
    },
});

export const {
    videoGetList,
    videoGetListSuccess,
    videoGetView,
    videoGetViewSuccess,
} = videoSlice.actions;
