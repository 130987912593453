import React, { useEffect, useState } from "react";
import FaqTabs from "./faqTabs";
import { useDispatch, useSelector } from "react-redux";
import { faqGetList } from "./module";
import AccordionItem from "./AccordionItem";
import { handleAppBridge } from "../../libs/js/common";

const Faq = () => {
    const dispatch = useDispatch();
    const { type, data } = useSelector((state) => ({
        type: state.faq.list.type,
        data: state.faq.list.data,
    }));
    const [faqType, setFaqType] = useState(type);
    const [isClick, setIsClick] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    // tab
    const handleTabClick = (e) => {
        e.preventDefault();
        const { name } = e.target;

        if (type !== name) {
            setFaqType(name);
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    };

    // faq 아코디언
    const handleToggle = (id) => {
        if (isClick === id) {
            return setIsClick(null);
        }
        setIsClick(id);
    };

    useEffect(() => {
        dispatch(
            faqGetList({
                type_cd: faqType,
            }),
        );
    }, [faqType]);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    return (
        <div className="faq-wrap">
            <div className="inner">
                <FaqTabs faq={faqType} handleTabClick={handleTabClick} />
                <div className="faq-inner">
                    <ul className="faq-list">
                        {data &&
                            data.map((post) => {
                                return (
                                    <AccordionItem
                                        key={post.id}
                                        post={post}
                                        onToggle={() => handleToggle(post.id)}
                                        active={isClick === post.id}
                                    />
                                );
                            })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Faq;
