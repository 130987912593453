import React, { useEffect } from "react";
import { BtnDefault } from "../../components/common/common";
import { handleAppBridge } from "../../libs/js/common";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Ticket = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button3_AI페퍼스_티켓예매_click','AI_peppers_button3_AI페퍼스_티켓예매_click','AI_peppers_button3_AI페퍼스_티켓예매_click');
            }            
        } else {
            TrackGoogleAnalyticsEvent('maintab1_banner4_티켓예매_click','maintab1_banner4_티켓예매_click','maintab1_banner4_티켓예매_click');
        }
    }, []);
    
    const handleClick = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            // param: "http://ticket.interpark.com/m-ticket/Sports/GoodsInfo?SportsCode=07004&TeamCode=PV027",
            param: "https://kovo.co.kr/aipeppers/ticket/ticket-buy",
        });
    };
    const handleLinkClick = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: "https://elypecs.com/platform/ZR099/index.html",
        });
    };

    return (
        <div className="ticket-wrap">
            {/*<div className="banner">*/}
            {/*    <a href="javascript:void()" onClick={handleLinkClick} />*/}
            {/*</div>*/}
            <div className="inner">
                <div className="ticket-img">좌석 이미지</div>
                <div className="ticket-price-wrap">
                    <div className="ticket-price family">
                        <span className="mark">1층</span>
                        <strong className="title">패밀리존</strong>
                        <span className="description">
                            #경기 종료 후 선수와 기념 촬영
                            <br />
                            #음료 무제한 제공
                        </span>
                        <div className="price">
                            구역당 160,000원(최대 6인 착석)
                        </div>
                    </div>
                    <div className="ticket-price ai-a">
                        <span className="mark">1층</span>
                        <strong className="title">AI존</strong>
                        {/*<span className="description">#경기를 가깝게!</span>*/}
                        <div className="price">22,000원</div>
                    </div>
                    <div className="ticket-price ai-b">
                        <span className="mark">1층</span>
                        <strong className="title">1층 원정석</strong>
                        {/*<span className="description">*/}
                        {/*    #더욱 생동감 넘치게!*/}
                        {/*</span>*/}
                        <div className="price">20,000원</div>
                    </div>
                    <div className="ticket-price lotte-outlet">
                        <span className="mark">2층</span>
                        <strong className="title">롯데아울렛 월드컵석</strong>
                        <span className="description">
                            #매 경기 관람 고객 추첨 F&B(식당가) 2만원 이용권
                            증정 <br />
                            #베비에르 "아메리카노 2잔 + 베이커리"세트
                            교환권(1매) 증정
                        </span>
                        <span className="sub-desc">
                            - 교환권 증정 장소 : 롯데아울렛월드컵점 1층
                            사은데스크 <br />
                            (방문 시 티켓 지참 필수 / 경기 당월 內 교환 및 사용)
                        </span>
                        <div className="price">30,000원(2인 테이블석)</div>
                    </div>
                    <div className="ticket-price puma">
                        <span className="mark">2층</span>
                        <strong className="title">푸마존</strong>
                        <span className="description">
                            #푸마 경품 제공
                            <br />
                            #매 경기 포토카드 증정
                            <br />
                            #해당 구역 누적 관람 고객 <br />
                            대상 특별 경품
                        </span>
                        <div className="price">10,000원</div>
                    </div>
                    <div className="ticket-price ediya">
                        <span className="mark">2층</span>
                        <strong className="title">이디야 커피존</strong>
                        <span className="description">
                            #관람 고객 대상 이디야 커피 <br />
                            쿠키 전원 증정 <br />
                            #매 경기 5만원 기프트카드 <br />
                            2명 추첨 증정
                        </span>
                        <div className="price">10,000원</div>
                    </div>
                    <div className="ticket-price pepper">
                        <span className="mark">2층</span>
                        <strong className="title">크레이지광주존</strong>
                        <span className="description">
                            #치어리더와 함께 응원 <br />
                            #현장 경품 확률 UP!
                        </span>
                        <div className="price">12,000원</div>
                    </div>
                    <div className="ticket-price crazy">
                        <span className="mark">2층</span>
                        <strong className="title">크레이지페퍼스존</strong>
                        <div className="price">8,000원</div>
                    </div>
                    <div className="ticket-price away">
                        <span className="mark">2층</span>
                        <strong className="title">2층 원정석</strong>
                        <div className="price">10,000원</div>
                    </div>
                    <div className="ticket-price regular">
                        <span className="mark">2층</span>
                        <strong className="title">2층 일반석</strong>
                        <div className="price">10,000원</div>
                    </div>
                    <div className="ticket-price esg">
                        <span className="mark">2층</span>
                        <strong className="title">2층 비지정석</strong>
                        <div className="price">8,000원</div>
                    </div>
                    <div className="ticket-price third-floor">
                        <span className="mark">3층</span>
                        <strong className="title">3층 비지정석</strong>
                        <div className="price">4,000원</div>
                    </div>
                </div>
            </div>
            <div className="ticket-inform">
                <h1 className="title">무료 관람 대상자</h1>
                <div className="content">
                    <ul className="text-list">
                        <li>경로 (만 65세이상) : 비지정석</li>
                        <li>휠체어장애인 : 2층 휠체어석</li>
                        <li>일반장애인 : 비지정석</li>
                        <li>국가유공자 : 비지정석</li>
                        <li>
                            미취학아동 (만7세미만) : 2층 전좌석 / 3층 전좌석
                        </li>
                        <li>군인, 경찰,소방관, 교정직 : 비지정석</li>
                        <li className="no-pad">
                            * 현장 매표소에서 신분증 및 관련 증빙 서류 확인 후
                            발권 <br />
                            (매표소 운영 시간 : 경기 시작 2시간 전 오픈) <br />
                        </li>
                        <li className="mt10">
                            광주 지역 배구부 학생, 감독, 코치 : 비지정석
                        </li>
                        <li className="no-pad">
                            * 사전 신청 <br />
                            (여) 치평초 · 광주체중 · 광주체고 <br />
                            (남) 문정초 · 문흥중 · 광주전자공고
                        </li>
                    </ul>
                </div>
                <h1 className="title mt20">할인 관람 대상자</h1>
                <div className="content">
                    <ul className="text-list">
                        <li>문화누리카드 (30% 할인) : 비지정석</li>
                        <li className="no-pad">* 온라인 및 현장 매표소</li>
                    </ul>
                </div>
            </div>
            <div className="ticket-inform">
                <h1 className="title">안내 및 주의사항</h1>
                <div className="content">
                    <ul className="text-list">
                        <li className="sub-title"> 티켓 예매 유의사항</li>
                        <li>1인 최대 4매까지 구매 가능</li>
                        <li>예매수수료 장당 1,000원</li>
                        <li>
                            예매 오픈시간 : 경기 5일전 11시 시작 (상황에 따라
                            변동될 수 있음)
                        </li>
                        <li className="no-pad">
                            * 멤버십 선예매는 경기 7일전 11시 시작
                        </li>
                        <li>
                            예매 가능시간 : 경기당일 경기시작 시간 1시간 후 마감
                        </li>
                        <li>
                            예매티켓 취소 가능시간 : 경기당일 경기시간 4시간
                            전까지
                        </li>
                        <li>
                            현장 매표소 운영시간 : 경기 시작 2시간 전 시작 (변경
                            시 별도 공지)
                        </li>
                        <li className="no-pad">
                            * 경기 당일 매표소에서 현장 예매 가능
                        </li>
                        <li className="sub-title">경기 당일 안내사항</li>
                        <li>
                            경기당일 매표소가 혼잡하오니, 모바일티켓을 활용하여
                            편안한 입장을 권장드립니다.
                        </li>
                        <li>
                            모바일 티켓은 바로 출입구에서 입장이 가능합니다.
                        </li>
                        <li>
                            모바일 티켓 캡쳐 혹은 이미지는 입장 불가 (어플에서
                            예약번호 혹은 바코드가 실시간으로 움직이는 티켓만
                            입장 가능)
                        </li>
                        <li>
                            모바일 티켓으로 티켓선물하기 기능 사용하여 티켓을
                            선물한 경우 취소 불가 (선물한 티켓이 반납된 이후
                            취소가능)
                        </li>
                        <li className="sub-title">취소 수수료</li>
                        <li>
                            예매 당일은 취소수수료가 없으며, 예매수수료도
                            환불됩니다.
                        </li>
                        <li>
                            예매 익일 이후로는 티켓금액의 10% 취소수수료
                            부과되며, 예매수수료도 환불되지 않습니다.
                        </li>
                        <li>경기일자 및 좌석변경은 불가합니다.</li>
                        <li>
                            부분취소는 불가합니다. 기존 건을 전체취소후 재
                            예매하셔야 하며, 취소좌석에 대한 좌석선점은 보장되지
                            않습니다.
                        </li>
                        <li className="sub-title">
                            경로, 장애인,
                            국가유공자,미취학아동,군인,경찰,소방관,교정직 예매
                            안내
                        </li>
                        <li>
                            현장에서 각 해당 신분증 확인 후, 입장권을 발권하셔야
                            입장하실 수 있습니다.(잔여좌석에 한해 현장
                            발권/무료)
                        </li>
                        <li className="sub-title">휠체어석 입장 안내</li>
                        <li>
                            휠체어석은 현장매표소에서 발권 후 입장하실 수
                            있습니다. <br />
                            (경기 당일 체육관 사정에 따라 발권 매수가
                            제한됩니다.)
                        </li>
                        <li className="sub-title">
                            티켓구매처 : 한국배구연맹
                            통합홈페이지(https://www.kovo.co.kr)
                        </li>
                    </ul>
                </div>
            </div>
            <div className="button-wrap">
                <BtnDefault
                    size="block"
                    text="티켓 예매 바로가기"
                    onClick={handleClick}
                    className="btn-gradient-red"
                />
            </div>
        </div>
    );
};

export default Ticket;
