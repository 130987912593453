import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { match } from "./module";
import MatchList from "./matchList";

const MatchListCon = ({ useListParam, setListParam }) => {
    const {
        league_id,
        league_detail_id,
        nextMatch_id,
        isloding,
        seezn,
        callAPI,
    } = useListParam;

    const dispatch = useDispatch();
    // Match List
    const matchListRes = useSelector((state) => {
        return state.match.matchList;
    });

    useEffect(() => {
        if (callAPI) {
            if (nextMatch_id !== null) {
                dispatch(
                    match({
                        league_id,
                        league_detail_id,
                    }),
                );
            } else {
                dispatch(match());
            }
            setListParam({
                ...useListParam,
                callAPI: false,
            });
        }
    }, [useListParam]);

    useEffect(() => {
        if (!seezn && isloding && matchListRes) {
            if (matchListRes.length > 0) {
                const { league_id, league_detail_id } = matchListRes[0];
                setListParam({
                    ...useListParam,
                    isloding: false,
                    league_id,
                    league_detail_id,
                    nextMatch_id: false,
                    seezn: false,
                });
            } else {
                console.log("AA");
                setListParam({
                    ...useListParam,
                    isloding: false,
                });
            }
        }
    }, [matchListRes]);

    if (!matchListRes) return false;

    return <MatchList resData={matchListRes} nextMatch_id={nextMatch_id} />;
};

export default MatchListCon;
