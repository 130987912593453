import { useDispatch, useSelector } from "react-redux";
import StaffTabs from "./staffTabs";
import { useEffect, useState, useCallback } from "react";
import { staffGetList } from "./module/staff.slice";
import { handleAppBridge } from "../../libs/js/common";
import { FULL_URL } from "constant/contant";

import StaffBottom from "./staffBottom";
import { getStaff } from "./module/staff.selectors";

const StaffContent = ({ stafftype }) => {
    const [showId,setShowId] = useState(0);
    const [bottomShow, setBottomShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { data: staffList, loading} = useSelector(getStaff);
    const filterStaffList = stafftype === '' ? staffList : staffList.filter(staff => staff.typeCd === stafftype);

    useEffect(() => {
        handleAppBridge({ fnName: loading ? "startLoading" : "stopLoading" });
    }, [loading]);


    function handleBottomShow(id){
        setShowId(id)
        setBottomShow(true)
    }

    function handleBottomHide(){
        setBottomShow(false)
    }
    
    if (!staffList) return null;

    return (
        <section className="staff-section">
            <div className="staff-list">
                {staffList && staffList.length > 0 ? (
                    filterStaffList.map((staff,idx) => { 
                        const IMG_URL = `${FULL_URL}file${staff.filePath}${staff.fileNo}`;
                        return (
                            <div key={staff.id} className="staff-card" onClick={() => handleBottomShow(staff.id)}>
                                <div className="top-info">
                                    <strong>{staff.name}</strong>
                                    <p>{staff.typeCdNm}</p>
                                </div>
                                <div className="image-wrap">
                                    <img src={IMG_URL} alt="Director" />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div>없음</div>
                )}
            </div>
            <StaffBottom staffList={staffList} show={bottomShow} showId={showId} handleBottomHide={handleBottomHide} />
        </section>
    );
};

export default StaffContent;
