import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./player.reducer";

// 선수 페이지
const initialState = {
    list: {
        count: 999,
        type: "all",
        data: null,
    },
};

export const playerSlice = createSlice({
    name: "player",
    initialState,
    reducers,
});

export const { playerGetList, playerGetListSuccess } = playerSlice.actions;
