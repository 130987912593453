import { put, takeEvery, call } from "redux-saga/effects";
import {
    videoGetList,
    videoGetListSuccess,
    videoGetView,
    videoGetViewSuccess,
} from "./video.slice";
import { videoAPI, videoViewAPI } from "../API/videoAPI";

function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(videoAPI, params);
        yield put(videoGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* videoSaga() {
    yield takeEvery(videoGetList, getList);
}

function* getView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(videoViewAPI, params);
        yield put(videoGetViewSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* videoViewSaga() {
    yield takeEvery(videoGetView, getView);
}
