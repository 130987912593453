import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { noticeGetList } from "./module";
import NoticeListItem from "./noticeListItem";
import Infinite from "../../components/infinite/infinite";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const NoticeList = () => {
    const { count, data } = useSelector((state) => ({
        count: state.news.noticeList.count,
        data: state.news.noticeList.data
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button5_AI페퍼스_공지사항_click','AI_peppers_button5_AI페퍼스_공지사항_click','AI_peppers_button5_AI페퍼스_공지사항_click');
            } else if( gb === 'm1_no' ) {
                TrackGoogleAnalyticsEvent('maintab1_notice_공지_더보기_click','maintab1_notice_공지_더보기_click','maintab1_notice_공지_더보기_click');
            }            
        } 
    }, []);

    const el = document.querySelector("#header .page-name");
    if (el) {
        el.textContent = "공지사항";
    }

    const onItemComponent = (post) => {
        return <NoticeListItem data={post} key={post.id} />;
    };

    const onNoneResultComponent = () => (
        <p className="list-none">공지사항이 없습니다.</p>
    );

    return (
        <div className="notice-list">
            <Infinite
                count={count}
                data={data}
                onGetList={noticeGetList}
                onItemComponent={onItemComponent}
                onNoneResultComponent={onNoneResultComponent}
            />
        </div>
    );
};

export default NoticeList;
