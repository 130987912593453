import { put, takeEvery, call } from "redux-saga/effects";
import { gatewayGetAPI } from "../API/gatewayAPI";
import { gatewayGetDetail, gatewayGetDetailSuccess } from "./gateway.slice";

// Player List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(gatewayGetAPI, params);
        yield put(gatewayGetDetailSuccess(data));
    } catch (err) {}
}

export function* gatewayDetailSaga() {
    yield takeEvery(gatewayGetDetail, getList);
}