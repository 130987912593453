import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useLoginCheck = () => {
    const { loginCheck } = useSelector((state) => ({
        loginCheck: state.auth.loginCheck?.result,
    }));

    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        // 로그인 성공 시
        if (loginCheck === "Y") {
            setIsLogin(true);
        }
    }, [loginCheck]);

    return isLogin;
};

export default useLoginCheck;
