import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const pepperNewsListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "sns/info/showSns",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};

export const pepperNewsViewAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "sns/info/showSnsView",
            data: params,
        });

        return response;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
