import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { NAVIGATION } from "../../constant/navigation";
import { handleAppBridge } from "../../libs/js/common";
import { BtnDefault } from "../common/common";
import classNames from "classnames";
import { useParams } from "react-router";
import { loginCheck } from "./module";
import { useDispatch } from "react-redux";

const Header = () => {
    const location = useLocation();
    const { pathname } = location;
    const { type } = useParams();

    const isLocation = pathname.indexOf("playerView") > -1;
    const isVideoPage = pathname.indexOf("video") > -1 && !!type;

    const [pageName, setPageName] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loginCheck());
    }, []);

    const handleGoBack = () => {
        handleAppBridge({
            fnName: "goBack",
        });
    };
    const handleClick = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: "https://kovo.co.kr/aipeppers/game/v-league?first=%ED%8C%80+%EC%88%9C%EC%9C%84",
        });
    };

    // 페퍼 시리즈 상세 > 타이틀
    const getVideoTitle = () => {
        switch (type) {
            case "on":
                return "페퍼 ON";
            case "youtube":
                return "페퍼 유튜브";
            case "archive":
                return "아카이브";
            default:
                return "미디어";
        }
    };

    useEffect(() => {
        NAVIGATION.map((item) => {
            const { name, path } = item;
            const isMatch = matchPath(path, pathname);

            isMatch && setPageName(name);
        });
    }, [location]);

    return (
        <header id="header" className={classNames(isLocation && "player-view")}>
            <button
                type="button"
                className="btn-page-back"
                onClick={handleGoBack}
            >
                뒤로 가기
            </button>
            <h2 className="page-name">
                {!isVideoPage ? pageName : getVideoTitle()}
            </h2>

            {pageName === "경기일정" && (
                <BtnDefault
                    text={"팀 순위"}
                    onClick={handleClick}
                    className={"team-rank-btn"}
                />
            )}
        </header>
    );
};

export default Header;
