import { put, takeEvery, call } from "redux-saga/effects";
import {
    getApplyEventAPI,
    getLeaguesAPI,
    isMyVoteAPI,
    votePlayerAPI,
    getEdiyaCpnCntAPI,
    getEdiyaCpnIssueAPI,
    getMyEdiyaCpnAPI,
} from "../API/eventAPI";
import {
    eventVoteCheck,
    eventVoteCheckSuccess,
    eventVoting,
    eventVotingSuccess,
    eventLeagues,
    eventLeaguesSuccess,
    eventApply,
    eventApplySuccess,
} from "./event.slice";

// 투표하기
function* getApply(action) {
    const { payload: params } = action;

    try {
        const data = yield call(votePlayerAPI, params);
        yield put(eventVotingSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* eventVotingSaga() {
    yield takeEvery(eventVoting, getApply);
}

// 투표 중복체크
function* getApplyCheck(action) {
    const { payload: params } = action;

    try {
        const data = yield call(isMyVoteAPI, params);
        yield put(eventVoteCheckSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* voteCheckSaga() {
    yield takeEvery(eventVoteCheck, getApplyCheck);
}

// 저장된 투표 정보 불러오기
function* getLeagues(action) {
    const { payload: params } = action;

    try {
        const result = yield call(getLeaguesAPI, params);
        yield put(
            eventLeaguesSuccess({
                data: result.data,
                code: result.code,
            }),
        );
    } catch (error) {
        console.error(error);
    }
}

export function* getLeaguesSaga() {
    yield takeEvery(eventLeagues, getLeagues);
}

/*
function* getUserApply() {
    try {
        const data = yield call(getApplyEventAPI);
        yield put(eventApplySuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* getEventApplySaga() {
    yield takeEvery(eventApply, getUserApply);
}
*/
