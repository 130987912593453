import React from "react";
import { BtnRound } from "../../components/common/common";
import { handleAppBridge } from "../../libs/js/common";
import classNames from "classnames";

const MyTicketItem = ({ data, onToggle, active }) => {
    const { used_date, code } = data;

    const handleCopy = () => {
        // 복사하기
        handleAppBridge({
            fnName: "setCopy",
            param: [code, "할인코드가 복사되었습니다."],
        });
    };

    return (
        <li className={classNames("ticket-list", active && "active")}>
            <div className="title-wrap" onClick={onToggle}>
                <span className="date">{used_date}</span>
                <strong className="title">티켓 할인코드 구매</strong>
            </div>
            <div className="coupon">
                <p className="num">{code}</p>
                <BtnRound
                    text="복사"
                    size="small"
                    className="line"
                    onClick={handleCopy}
                />
            </div>
        </li>
    );
};

export default MyTicketItem;
