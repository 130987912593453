import { put, takeEvery, call } from "redux-saga/effects";
import { merchandiseAPI } from "../API/merchandaiseAPI";
import {
    merchandiseGetList,
    merchandiseGetListSuccess,
} from "./merchandise.slice";

function* getList(action) {
    const { payload: params } = action;

    try {
        const result = yield call(merchandiseAPI, params);
        const isData = result.data?.MerchandiseList?.length;

        yield put(
            merchandiseGetListSuccess({
                count: result.count,
                data: isData ? result.data.MerchandiseList : result.data,
            }),
        );
    } catch (error) {
        console.error(error);
    }
}

export function* merchandiseSaga() {
    yield takeEvery(merchandiseGetList, getList);
}
