import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./matchPrediction.reducer";
const name = "matchPrediction";

const initialState = {
    matchInfo: null,
    matchVictory: null,
    getMatchView: null,
    checkApply: null,
};

export const matchPredictionSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const { matchInfo, matchVictory, getMatchView, checkApply } =
    matchPredictionSlice.actions;
