import moment from "moment";
import React from "react";

// 날짜 포맷 변경
export const setDateFormat = (date, type) => {
    switch (type) {
        case "short":
            // ex) 2022.10.01
            if (typeof date === "string") {
                //IOS undefined Issue
                return date;
            } else {
                return moment(date).format("YYYY.MM.DD");
            }
        case "short2":
            return moment(date).format("YYYY.MM.DD");
        case "ko":
            // ex) 2022년 10월 01일 토요일 오후 9:10
            return moment(date).format("YYYY년 MMM DD일 dddd A h:mm");
        case "ko-short":
            // ex) 2022년 10월 01일 토요일 오후 9:10
            return moment(date).format("YYYY년 MMM DD일 dddd");
        default:
            // ex) 2022.10.01 21:10
            return moment(date).format("YYYY.MM.DD HH:mm");
    }

    
};

export const isSameDate = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

// 현재 페이지로 돌아오기
export const returnCurrentPage = () => {
    const { pathname, search } = window.location;
    window.location.replace(pathname + search);
};

// useragent 가져오기
export const getUserAgent = () => {
    const urlParams = new URL(window.location.href).searchParams;
    return urlParams.get("useragent");
};

// App 함수 호출
export const handleAppBridge = (options) => {
    const isAOS = getUserAgent() === "WA";
    const isIOS = getUserAgent() === "WI";

    const { fnName = "" } = options;
    const { param } = options;
    let { callbackName = "" } = options;

    let parameter = param
        ? typeof param === "object"
            ? param.join("|")
            : param
        : "";

    if (fnName === "goLogin") {
        callbackName = "returnCurrentPage";
    }

    // iOS 함수 호출
    if (isIOS) {
        const message = {
            function: fnName,
            callback: callbackName,
            parameter,
        };
        window.webkit?.messageHandlers.aiPeppersHandler.postMessage(message);

        return false;
    }

    // AOS 함수 호출
    if (isAOS) {
        window.aiPeppersScript?.[fnName](parameter, callbackName);
        return false;
    }
};

// 데이터 조회
export const getLocalStorage = (name) => localStorage.getItem(name);

// 데이터 추가
export const setLocalStorage = (name, value) =>
    localStorage.setItem(name, value);

// 데이터 삭제
export const removeLocalStorage = (name) => localStorage.removeItem(name);

// API 에러 처리
export const handleError = (code, msg) => {
    alert("잠시 후 다시 시도해 주세요.");
    if (code != 200) {
        const message = msg ?? "잠시 후 다시 시도해 주세요.";
        alert(message);
    }

    handleAppBridge({
        fnName: "goBack",
    });
};

// 스크롤 버그 해결
export const scrollError = () => {
    window.scrollTo({
        top: 1,
        behavior: "smooth",
    });
};

export const scrollObj = {
    state: {
        pageType: "view",
    },
};

// 에디터 특수문자 치환 함수
export const convertSpecialCharacters = (text) => {
    // 추가적인 특수문자 변환 필요한 경우 여기에 추가합니다
    const specialCharacters = {
        "&amp;": "&",
        "&#34": '"',
        "&lt;": "<",
        "&gt;": ">",
        "&#39;": "'",
        "&rdquo;": "”",
        "&ldquo;": "”",
    };

    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    const childNodes = Array.from(tempElement.childNodes);

    // 자식 노드를 JSX로 변환
    return childNodes.map((node, index) => {
        if (node.nodeType === Node.TEXT_NODE) {
            let textContent = node.textContent;

            // 특수문자를 HTML 엔티티로 변환
            Object.entries(specialCharacters).forEach(([entity, character]) => {
                textContent = textContent.split(entity).join(character);
            });

            return textContent;
        } else {
            return React.createElement(node.tagName.toLowerCase(), {
                key: index,
                dangerouslySetInnerHTML: { __html: node.innerHTML },
            });
        }
    });
};
