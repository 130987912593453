import { put, takeEvery, call } from "redux-saga/effects";
import { pepperLiveAPI } from "../API/pepperLiveAPI";
import { pepperGetList, pepperGetListSuccess } from "./pepperLive.slice";

function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(pepperLiveAPI, params);
        yield put(pepperGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* pepperLiveSaga() {
    yield takeEvery(pepperGetList, getList);
}
