import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pepperNewsGetView } from "./module";
import { convertSpecialCharacters, setDateFormat } from "../../libs/js/common";
import { FULL_URL } from "../../constant/contant";
import { Link } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const PepperNewsView = () => {
    const { news_id } = useParams();
    const dispatch = useDispatch();

    const { pepperNewsViewData } = useSelector((state) => ({
        pepperNewsViewData: state.pepperNews.view.data,
    }));

    const convertHtml = (data) => {
        data = data.replace(/&lt;/g, "<");
        data = data.replace(/&gt;/g, ">");
        data = data.replace(/&quot;/g, '"');
        data = data.replace(/&nbsp;/g, " ");
        data = data.replace(/\n/g, "<br />");
        return data;
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'm1_b7' ) {
                TrackGoogleAnalyticsEvent('maintab1_banner7_AI페퍼스구단뉴스_click','maintab1_banner7_AI페퍼스구단뉴스_click','maintab1_banner7_AI페퍼스구단뉴스_click');
            } 
        } 
        
    }, []);

    // 첫 진입 Dispatch
    useEffect(() => {
        dispatch(pepperNewsGetView({ id: news_id }));
    }, []);

    if (!pepperNewsViewData) return false;

    const { content, regDt, thumb, title } = pepperNewsViewData;

    return (
        <div className="news_cont_wrap">
            <div className="news_ttl">
                <strong>{convertHtml(title)}</strong>
                <p>{setDateFormat(regDt, "short")}</p>
            </div>
            <div className="news_cont">
                <img
                    src={`${FULL_URL}/file/${thumb}`}
                    alt={convertHtml(title)}
                />
                <br />
                <br />
                <div
                    className="editor"
                    dangerouslySetInnerHTML={{ __html: convertHtml(content) }}
                />
            </div>
        </div>
    );
};

export default PepperNewsView;
