import React from "react";
import { FULL_URL } from "../../constant/contant";
import { setDateFormat } from "../../libs/js/common";
import classNames from "classnames";

const VideoItem = ({ post, isPlayList, onClick, aspectCode }) => {
    const {
        id,
        contentCd,
        title,
        thumb,
        total,
        regDt,
        videoTypeCd,
        link,
        contentTypeCd,
        siNm
    } = post;

    return (
        <li
            className={classNames(
                "live-post",
                contentTypeCd === "ICON_ORIGINAL" ? "origin" : "youtube",
            )}
            onClick={() =>
                onClick(id, contentCd, videoTypeCd, link, contentTypeCd)
            }
        >
            {thumb && (
                <div className="thumb">
                    <img src={`${FULL_URL}/file/${thumb}`} alt={title} />
                    {!isPlayList && <strong className="total">{total}</strong>}
                </div>
            )}

            <p className="title">{title}</p>
            {isPlayList && (
                <div className="live-info">
                    <span>{siNm}</span>
                    <span>{setDateFormat(regDt, "short")}</span>
                </div>
            )}
        </li>
    );
};

export default VideoItem;
