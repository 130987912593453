export const reducers = {
    privacyGetList: (state, { payload: { name } }) => {
        state.list.name = name;
        state.list.data = null;
    },
    privacyGetListSuccess: (state, { payload }) => {
        state.list.data = payload;
    },
    privacyGetHtml: (state, { payload: { id,name } }) => {
        state.html.id = id;
        state.html.name = name;
        state.html.data = null;
    },
    privacyGetHtmlSuccess: (state, { payload }) => {
        state.html.data = payload;
    },
};
