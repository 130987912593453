import React, { useState } from "react";
import { BtnOutline02 } from "../../components/common/common";
import { Swiper, SwiperSlide } from "swiper/react";

const PlayerTabs = ({ type, handleTabClick }) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const tabType = type === "" ? "all" : type;
    const tabList = [
        { name: "all", text: "All" },
        { name: "OH", text: "Outside Hitter" },
        { name: "OP", text: "Opposite" },
        { name: "MB", text: "Middle Blocker" },
        { name: "S", text: "Setter" },
        { name: "L", text: "Libero" },
    ];

    const handleSwiperUpdate = (swiper) => {
        if (!isUpdate) {
            swiper.updateSlides();
            setIsUpdate(true);
        }
    };

    return (
        <div className="tab-swiper">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                slideToClickedSlide={true}
                onClick={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onTouchMove={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
            >
                {tabList.map(({ name, text }) => {
                    return (
                        <SwiperSlide key={name}>
                            <BtnOutline02
                                text={text}
                                name={name}
                                active={tabType === name}
                                onClick={() => handleTabClick(name)}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default PlayerTabs;
