import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Membership = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button1_맴버십혜택_click','AI_peppers_button1_맴버십혜택_click','AI_peppers_button1_맴버십혜택_click');
            }
        } else {
            TrackGoogleAnalyticsEvent('maintab1_banner4_멤버십혜택_click','maintab1_banner4_멤버십혜택_click','maintab1_banner4_멤버십혜택_click');
        }
    }, []);

    return (
        <div className="membership-wrap">
            <div className="membership-each">
                <h3>멤버십 등급별 혜택</h3>
                <div className="grade-adv-list">
                    <ul>
                        <li className="white">
                            <div className="cont">
                                <strong>WHITE PEPPERS</strong>
                                <dl>
                                    <dt>조건</dt>
                                    <dd>신규 가입 고객</dd>
                                </dl>
                            </div>
                        </li>
                        <li className="green">
                            <div className="cont">
                                <strong>GREEN PEPPERS</strong>
                                <dl>
                                    <dt>조건</dt>
                                    <dd>
                                        한달 간 앱 기본 활동으로 <b>2,000P</b>
                                        달성 고객
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>혜택</dt>
                                    <dd className="red">
                                        럭키드로우 1회 포인트 미차감
                                    </dd>
                                </dl>
                            </div>
                        </li>
                        <li className="black">
                            <div className="cont">
                                <strong>BLACK PEPPERS</strong>
                                <dl>
                                    <dt>조건</dt>
                                    <dd>
                                        한달 간 앱 기본 활동으로 <b>3,500P</b>
                                        달성 고객
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>혜택</dt>
                                    <dd className="red">
                                        럭키드로우 1회, 승부예측 1회 포인트
                                        미차감
                                    </dd>
                                </dl>
                            </div>
                        </li>
                        <li className="red">
                            <div className="cont">
                                <strong>RED PEPPERS</strong>
                                <dl>
                                    <dt>조건</dt>
                                    <dd>
                                        한달 간 앱 기본 활동으로 <b>6,000P</b>
                                        달성 고객
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>혜택</dt>
                                    <dd className="red">
                                        럭키드로우 2회, 승부예측 2회 포인트
                                        미차감
                                    </dd>
                                </dl>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="membership-each">
                <h3>앱 활동 지급 포인트</h3>
                <strong className="subttl">앱 기본 활동</strong>
                <div className="point-adv-list">
                    <ul>
                        <li className="icon-attend">
                            <div className="cont">
                                <strong>출석체크</strong>
                                <p>50P 지급</p>
                                <span>1일 1회 한정 지급</span>
                            </div>
                        </li>
                        <li className="icon-fan">
                            <div className="cont">
                                <strong>팬/마이포스트 작성</strong>
                                <p>50P 지급</p>
                                <span>각각 1주일 2회 한정 지급</span>
                            </div>
                        </li>
                        <li className="icon-showpepper">
                            <div className="cont">
                                <strong>쇼페퍼 업로드</strong>
                                <p>100P 지급</p>
                                <span>1주일 2회 한정 지급</span>
                            </div>
                        </li>
                        <li className="icon-reply">
                            <div className="cont">
                                <strong>댓글</strong>
                                <p>10P 지급</p>
                                <span>1일 5회 한정 지급</span>
                            </div>
                        </li>
                        <li className="icon-fav">
                            <div className="cont">
                                <strong>좋아요</strong>
                                <p>10P 지급</p>
                                <span>1일 5회 한정 지급</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <strong className="subttl">이벤트 활동</strong>
                <div className="point-adv-list">
                    <ul>
                        <li className="icon-1st">
                            <div className="cont">
                                <strong>게임 상위 랭킹 1등</strong>
                                <p>3,000P 지급</p>
                                <span>1개월 1회 지급</span>
                            </div>
                        </li>
                        <li className="icon-2nd">
                            <div className="cont">
                                <strong>게임 상위 랭킹 2등</strong>
                                <p>2,000P 지급</p>
                                <span>1개월 1회 지급</span>
                            </div>
                        </li>
                        <li className="icon-3rd">
                            <div className="cont">
                                <strong>게임 상위 랭킹 3등</strong>
                                <p>1,000P 지급</p>
                                <span>1개월 1회 지급</span>
                            </div>
                        </li>
                        <li className="icon-gift">
                            <div className="cont">
                                <strong>미스터리 박스</strong>
                                <span>
                                    미스터리 박스 포인트 상품 당첨 시 해당
                                    포인트 추가 지급 <br />
                                    (앱 활동 지급 포인트에 포함되지 않음)
                                </span>
                            </div>
                        </li>
                        <li className="icon-join">
                            <div className="cont">
                                <strong>회원가입 이벤트</strong>
                                <p>2,000P 지급</p>
                                <span>회원가입 시 1회 지급</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="membership-each">
                <h3>사용자 소비 포인트</h3>
                <div className="point-adv-list">
                    <ul>
                        <li className="icon-luckydraw">
                            <div className="cont">
                                <strong>럭키드로우</strong>
                                <p>1,000P 차감</p>
                                <span>1주일 1회 진행</span>
                            </div>
                        </li>
                        <li className="icon-predict">
                            <div className="cont">
                                <strong>승부예측</strong>
                                <p>500P 차감</p>
                                <span>모든 홈/어웨이 경기 진행</span>
                            </div>
                        </li>
                        <li className="icon-ticket">
                            <div className="cont">
                                <strong>티켓 할인권</strong>
                                <p>3,000P 차감</p>
                                <span>
                                    모든 홈 경기 진행
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="membership-warn">
                <strong>꼭 확인해 주세요!</strong>
                <ul>
                    <li>
                        멤버십 등급 변경은 매월 획득 포인트 합산 후 갱신됩니다.
                    </li>
                    <li>
                        이벤트 활동은 등급 산정에 제외되며 앱 기본 활동으로
                        획득한 포인트로만 등급 산정이 가능합니다.
                    </li>
                    <li>시즌이 종료 될 경우 포인트는 소멸됩니다.</li>
                </ul>
            </div>
        </div>
    );
};

export default Membership;
