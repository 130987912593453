import React, { useEffect, useRef, useState } from "react";
import { FULL_URL } from "../../constant/contant";
import classNames from "classnames";
import Hls from "hls.js";

const SlideItem = ({ posts, post, id, index, currentIdx }) => {
    const [loadImages, setLoadImages] = useState([]);
    const [isVideoLoad, setIsVideoLoad] = useState(false);
    const { bg_type, bg_url } = post;
    const [isVid, setIsVid] = useState(false);

    const [refVisible, setRefVisible] = useState(false);
    const [hls, setHls] = useState(new Hls());
    const videoEl = useRef(null);

    const handleLoad = () => {
        setIsVideoLoad(true);
    };

    // popup 열었을경우 비디오 재생
    const handleVisibilityChange = () => {
        if (videoEl.current) {
            if (document.visible || document.hidden) {
                videoEl.current.play();
            }
        }
    };

    // 해당 화면을 보고있는지 체크하는 로직
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () =>
            document.addEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, []);

    // 안드로이드에서 비디오 재생
    useEffect(() => {
        if (!refVisible) return;

        if (videoEl.current) {
            if (Hls.isSupported()) {
                hls.loadSource(bg_url);
                hls.attachMedia(videoEl.current);
                hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                    hls.play();
                });
            } else if (
                videoEl.current.canPlayType("application/vnd.apple.mpegurl")
            ) {
                videoEl.current.src = bg_url;
                videoEl.current.play();
            }
        }
    }, [refVisible]);

    // 비디오 이미지 3개씩만 로드
    useEffect(() => {
        if (posts && posts.length !== loadImages.length) {
            setLoadImages((loadImages) => {
                const prevIdx =
                    currentIdx - 1 < 0 ? posts.length - 1 : currentIdx - 1;
                const nextIdx =
                    posts.length === currentIdx + 1 ? 0 : currentIdx + 1;

                const allIdx = [prevIdx, currentIdx, nextIdx];
                const noneLoadIdx = allIdx.filter(
                    (item) => loadImages.indexOf(item) === -1,
                );

                if (noneLoadIdx.length > 0) {
                    return [...loadImages, ...noneLoadIdx];
                }
                return loadImages;
            });
        }
    }, [currentIdx]);

    useEffect(() => {
        if (bg_type === "V") {
            setIsVid(true);
        }
    });

    return (
        <>
            {
                loadImages.indexOf(index) > -1 &&
                    (isVid ? (
                        <video
                            ref={(el) => {
                                videoEl.current = el;
                                setRefVisible(!!el);
                            }}
                            muted={true}
                            autoPlay={true}
                            playsInline={true}
                            loop
                            className={classNames(
                                "video-img",
                                isVideoLoad && "active",
                            )}
                            onPlaying={handleLoad}
                        />
                    ) : (
                        <img src={`${FULL_URL}/file/${bg_url}`} alt="" />
                    ))
                // <video
                //     ref={(el) => {
                //         videoEl.current = el;
                //         setRefVisible(!!el);
                //     }}
                //     muted={true}
                //     autoPlay={true}
                //     playsInline={true}
                //     loop
                //     className={classNames("video-img", isVideoLoad && "active")}
                //     onPlaying={handleLoad}
                // />
            }
        </>
    );
};

export default SlideItem;
