import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "login",
    initialState: {
        login: null,
        logout: null,
        loginCheck: null,
    },
    reducers: {
        login: (state) => {
            state.login = null;
        },
        logout: (state) => {
            state.logout = null;
        },
        loginCheck: (state) => {
            state.loginCheck = null;
        },
        loginSuccess: (state, { payload }) => {
            state.login = payload;
            state.logout = null;
        },
        logoutSuccess: (state, { payload }) => {
            state.login = null;
            state.logout = payload;
        },
        loginCheckSuccess: (state, { payload }) => {
            state.loginCheck = payload.data;
        },
    },
});

export const {
    login,
    loginSuccess,
    logout,
    logoutSuccess,
    loginCheck,
    loginCheckSuccess,
} = authSlice.actions;
