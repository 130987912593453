import React from "react";
import { BtnOutline02 } from "../../components/common/common";

const FaqTabs = ({ faq, handleTabClick }) => {
    const tabList = [
        { name: "FAQ01", text: "회원" },
        { name: "FAQ02", text: "이벤트" },
        { name: "FAQ03", text: "포인트" },
        { name: "FAQ04", text: "티켓" },
    ];

    return (
        <div className="tab-scroll">
            {tabList.map(({ name, text }) => (
                <BtnOutline02
                    key={name}
                    text={text}
                    name={name}
                    active={faq === name}
                    onClick={handleTabClick}
                />
            ))}
        </div>
    );
};

export default FaqTabs;
