
export const reducers = {
    aboutGetList: (state, { payload: { typeCd } }) => {
        state.list.loading = true;
        state.list.error = null;
    },
    aboutGetListSuccess: (state, { payload }) => {
        state.list.loading = false;
        state.list.data = payload;
    },
    aboutGetListFailure: (state, {payload}) =>{
        state.list.loading = false;
        state.list.error = payload;
    },
    aboutGetMenuList: (state, { payload: { typeCd } }) => {
        
        state.menuList.type = typeCd;
        state.menuList.data = [];
    },
    aboutGetMenuListSuccess: (state, { payload }) => {
        if(!state.list.data){
            state.list.data = [];
        }

        console.log('겟', payload)
        state.menuList.data = payload;
    },
};
