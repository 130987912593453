import React, { useEffect } from "react";
import NewsItem from "./newsItem";
import { useDispatch } from "react-redux";
import { newsTab } from "./module";
import { useParams } from "react-router";

const NewsCon = () => {
    const dispatch = useDispatch();
    const { newsName } = useParams();

    useEffect(() => {
        dispatch(
            newsTab({
                tab: newsName,
            }),
        );
    });

    return <NewsItem newsName={newsName} />;
};

export default NewsCon;
