import React from "react";
import { useLocation } from "react-router-dom";
import { handleAppBridge } from "../../libs/js/common";

const DormantAccount = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let lDay = searchParams.get("lDay");
    let hDay = searchParams.get("hDay");

    if(lDay === null || lDay === "") {
        lDay = "-";
    }
    
    if(hDay === null || hDay === "") {
        hDay = "-";
    }

    const handleClick = () => {
        handleAppBridge({
            fnName: "goDormancy",
        });
    }

    return (
        <div className="dormant_account_wrap">
            <p className="greeting">
                안녕하세요!
                <br />
                회원님은 AI PEPPERS 계정에
                <strong> 1년 이상 로그인하지 않아</strong> 관련 법령에 따라 휴면
                상태로 전환되었습니다.
            </p>
            <div className="chg_dates">
                <dl>
                    <dt>마지막 접속일</dt>
                    <dd>{lDay}</dd>
                </dl>
                <dl>
                    <dt>휴면전환일</dt>
                    <dd>{hDay}</dd>
                </dl>
            </div>
            <ul className="warn">
                <li>
                    AI PEPPERS 앱 서비스를 계속 이용하시려면 [휴면 해제하기]
                    버튼을 클릭해 주세요.
                </li>
                <li>
                    휴면 계정 해제를 위해서 인증 및 비밀번호 재설정 과정이
                    필요합니다.
                </li>
            </ul>
            <button type="button" onClick={handleClick}>휴면 해제하기</button>
        </div>
    );
};

export default DormantAccount;
