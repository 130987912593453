import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./staff.reducer";

const initialState = {
    list: {
        loading:true,
        data: null,
    },
    menuList: {
        typeCd: "",
        data: null,
    },
};

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers,
});

export const { staffGetMenuList, staffGetMenuListSuccess, staffGetList, staffGetListSuccess } = staffSlice.actions;
