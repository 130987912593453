import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BtnDefault } from "../common/common";
import { modalClose } from "./module/layerModal";
import classNames from "classnames";

const LayerModal = ({
    modalName,
    modalBody,
    text,
    content,
    onClick,
    disabled,
    bottomButton,
    closeClass,
    closeClick,
    type,
}) => {
    const dispatch = useDispatch();

    const isModalOpen = useSelector((state) => state.layerModal.isModalOpen);
    const handleClick = () => {
        onClick && onClick();
        modalClose(dispatch);
    };

    const handleClose = () => {
        modalClose(dispatch);
    };

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? "hidden" : "unset";
    }, [isModalOpen]);

    return (
        <>
            {isModalOpen && (
                <div className={classNames("modal-wrap")}>
                    <div
                        className={classNames(
                            "modal",
                            modalName && `${modalName}-modal`,
                        )}
                    >
                        <div className={classNames("modal-body", modalBody)}>
                            <div className="modal-content">{content}</div>

                            {type === "B" ? (
                                <div className="button-wrap center">
                                    <BtnDefault
                                        text={text ? text : "확인"}
                                        size="medium"
                                        onClick={handleClick}
                                        disabled={disabled}
                                    />
                                </div>
                            ) : (
                                <div className="button-wrap center">
                                    <BtnDefault
                                        text={text ? text : "확인"}
                                        size="medium"
                                        onClick={handleClick}
                                        disabled={disabled}
                                    />
                                </div>
                            )}

                            <button
                                className={classNames(
                                    "btn-modal-close",
                                    closeClass && closeClass,
                                )}
                                onClick={
                                    closeClick
                                        ? () => closeClick()
                                        : handleClose
                                }
                            >
                                닫기
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LayerModal;
