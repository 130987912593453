import React, { useState } from "react";
import { BtnOutline02 } from "../../components/common/common";
import { Swiper, SwiperSlide } from "swiper/react";

const SnsTabs = ({ activeTab, onTabClick }) => {
    const [isUpdate, setIsUpdate] = useState(false);

    const tabList = [
        {
            type: "all",
            text: "전체",
        },
        {
            type: "SN01",
            text: "페퍼포토",
        },
        {
            type: "SN02",
            text: "인스타그램",
        },
        {
            type: "SN03",
            text: "네이버 포스트",
        },
        {
            type: "SN04",
            text: "네이버 TV",
        },
    ];

    const handleSwiperUpdate = (swiper) => {
        if (!isUpdate) {
            swiper.updateSlides();
            setIsUpdate(true);
        }
    };

    return (
        <div className="tab-swiper">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                slideToClickedSlide={true}
                onClick={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onTouchMove={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
            >
                {tabList.map(({ type, text }) => {
                    return (
                        <SwiperSlide key={type}>
                            <BtnOutline02
                                name={type}
                                text={text}
                                active={activeTab === type}
                                onClick={() => onTabClick(type)}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default SnsTabs;
