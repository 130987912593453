import { createSlice } from "@reduxjs/toolkit";
import { ONGOING } from "../../../constant/contant";
import { reducers } from "./luckyDraw.reducer";

export const luckyDrawSlice = createSlice({
    name: "luckyDraw",
    initialState: {
        list: {
            step: ONGOING,
            count: 0,
            data: null,
        },
        view: {
            id: null,
            data: null,
            applyCheckCode: 0,
        },
        apply: {
            code: 0,
            data: null,
        },
        winner: {
            code: null,
            data: null,
        },
    },
    reducers,
});

export const {
    luckyDrawGetList,
    luckyDrawGetView,
    luckyDrawGetApply,
    luckyDrawGetApplyCheck,
    luckyDrawWinnerYn,
    luckyDrawGetListSuccess,
    luckyDrawGetViewSuccess,
    luckyDrawGetApplySuccess,
    luckyDrawGetApplyCheckSuccess,
    luckyDrawWinnerYnSuccess,
} = luckyDrawSlice.actions;
