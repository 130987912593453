import React from "react";
import { TOKEN } from "../../constant/contant";
import Policy from "./policy";
import Policy2 from "./policy2";
import useLoginCheck from "../../hooks/useLoginCheck";

const PolicyControl = () => {
    const isLogin = useLoginCheck();
    if (isLogin) {
        return <Policy />;
    } else {
        return <Policy2 />;
    }
    // return <Policy />;
};

export default PolicyControl;
