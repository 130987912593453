import { createSlice } from "@reduxjs/toolkit";

export const broadcastSlice = createSlice({
    name: "broadcast", // slice name
    initialState: {
        info: {
            data: null,
        },
    },
    reducers: {
        broadcastInfo: (state) => {
            state.info.data = null;
        },
        broadcastInfoSuccess: (state, { payload: { count, data } }) => {
            state.info.data = data;
        },
    },
});

export const { broadcastInfo, broadcastInfoSuccess } = broadcastSlice.actions;
