import { layerModalClose, layerModalOpen } from "./layerModal.slice";

export const modalOpen = (dispatch) => {
    dispatch && dispatch(layerModalOpen());
};

export const modalClose = (dispatch) => {
    dispatch && dispatch(layerModalClose());
    document.body.style.overflow = "unset";
};
