import React, { useState, useEffect, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BtnOutline02 } from "components/common/common";
import { useDispatch, useSelector } from "react-redux";
import { staffGetMenuList } from "./module/staff.slice";
import { getTabList } from "./module/staff.selectors";

const StaffTabs = React.memo(({ handleTabClick }) => {
    const dispatch = useDispatch();
    const [type,setType] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const tabType = type === "" ? "ALL" : type.toUpperCase();
    const swiperRef = useRef(null);
    const tabList = useSelector(getTabList);

    useEffect(() => {
        dispatch(
            staffGetMenuList({
                typeCd: "COACH00",
            })
        );
    }, [dispatch]);

    const handleSwiperUpdate = useCallback((swiper) => {
        if (!isUpdate) {
            swiper.updateSlides();
            setIsUpdate(true);
        }
    }, [isUpdate]);

    const handleTabClickMost = useCallback((event, val) => {
        setType(val);
        handleTabClick(val);

        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            const target = event.currentTarget.parentElement;
            const boxHarf = swiperRef.current.getBoundingClientRect().width / 2
            let leftSibling = target.previousElementSibling;
            let targetLeft = 0;

            while (leftSibling) {
                targetLeft += leftSibling.getBoundingClientRect().width;
                targetLeft += parseFloat(leftSibling.style.marginLeft) || 0;
                targetLeft += parseFloat(leftSibling.style.marginRight) || 0;

                leftSibling = leftSibling.previousElementSibling;
            }

            setTimeout(() => {
                swiper.translateTo(-((targetLeft - boxHarf + target.getBoundingClientRect().width / 2)), 500)
            }, 100)

        }
    }, [handleTabClick]);

    if (!tabList) return null;

    return (
        <div className="tab-swiper">
            <Swiper
                ref={swiperRef}
                slidesPerView={"auto"}
                spaceBetween={10}
                onClick={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
                onTouchMove={(swiper) => {
                    handleSwiperUpdate(swiper);
                }}
            >
                {tabList.length > 0 && (
                    <SwiperSlide data-tab-index={0}>
                        <BtnOutline02
                            text="All"
                            name="ALL"
                            active={tabType === "ALL"}
                            type="button"
                            onClick={(event) => handleTabClickMost(event, "ALL")}
                        />
                    </SwiperSlide>
                )}
                {tabList.map(({ cdId, cdNm }, index) => (
                    <SwiperSlide key={cdNm} data-tab-index={index + 1}>
                        <BtnOutline02
                            text={cdNm}
                            name={cdId}
                            active={tabType === cdId}
                            type="button"
                            onClick={(event) => handleTabClickMost(event, cdId)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
});

export default StaffTabs;
