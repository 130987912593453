import React from "react";
import { useNavigate } from "react-router";
import { scrollObj, setDateFormat } from "../../libs/js/common";

const NoticeListItem = ({ data }) => {
    const navigate = useNavigate();
    const { id, content_nm, title, reg_dt } = data;

    const handleLink = () => {
        const { search } = window.location;
        navigate(`/noticeView/${id}${search}`, scrollObj);
    };

    return (
        <li className="notice" onClick={handleLink}>
            <span className="label">[{content_nm}]</span>
            <strong className="title">{title}</strong>
            {/*<span className="date">{setDateFormat(reg_dt, "short")}</span>*/}
            <span className="date">{reg_dt}</span>
        </li>
    );
};

export default NoticeListItem;
