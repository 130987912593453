import React from "react";
import PepperLive from "./pepperLive";
import { useParams } from "react-router-dom";

const PepperLiveCon = () => {
    const { type } = useParams();

    return <PepperLive activeType={type} />;
};

export default PepperLiveCon;
