import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./errorTest.reducer";
const name = "errorTest";

const initialState = {
    errorTest: null,
};

export const errorTestSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const { errorTest } = errorTestSlice.actions;
