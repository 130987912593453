import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
    getLocalStorage,
    handleAppBridge,
    removeLocalStorage,
} from "../../libs/js/common";
import { setInfinite } from "./module";
import { useLocation } from "react-router";

const Infinite = ({
    listClass,
    count,
    data,
    activeTab,
    isAllShow,
    onItemComponent,
    onNoneResultComponent,
    onGetList,
    selectType,
}) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
    const [posts, setPosts] = useState(null); // 모든 포스트들
    const [postPage, setPostPage] = useState(1); // 현재 페이지
    const [isLoading, setIsLoading] = useState(true);
    const [isLoad, setIsLoad] = useState(false);
    const [isScroll, setIsScroll] = useState(false); // 직접 스크롤 했는지 체크
    const [isScrollRestore, setIsisScrollRestore] = useState(true); // true 일 때만 스크롤 위치 복원 가능

    const { savePage, savePosts } = useSelector((state) => ({
        savePage: state.infinite.page,
        savePosts: state.infinite.posts,
    }));

    // 리스트 API 호출
    const getPosts = (type = "", page = 1, selectType) => {
        let payload = {};
        if (!selectType) {
            payload = {
                type: type === "all" ? "" : type,
                page,
            };
        } else {
            payload = {
                type: type === "all" ? "" : type,
                content_cd: selectType,
                page,
            };
        }

        // 페이징 처리 없을 경우
        if (isAllShow) {
            delete payload.page;
        }

        dispatch(onGetList(payload));
    };

    // Infinite Scroll
    const handleInfinite = () => {
        if (postPage < totalPage) {
            setIsScroll(true);
            setPostPage((postPage) => postPage + 1);
        }
    };

    // 스크롤 시 page 변경되었을 경우
    useEffect(() => {
        if (isScroll && postPage > 1) {
            const type = activeTab ?? "";

            getPosts(type, postPage, selectType);
            setIsScroll(false);
        }
    }, [postPage]);

    // 탭 클릭 시
    useEffect(() => {
        if (isLoad && activeTab) {
            setPosts(null);
            setPostPage(1);
            setTotalPage(0);

            getPosts(activeTab, 1, selectType);
        }
    }, [activeTab, selectType]);

    // 총 페이지 수
    useEffect(() => {
        if (count) {
            const total = count / 10;
            setTotalPage(Math.ceil(total));
        }
    }, [count]);

    // Data 셋팅
    useEffect(() => {
        if (!data) {
            setIsLoading(false);
        }

        if (data && !isLoading) {
            // API 통신 후 받아온 data가 존재할 경우
            if (data.length > 0) {
                handleAppBridge({
                    fnName: "startLoading",
                });

                setTimeout(() => {
                    handleAppBridge({
                        fnName: "stopLoading",
                    });

                    setPosts((posts) =>
                        postPage === 1 ? data : [...posts, ...data],
                    );
                }, 500);
            } else {
                postPage === 1 && setPosts([]);
            }
        }
    }, [data]);

    // 현재 페이지 최초 진입 시
    useEffect(() => {
        if (savePosts && posts !== null) {
            setPostPage(savePage);
            setPosts(savePosts);
        } else {
            getPosts(activeTab, 1, selectType);
            removeLocalStorage("scrollY");
        }

        setIsLoad(true);
    }, [pathname]);

    // Posts 저장
    useEffect(() => {
        dispatch(
            setInfinite({
                page: postPage,
                posts,
            }),
        );
    }, [posts]);

    // 스크롤 위치 복원
    useEffect(() => {
        const scrollY = Number(getLocalStorage("scrollY"));

        if (scrollY && isScrollRestore) {
            if (savePosts && savePosts === posts) {
                handleAppBridge({
                    fnName: "startLoading",
                });

                setTimeout(() => {
                    window.scrollTo(0, scrollY);
                    removeLocalStorage("scrollY");
                    setIsisScrollRestore(false);

                    handleAppBridge({
                        fnName: "stopLoading",
                    });
                }, 500);
            }
        }
    }, [posts, savePosts]);

    if (!posts) return false;

    const getNoneResultComponent = () => {
        if (onNoneResultComponent) {
            return onNoneResultComponent();
        }
    };

    return (
        <>
            {Array.isArray(posts) && posts.length ? (
                <InfiniteScroll
                    loader={false}
                    hasMore={true}
                    dataLength={posts.length}
                    next={!isAllShow && handleInfinite}
                    children={
                        <ul className={classNames(listClass && listClass)}>
                            {posts.map((post) => onItemComponent(post))}
                        </ul>
                    }
                />
            ) : (
                getNoneResultComponent()
            )}
        </>
    );
};

export default Infinite;
