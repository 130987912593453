import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login, logout } from "../../components/header/module";
import { IS_AUTH } from "../../constant/contant";
import { BtnDefault } from "../../components/common/common";

import image from "libs/images/event/bg_character.svg";

const Login = () => {
    const dispatch = useDispatch();
    const [isLogin, setLogin] = useState(IS_AUTH);

    const handleClick = () => {
        if (isLogin) {
            // 로그아웃
            dispatch(logout());
        } else {
            // 로그인
            dispatch(login());
        }

        setLogin((isLogin) => !isLogin);
    };

    return (
        <div className="login">
            <div className="inner">
                <div className="image-wrap">
                    <img
                        src={image}
                        alt="로그인"
                        style={{
                            display: "block",
                            width: "200px",
                            margin: "50px auto",
                        }}
                    />
                </div>
                <BtnDefault
                    text={isLogin ? "로그아웃" : "로그인"}
                    size="block"
                    onClick={handleClick}
                    second={isLogin}
                />
            </div>
        </div>
    );
};

export default Login;
