import React, { useEffect, useState } from "react";
import topBanner from "../../libs/images/matchPrediction/match_top_bn.png";
import title01 from "../../libs/images/matchPrediction/title01.svg";
import title02 from "../../libs/images/matchPrediction/title02.svg";

import MatchInfoBn from "./matchInfoBn";
import {
    APPLE_EVENT_MESSAGE,
    CLOSE,
    FULL_URL,
    IOS,
    TOKEN,
} from "../../constant/contant";
import { matchPredictionJSON } from "./API/matchPredictionJSON";
import { useDispatch, useSelector } from "react-redux";
import { matchVictory } from "./module";
import { BtnDefault, BtnOutline } from "../../components/common/common";
import { handleAppBridge, scrollError, scrollObj } from "../../libs/js/common";
import { Link, useLocation } from "react-router-dom";
import LayerModal from "../../components/layerModal/layerModal";

import useLoginCheck from "../../hooks/useLoginCheck";
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const MatchPrediction = ({ matchInfoRes, match_id }) => {
    const location = useLocation();
    const pageType = location.state?.pageType;
    const navigate = useNavigate();
    const isLogin = useLoginCheck();

    const { matchVictory: resVictory, loginCheck } = useSelector((state) => ({
        matchVictory: state.matchPrediction.matchVictory,
        loginCheck: state.auth.loginCheck,
    }));

    const dispatch = useDispatch();

    const { search } = window.location;
    const goEvt = () => {
        window.location.replace(`/application${search}`);
    };

    const handleApply = () => {
        if (isLogin) {
            navigate(`/application/${search}`);
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button1_이벤트_승부예측_click','event_button1_이벤트_승부예측_click','event_button1_이벤트_승부예측_click');
            } else if( gb === 'm1_e' ) {
                TrackGoogleAnalyticsEvent('maintab1_event_승부예측이벤트_click','maintab1_event_승부예측이벤트_click','maintab1_event_승부예측이벤트_click');
            } else if( gb === 'm3_b' ) {
                TrackGoogleAnalyticsEvent('maintab3_banner_승부예측이벤트_click','maintab3_banner_승부예측이벤트_click','maintab3_banner_승부예측이벤트_click');
            }
        } else {
            TrackGoogleAnalyticsEvent('maintab1_top_승부예측_click','maintab1_top_승부예측_click','maintab1_top_승부예측_click');
        }        
    }, []);

    useEffect(() => {
        if (resVictory) {
            const { code, msg } = resVictory;
            if (code === "200") {
                alert(msg);
                handleAppBridge({
                    fnName: "goBack",
                });
            } else {
                alert(msg);
            }
        }
    }, [resVictory]);

    useEffect(() => {
        if (pageType === "view") {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    const { HomeTeam, AwayTeam } = matchInfoRes;

    const home_team_logo = `${FULL_URL}/file/${HomeTeam.team_pic_url_sub}`;
    const away_team_logo = `${FULL_URL}/file/${AwayTeam.team_pic_url_sub}`;
    const home_team_active_logo = `${FULL_URL}/file/${HomeTeam.team_pic_url}`;
    const away_team_active_logo = `${FULL_URL}/file/${AwayTeam.team_pic_url}`;

    const [useVal, setVal] = useState(matchPredictionJSON);

    const winValueChked = (event, listKey) => {
        const { winner } = useVal.setData[listKey - 1];
        if (winner === "") alert("승/패를 선택해주세요.");
    };

    const { inputConfirm } = useVal;

    const winTeamChk = (event, listKey) => {
        if (loginCheck.result === "N") {
            handleAppBridge({
                fnName: "goLogin",
            });
            return false;
        }

        const $this = event.target;
        const $thisClass = $this.classList[0];
        const { loseScore, winScore } = useVal.setData[listKey - 1];
        const target = document.querySelectorAll(`.set${listKey}`);

        target.forEach((el) => {
            el.value = "";
        });

        if (loseScore > 0 || winScore > 0) {
            const setData = useVal.setData.map((arr) =>
                arr.set === listKey
                    ? {
                          ...arr,
                          step: "step02",
                          winner: $thisClass,
                          winScore: "",
                          loseScore: "",
                      }
                    : arr,
            );

            setVal({
                ...useVal,
                setData,
            });
        } else {
            const setData = useVal.setData.map((arr) =>
                arr.set === listKey
                    ? { ...arr, step: "step02", winner: $thisClass }
                    : arr,
            );
            setVal({
                ...useVal,
                setData,
            });
        }
    };

    // 승리팀 스코어 부터 입력
    const notWinScore = () => {
        alert("승리팀의 점수부터 입력하세요");
    };

    // 승리팀 스코어 업데이트
    const winScoreUpdate = (event, listKey) => {
        const $this = event.target;
        const $thisvalue = Number($this.value);
        const $thisvalueLen = $thisvalue.toString().length;

        const { dfMaxScore } = useVal.setData[listKey - 1];

        if ($thisvalueLen > 1) {
            if ($thisvalue >= dfMaxScore && $thisvalue <= 99) {
                const setData = useVal.setData.map((arr) =>
                    arr.set === listKey
                        ? { ...arr, step: "step02-1", winScore: $thisvalue }
                        : arr,
                );
                setVal({ ...useVal, setData });
            } else if ($thisvalue > 99) {
                const msg = "승리팀 입력 가능한 점수는 99점 이하 입니다.";
                scoreFix($this, setVal, useVal, listKey, msg);
            } else {
                const msg = `승리팀 점수가 ${dfMaxScore}점 이상이어야 합니다.`;
                scoreFix($this, setVal, useVal, listKey, msg);
            }
        } else {
            const setData = useVal.setData.map((arr) =>
                arr.set === listKey
                    ? { ...arr, step: "step02", winScore: null }
                    : arr,
            );
            setVal({ ...useVal, setData });
        }
    };

    // 패배팀 스코어 입력
    const loseScoreInput = (event, listKey) => {
        const $this = event.target;
        const $thisValStr = $this.value;
        const $thisValue = Number($thisValStr);
        const $thisValueLen = $thisValue.toString().length;
        const { winScore, dfMaxScore, dfminScore } =
            useVal.setData[listKey - 1];
        if (winScore === dfMaxScore) {
            if ($thisValue <= dfminScore) {
                if ($thisValStr.length >= 1) {
                    const setData = useVal.setData.map((arr) =>
                        arr.set === listKey
                            ? { ...arr, loseScore: $thisValue }
                            : arr,
                    );
                    setVal({ ...useVal, setData });
                } else {
                    const setData = useVal.setData.map((arr) =>
                        arr.set === listKey ? { ...arr, loseScore: null } : arr,
                    );
                    setVal({ ...useVal, setData });
                }
            } else {
                const msg = `승리팀의 점수가 ${dfMaxScore}점 일시, 상대팀의 점수는 ${dfminScore}점 이하여야 합니다.`;
                scoreAlert($this, setVal, useVal, listKey, msg);
            }
        } else if (winScore > dfMaxScore) {
            if ($thisValue === winScore - 2) {
                const setData = useVal.setData.map((arr) =>
                    arr.set === listKey
                        ? {
                              ...arr,
                              loseScore: $thisValue,
                          }
                        : arr,
                );
                setVal({ ...useVal, setData });
            } else {
                if ($thisValueLen >= winScore.toString().length) {
                    const msg =
                        "듀스 상황에서는 승리팀의 점수와 2점 차가 나야합니다.";
                    scoreAlert($this, setVal, useVal, listKey, msg);
                }
            }
        } else {
            const setData = useVal.setData.map((arr) =>
                arr.set === listKey
                    ? {
                          ...arr,
                          loseScore: $thisValue,
                      }
                    : arr,
            );
            setVal({ ...useVal, setData });
        }
    };

    const resetScore = (event, listKey) => {
        const $this = event.target;
        valReset($this, setVal, useVal, listKey);
    };

    const loseResetScore = (event, listKey) => {
        const $this = event.target;
        loseReset($this, setVal, useVal, listKey);
    };

    const saveSet = (event, listKey) => {
        const { winScore, loseScore, dfMaxScore, set, winner } =
            useVal.setData[listKey - 1];
        const leftWinCount = document.querySelectorAll(".match-li.leftWin");
        const rightWinCount = document.querySelectorAll(".match-li.rightWin");

        if (winScore.toString().length <= 1) {
            const msg = "승리팀의 점수는 25점 이상 이여야 합니다.";
            const target = document.querySelector(`.set${set}.${winner}`);

            alert(msg);

            setTimeout(() => {
                target.focus();
            }, 200);
        }

        if (winScore > dfMaxScore && winScore - 2 !== loseScore) {
            const msg = "듀스 상황에서는 승리팀의 점수와 2점 차가 나야합니다.";
            const lose = winner === "leftWin" ? "rightLose" : "leftLose";

            const target = document.querySelector(`.set${set}.${lose}`);

            alert(msg);

            setTimeout(() => {
                target.focus();
            }, 200);

            return false;
        }

        if (loseScore === null) {
            const msg = "점수를 입력해주세요.";
            const lose = winner === "leftWin" ? "rightLose" : "leftLose";

            const target = document.querySelector(`.set${set}.${lose}`);

            alert(msg);

            setTimeout(() => {
                target.focus();
            }, 200);

            return false;
        }

        if (winScore >= 0 && loseScore >= 0) {
            if (leftWinCount.length <= 2 && rightWinCount.length <= 2) {
                const setData = useVal.setData.map((arr) =>
                    arr.set === listKey
                        ? {
                              ...arr,
                              step: "step03",
                          }
                        : arr.set === listKey - 1
                        ? {
                              ...arr,
                          }
                        : arr.set === listKey + 1
                        ? {
                              ...arr,
                              step: "step01",
                          }
                        : arr,
                );
                setVal({
                    ...useVal,
                    totalScore: {
                        left: leftWinCount.length,
                        right: rightWinCount.length,
                    },
                    setData,
                });
            } else if (
                leftWinCount.length === 3 ||
                rightWinCount.length === 3
            ) {
                const setData = useVal.setData.map((arr) =>
                    arr.set === listKey
                        ? {
                              ...arr,
                              step: "step03",
                          }
                        : arr.set === listKey + 1
                        ? {
                              ...arr,
                              step: "step00",
                          }
                        : arr,
                );
                setVal({
                    ...useVal,
                    inputConfirm: true,
                    totalScore: {
                        left: leftWinCount.length,
                        right: rightWinCount.length,
                    },
                    setData,
                });
            }
        } else {
            alert("점수 입력을 완료해 주세요.");
        }
    };

    const matchVictoryBtn = () => {
        const winScoreArr = useVal.setData.map((arr) => {
            if (arr.winner === "leftWin") {
                return [arr.winScore, arr.loseScore];
            } else {
                return [arr.loseScore, arr.winScore];
            }
        });

        const homeArr = winScoreArr.map((arr) => {
            if (arr[0] === null) {
                return Number(0);
            }
            return arr[0];
        });

        const awayArr = winScoreArr.map((arr) => {
            if (arr[1] === null) {
                return Number(0);
            }
            return arr[1];
        });

        const homeScore = useVal.totalScore.left;
        const awayScore = useVal.totalScore.right;
        const our = `${homeArr[0]},${homeArr[1]},${homeArr[2]},${homeArr[3]},${homeArr[4]}`;
        const opp = `${awayArr[0]},${awayArr[1]},${awayArr[2]},${awayArr[3]},${awayArr[4]}`;

        const param = {
            matchId: match_id,
            homeScore,
            awayScore,
            our,
            opp,
        };

        const confirmChked = window.confirm(
            "승부예측 참여를 완료 하시겠습니까?\n등급에 따라 포인트가 차감될 수 있습니다.",
        );

        if (confirmChked) {
            dispatch(matchVictory(param));
        }
    };

    const resetInput = () => {
        const confirmChk = window.confirm(
            "모든 정보가 초기화됩니다.\n초기화하시겠습니까?",
        );
        if (confirmChk) {
            const $target = document.querySelectorAll(".match-li input");
            $target.forEach((el) => {
                el.value = "";
            });
            setVal(matchPredictionJSON);
        }
    };
    const GiftModal = () => {
        return (
            <div className="next-seezn">
                <div className="content"></div>
            </div>
        );
    };

    const handleGoBack = () => {
        document.body.style.overflow = "unset";
        //navigator(-1);
        handleAppBridge({
            fnName: "goBack",
        });
    };

    // 다음시즌 팝업
    // useEffect(()=>{
    //     modalOpen(dispatch);
    // })

    return (
        <div className="match-p-wrap">
            <LayerModal
                modalName="nextSeezn"
                content={<GiftModal />}
                bottomButton={false}
                closeClick={handleGoBack}
                closeClass={"white-close"}
            />

            <div className="match-p-head">
                <img src={topBanner} alt="" />
            </div>

            <div className="match-p-container">
                <div className="match-info">
                    <div className="match-tt">
                        <h2>
                            <img src={title01} alt="경기일정" />
                        </h2>
                    </div>
                    <div className="match-game-info">
                        <MatchInfoBn matchInfoRes={matchInfoRes} />
                    </div>
                </div>

                <div className="match-input-content">
                    <div className="match-tt">
                        <h2>
                            <img src={title02} alt="승부예측" />
                            <p className="match-stt">
                                금일 경기의 승패를 예측해 주세요!
                            </p>
                        </h2>
                    </div>

                    <div className="match-input-box">
                        <div className="match-choose-team">
                            {useVal.totalScore.left >= 3 ||
                            useVal.totalScore.right >= 3 ? (
                                <div className="home-team team active">
                                    <img src={home_team_active_logo} alt="" />
                                </div>
                            ) : (
                                <div className="home-team team filter-img">
                                    <img src={home_team_logo} alt="" />
                                </div>
                            )}
                        </div>
                        <div className="match-team-score">
                            {useVal.totalScore.left >= 3 ||
                            useVal.totalScore.right >= 3 ? (
                                <>
                                    <span
                                        className={`total-winner ${
                                            useVal.totalScore.left >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {useVal.totalScore.left >= 3
                                            ? "승"
                                            : "패"}
                                    </span>
                                    <span
                                        className={`total-score home-score ${
                                            useVal.totalScore.left >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {useVal.totalScore.left}
                                    </span>
                                </>
                            ) : (
                                <span className="total-score home-score">
                                    {useVal.totalScore.left}
                                </span>
                            )}

                            {useVal.totalScore.left >= 3 ||
                            useVal.totalScore.right >= 3 ? (
                                <span className="dot active">:</span>
                            ) : (
                                <span className="dot">:</span>
                            )}

                            {useVal.totalScore.left >= 3 ||
                            useVal.totalScore.right >= 3 ? (
                                <>
                                    <span
                                        className={`total-score away-score ${
                                            useVal.totalScore.right >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {useVal.totalScore.right}
                                    </span>
                                    <span
                                        className={`total-winner ${
                                            useVal.totalScore.right >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {useVal.totalScore.right >= 3
                                            ? "승"
                                            : "패"}
                                    </span>
                                </>
                            ) : (
                                <span className="total-score away-score">
                                    {useVal.totalScore.right}
                                </span>
                            )}
                        </div>
                        <div className="match-choose-team">
                            {useVal.totalScore.left >= 3 ||
                            useVal.totalScore.right >= 3 ? (
                                <div className="away-team team active">
                                    <img src={away_team_active_logo} alt="" />
                                </div>
                            ) : (
                                <div className="away-team team filter-img">
                                    <img src={away_team_logo} alt="" />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="match-set-input">
                        <ul>
                            {setInputMarkup(
                                useVal,
                                winTeamChk,
                                winValueChked,
                                notWinScore,
                                winScoreUpdate,
                                loseScoreInput,
                                resetScore,
                                loseResetScore,
                                saveSet,
                            )}
                        </ul>
                    </div>
                    <div className="match-reset-box">
                        <button
                            type="button"
                            className="match-reset-btn"
                            onClick={() => resetInput()}
                        >
                            초기화
                        </button>
                    </div>
                    <div className="match-tip-box">
                        <span className="match-tip">TIP</span>
                        <span className="match-tip-txt">
                            세트별 승리팀/최종 스코어를 입력하세요!
                        </span>
                    </div>

                    <div className="match-submit-btn">
                        {inputConfirm ? (
                            <BtnDefault
                                text="승부예측 제출하기 (-500P)"
                                size="block"
                                className="btn-gradient-red"
                                onClick={() => matchVictoryBtn()}
                            />
                        ) : (
                            <BtnDefault
                                text="승부예측 제출하기 (-500P)"
                                size="block"
                                disabled={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="match-p-guide">
                <h2 className="title">승부예측 유의사항</h2>
                <ul className="text-list-dot">
                    <li>본 이벤트는 앱 전용 이벤트입니다.</li>
                    <li>
                        승부예측 참여는 해당 회차가 열린 이후부터 회차 경기시작
                        직전까지 참여가 가능합니다.
                    </li>
                    <li>
                        한 회차당 당첨인원 전체에게 최대 지급 가능한 상금은
                        1,000만원이며, 10명을 초과한 당첨자가 발생한 회차의
                        경우에는 총 1,000만원의 상금을 해당 당첨 인원 수만큼
                        나눠서 균등 지급합니다.
                    </li>
                    <li>
                        승부예측 당첨자에 한하여 개별 메시지 발송 등의 연락이
                        진행되며, 미당첨자에게는 연락이 가지 않습니다.
                    </li>
                    <li>
                        승부예측 당첨 메시지 발송 이후 7일 이내에 회신이 없을
                        경우, 당첨이 자동 취소 됩니다.
                    </li>
                    <li>
                        5만원 초과 경품의 제세공과금(22%)은 당첨자 본인
                        부담입니다.
                    </li>
                    <li>
                        제세공과금 납부와 관련한 세부사항은 소득세법, 지방세법
                        및 기타 관련 법령의 내용에 따라 처리합니다.
                    </li>
                    <li>
                        휴대폰 번호가 확인이 되지 않을 경우, 연락 불가로 인해
                        당첨이 취소될 수 있습니다.
                    </li>
                    <li>
                        부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며,
                        법에 따른 처벌 대상이 될 수 있습니다.
                    </li>
                    <li>
                        해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 내용
                        변경 또는 조기 종료 될 수 있습니다.
                    </li>
                    {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                </ul>
                <div className="button-wrap-single chk-last-matches">
                    {/*<BtnDefault*/}
                    {/*    text={"지난 이벤트 참여 확인"}*/}
                    {/*    size="large"*/}
                    {/*    onClick={handleApply}*/}
                    {/*/>*/}
                    <button
                        className="chk-last-matches btn btn-default block"
                        // to={`/application${search}`}
                        onClick={handleApply}
                    >
                        지난 이벤트 참여 확인
                    </button>
                </div>
            </div>
        </div>
    );
};

const setInputMarkup = (
    useVal,
    winTeamChk,
    winValueChked,
    notWinScore,
    winScoreUpdate,
    loseScoreInput,
    resetScore,
    loseResetScore,
    saveSet,
) => {
    return useVal.setData.map((arr, key) => {
        const { set, winner, step, winScore, dfMaxScore, loseScore } = arr;

        switch (step) {
            case "step00":
                return (
                    <li key={key} className="match-li" data-idx={key}>
                        <div className="home-set match-set">
                            <button
                                type="button"
                                className="leftWin"
                                disabled={true}
                            >
                                선택
                            </button>
                            <input
                                type="number"
                                name={`set${set}`}
                                className={`leftWin set${set}`}
                                maxLength="2"
                                disabled={true}
                                placeholder={"0"}
                            />
                        </div>
                        <div className="away-set match-set">
                            <input
                                type="number"
                                name={`set${set}`}
                                className={`rightWin set${set}`}
                                maxLength="2"
                                disabled={true}
                                placeholder={"0"}
                            />
                            <button
                                type="button"
                                className="rightWin"
                                disabled={true}
                            >
                                선택
                            </button>
                        </div>
                        <div className="set-info">
                            <p>{set}SET</p>
                        </div>
                    </li>
                );
            case "step01":
                return (
                    <li key={key} className="match-li" data-idx={key}>
                        <div className="home-set match-set">
                            <button
                                type="button"
                                className="leftWin"
                                onClick={(event) => winTeamChk(event, set)}
                            >
                                선택
                            </button>
                            <input
                                type="number"
                                name={`set${set}`}
                                className={`leftWin set${set}`}
                                onClick={(event) => winValueChked(event, set)}
                                readOnly={true}
                                placeholder={"0"}
                                maxLength="2"
                            />
                        </div>
                        <div className="away-set match-set">
                            <input
                                type="number"
                                name={`set${set}`}
                                className={`rightWin set${set}`}
                                onClick={(event) => winValueChked(event, set)}
                                readOnly={true}
                                placeholder={"0"}
                                maxLength="2"
                            />
                            <button
                                type="button"
                                className="rightWin"
                                onClick={(event) => winTeamChk(event, set)}
                            >
                                선택
                            </button>
                        </div>
                        <div className="set-info">
                            <p>{set}SET</p>
                        </div>
                    </li>
                );
            case "step02":
                return (
                    <li
                        key={key}
                        data-idx={key}
                        className={`match-li ${winner}`}
                    >
                        <div className="home-set match-set">
                            {winner === "leftWin" ? (
                                <>
                                    <button type="button" className="leftWin">
                                        승리
                                    </button>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        onChange={(event) =>
                                            winScoreUpdate(event, set)
                                        }
                                        className={`leftWin set${set}`}
                                    />
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="leftWin"
                                        onClick={(event) =>
                                            winTeamChk(event, set)
                                        }
                                    >
                                        패배
                                    </button>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={winScore < dfMaxScore}
                                        onClick={
                                            winScore >= dfMaxScore
                                                ? null
                                                : (event) => notWinScore(event)
                                        }
                                        className={`leftLose set${set}`}
                                    />
                                </>
                            )}
                        </div>
                        <div className="away-set match-set">
                            {winner === "rightWin" ? (
                                <>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        onChange={
                                            winScore <= 0
                                                ? (event) =>
                                                      winScoreUpdate(event, set)
                                                : null
                                        }
                                        className={`rightWin set${set}`}
                                    />
                                    <button type="button" className="rightWin">
                                        승리
                                    </button>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={winScore < dfMaxScore}
                                        onClick={
                                            winScore >= dfMaxScore
                                                ? null
                                                : (event) => notWinScore(event)
                                        }
                                        className={`rightLose set${set}`}
                                    />
                                    <button
                                        type="button"
                                        className="rightWin"
                                        onClick={(event) =>
                                            winTeamChk(event, set)
                                        }
                                    >
                                        패배
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="set-info">
                            <p>{set}SET</p>
                        </div>
                    </li>
                );
            case "step02-1":
                return (
                    <li
                        key={key}
                        data-idx={key}
                        className={`match-li ${winner}`}
                    >
                        <div className="home-set match-set">
                            <button
                                type="button"
                                className="leftWin"
                                onClick={(event) => winTeamChk(event, set)}
                            >
                                선택
                            </button>
                            {winner === "leftWin" ? (
                                <input
                                    type="number"
                                    name={`set${set}`}
                                    onFocus={(event) => resetScore(event, set)}
                                    onChange={(event) =>
                                        winScoreUpdate(event, set)
                                    }
                                    className={`leftWin set${set}`}
                                />
                            ) : (
                                <input
                                    type="number"
                                    name={`set${set}`}
                                    readOnly={winScore < dfMaxScore}
                                    onChange={(event) =>
                                        loseScoreInput(event, set)
                                    }
                                    onFocus={
                                        winScore > 0
                                            ? (event) =>
                                                  loseResetScore(event, set)
                                            : null
                                    }
                                    onClick={
                                        winScore >= dfMaxScore
                                            ? null
                                            : (event) => notWinScore(event)
                                    }
                                    className={`leftLose set${set}`}
                                />
                            )}
                        </div>
                        <div className="away-set match-set">
                            {winner === "rightWin" ? (
                                <input
                                    type="number"
                                    name={`set${set}`}
                                    onFocus={(event) => resetScore(event, set)}
                                    onChange={(event) =>
                                        winScoreUpdate(event, set)
                                    }
                                    className={`rightWin set${set}`}
                                />
                            ) : (
                                <input
                                    type="number"
                                    name={`set${set}`}
                                    readOnly={winScore < dfMaxScore}
                                    onChange={(event) =>
                                        loseScoreInput(event, set)
                                    }
                                    onFocus={
                                        winScore > 0
                                            ? (event) =>
                                                  loseResetScore(event, set)
                                            : null
                                    }
                                    onClick={
                                        winScore >= dfMaxScore
                                            ? null
                                            : (event) => notWinScore(event)
                                    }
                                    className={`rightLose set${set}`}
                                />
                            )}
                            <button
                                type="button"
                                className="rightWin"
                                onClick={(event) => winTeamChk(event, set)}
                            >
                                선택
                            </button>
                        </div>
                        <div
                            className="set-info save"
                            onClick={(event) => saveSet(event, set)}
                        >
                            <p>저장</p>
                        </div>
                    </li>
                );
            case "step03":
                return (
                    <li
                        key={key}
                        data-idx={key}
                        className={`match-li ${winner}`}
                    >
                        <div className="home-set match-set">
                            {winner === "leftWin" ? (
                                <>
                                    <button type="button" className="leftWin">
                                        승리
                                    </button>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={true}
                                        className={`leftWin set${set} winner`}
                                    />
                                </>
                            ) : (
                                <>
                                    <button type="button" className="leftWin">
                                        패배
                                    </button>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={true}
                                        className={`leftLose set${set}`}
                                    />
                                </>
                            )}
                        </div>
                        <div className="away-set match-set">
                            {winner === "rightWin" ? (
                                <>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={true}
                                        className={`rightWin set${set} winner`}
                                    />
                                    <button type="button" className="rightWin">
                                        승리
                                    </button>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="number"
                                        name={`set${set}`}
                                        readOnly={true}
                                        className={`rightLose set${set}`}
                                    />
                                    <button type="button" className="rightWin">
                                        패배
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="set-info">
                            <p>{set}SET</p>
                        </div>
                    </li>
                );
            default:
                return;
        }
    });
};

/* 승리팀 스코어 25점 이하 입력이 되었을 경우 */
function scoreAlert($this, setVal, useVal, listKey, msg) {
    alert(msg);
    const setData = useVal.setData.map((arr) =>
        arr.set === listKey ? { ...arr, loseScore: null } : arr,
    );

    setVal({
        ...useVal,
        setData,
    });

    setTimeout(() => {
        $this.value = "";
        $this.focus();
    }, 500);
}

function loseReset($this, setVal, useVal, listKey) {
    const setData = useVal.setData.map((arr) =>
        arr.set === listKey ? { ...arr, loseScore: null } : arr,
    );

    setVal({
        ...useVal,
        setData,
    });
    $this.value = "";
}

function scoreFix($this, setVal, useVal, listKey, msg) {
    alert(msg);
    const setData = useVal.setData.map((arr) =>
        arr.set === listKey ? { ...arr, winScore: null } : arr,
    );

    setVal({
        ...useVal,
        setData,
    });

    setTimeout(() => {
        $this.value = "";
        $this.focus();
    }, 500);
}

function valReset(target, setVal, useVal, listKey, type, className) {
    const inputReset = document.querySelectorAll(`.set${listKey}`);
    inputReset.forEach((el) => {
        if (el.value > 0) {
            el.value = "";
        }
    });
    if (type === "all") {
        const setData = useVal.setData.map((arr) =>
            arr.set === listKey
                ? {
                      ...arr,
                      step: "step02",
                      winner: className,
                      winScore: null,
                      loseScore: null,
                  }
                : arr,
        );

        setVal({
            ...useVal,
            setData,
        });
    } else {
        const setData = useVal.setData.map((arr) =>
            arr.set === listKey
                ? {
                      ...arr,
                      winScore: null,
                      loseScore: null,
                  }
                : arr,
        );
        setVal({
            ...useVal,
            setData,
        });
    }
}

export default MatchPrediction;
