import React, { useEffect, useRef, useState } from "react";
import AboutTabs from "./aboutTabs";
import AboutItem from "./aboutItem";
import { useDispatch, useSelector } from "react-redux";
import { aboutGetList, aboutGetMenuList } from "./module/about.slice";

// const getWindowSize = () => {
//     const { innerWidth, innerHeight } = window;
//     return { innerWidth, innerHeight };
// };

const About = () => {
    const dispatch = useDispatch();
    const aboutWrapRef = useRef(null);
    const [activeTab,setActiveTab] = useState("MENU01");
    const [clicked, setClicked] = useState(false);
    const { data: tabList } = useSelector(state => state.about.menuList);

    console.log('탭리',tabList)

    

    React.useEffect(() => {
        dispatch(
            aboutGetMenuList({
                typeCd: "MENU00",
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        if (tabList && tabList.length > 0) {
            dispatch(aboutGetList(tabList));
        }
    }, [dispatch, tabList]);

    function handleActiveTab(id){
        setActiveTab(id)
    }

    return (
        <div className="pepper-about" ref={aboutWrapRef}>
            <AboutTabs tabList={tabList} activeTab={activeTab} handleActiveTab={handleActiveTab} clicked={clicked} setClicked={setClicked} />
            <AboutItem aboutWrapRef={aboutWrapRef} handleActiveTab={handleActiveTab} clicked={clicked} />
            <div className="drag-down">
                <span />
                <span />
                <span />
            </div>
        </div>
    );
};

export default About;
