import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 리스트
export const luckyDrawListAPI = async (params) => {
    try {
        return await request({
            method: "post",
            url: "category/event/luckydrawList",
            data: params,
        });
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 상세
export const luckyDrawViewAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/luckydrawDetail",
            data: params,
        });

        return response.data;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 응모하기
export const luckyDrawApplyAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/luckydrawApply",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 응모 여부 조회
export const luckyDrawApplyCheckAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/checkLuckydrawApply",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 당첨자 조회
export const luckyDrawWinnerAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/showWinner",
            data: params,
        });

        const { code, msg, data } = response;

        if (code === "E107") {
            alert(msg);
            return false;
        }

        if (code === "200") {
            const winnerList = data
                .map((item, index) => {
                    if (data.length - 1 !== index) {
                        return item.apply_email + "\n";
                    } else {
                        return item.apply_email;
                    }
                })
                .join("");

            alert("당첨자\n" + winnerList);
        }
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 당첨자 여부 확인
export const luckydrawWinYnAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/event/showWinnerYn",
            data: params,
        });

        const { code, msg, data } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
            return false;
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
