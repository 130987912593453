import React, { useEffect, useState } from "react";
import topBanner from "../../libs/images/matchPrediction/match_top_bn.png";
import title03 from "../../libs/images/matchPrediction/title03.svg";
import title04 from "../../libs/images/matchPrediction/title04.svg";
import { APPLE_EVENT_MESSAGE, FULL_URL, IOS } from "../../constant/contant";
import {
    handleAppBridge,
    scrollError,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoginCheck from "../../hooks/useLoginCheck";
import { BtnDefault } from "../../components/common/common";

const MatchPredictionView = ({ getMatchViewRes, applyCheck }) => {
    const { HomeTeam, AwayTeam, MatchVictory, MatchSchedule } = getMatchViewRes;
    const { match_date, status } = MatchSchedule;

    const navigate = useNavigate();
    const location = useLocation();
    const pageType = location.state?.pageType;
    const isLogin = useLoginCheck();
    const matchDay = setDateFormat(match_date, "short2");

    const { search } = window.location;
    const goEvt = () => {
        window.location.replace(`/application${search}`);
    };
    const home_team_active_logo = `${FULL_URL}/file/${HomeTeam.team_pic_url}`;
    const away_team_active_logo = `${FULL_URL}/file/${AwayTeam.team_pic_url}`;

    const scheduleScoreChked =
        !!MatchSchedule.away_score || !!MatchSchedule.home_score;

    const handleApply = () => {
        if (isLogin) {
            navigate(`/application/${search}`);
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    const newArr = {
        isLoading: true,
        setData: [
            {
                set: 1,
                awayScore: 0,
                homeScore: 0,
            },
            {
                set: 2,
                awayScore: 0,
                homeScore: 0,
            },
            {
                set: 3,
                awayScore: 0,
                homeScore: 0,
            },
            {
                set: 4,
                awayScore: 0,
                homeScore: 0,
            },
            {
                set: 5,
                awayScore: 0,
                homeScore: 0,
            },
        ],
    };

    const [useMatchSchedule, setMatchSchedule] = useState(newArr);

    const myAwayScoreArr = MatchVictory.away_score_detail.split(",");
    const myHomeScoreArr = MatchVictory.home_score_detail.split(",");

    useEffect(() => {
        if (useMatchSchedule.isLoading) {
            if (status === "MS03" && scheduleScoreChked) {
                let awayScoreArr = MatchSchedule.away_score_detail.split(",");
                let homeScoreArr = MatchSchedule.home_score_detail.split(",");

                const newArrs = [];
                useMatchSchedule.setData.map((arr, idx) => {
                    if (arr.set === idx + 1) {
                        newArrs.push({
                            set: arr.set,
                            myScoreInput: {
                                awayScore: Number(myAwayScoreArr[idx]),
                                homeScore: Number(myHomeScoreArr[idx]),
                            },
                            endGameScore: {
                                awayScore: Number(awayScoreArr[idx]),
                                homeScore: Number(homeScoreArr[idx]),
                            },
                        });
                    }
                });

                setMatchSchedule({
                    isLoading: false,
                    setData: newArrs,
                });
            } else {
                const newArrs = [];
                useMatchSchedule.setData.map((arr, idx) => {
                    if (arr.set === idx + 1) {
                        newArrs.push({
                            set: arr.set,
                            myScoreInput: {
                                awayScore: Number(myAwayScoreArr[idx]),
                                homeScore: Number(myHomeScoreArr[idx]),
                            },
                            endGameScore: {
                                awayScore: 0,
                                homeScore: 0,
                            },
                        });
                    }
                });

                setMatchSchedule({
                    isLoading: false,
                    setData: newArrs,
                });
            }
        }
    }, []);

    useEffect(() => {
        if (pageType === "view") {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    const matchApply = () => {
        if (applyCheck.code === "200") {
            navigate(
                `/matchEventApply/${MatchSchedule.id}/${MatchVictory.id}${search}`,
                scrollObj,
            );
        } else {
            alert(applyCheck.msg);
        }
    };

    if (useMatchSchedule.isLoading) return false;

    return (
        <div className="match-p-wrap">
            <div className="match-p-head">
                <img src={topBanner} alt="" />
            </div>

            <div className="match-p-container">
                {status === "MS03" && scheduleScoreChked && (
                    <div className="match-input-content">
                        <div className="match-tt">
                            <h2>
                                <img src={title03} alt="경기결과" />
                            </h2>
                            <p className="match-stt">{matchDay}</p>
                        </div>

                        <div className="match-input-box">
                            <div className="match-choose-team">
                                <div className="home-team team active">
                                    <img src={home_team_active_logo} alt="" />
                                </div>
                            </div>
                            <div className="match-team-score">
                                {MatchSchedule.home_score >= 3 ||
                                MatchSchedule.away_score >= 3 ? (
                                    <>
                                        <span
                                            className={`total-winner left ${
                                                MatchSchedule.home_score >= 3
                                                    ? "win"
                                                    : "lose"
                                            }`}
                                        >
                                            {MatchSchedule.home_score >= 3
                                                ? "승"
                                                : "패"}
                                        </span>
                                        <span
                                            className={`total-score home-score ${
                                                MatchSchedule.home_score >= 3
                                                    ? "win"
                                                    : "lose"
                                            }`}
                                        >
                                            {MatchSchedule.home_score}
                                        </span>
                                    </>
                                ) : (
                                    <span className="total-score home-score">
                                        {MatchSchedule.home_score}
                                    </span>
                                )}

                                <span>:</span>

                                {MatchSchedule.home_score >= 3 ||
                                MatchSchedule.away_score >= 3 ? (
                                    <>
                                        <span
                                            className={`total-score away-score ${
                                                MatchSchedule.away_score >= 3
                                                    ? "win"
                                                    : "lose"
                                            }`}
                                        >
                                            {MatchSchedule.away_score}
                                        </span>
                                        <span
                                            className={`total-winner right ${
                                                MatchSchedule.away_score >= 3
                                                    ? "win"
                                                    : "lose"
                                            }`}
                                        >
                                            {MatchSchedule.away_score >= 3
                                                ? "승"
                                                : "패"}
                                        </span>
                                    </>
                                ) : (
                                    <span className="total-score away-score">
                                        {MatchSchedule.away_score}
                                    </span>
                                )}
                            </div>
                            <div className="match-choose-team">
                                <div className="away-team team active">
                                    <img src={away_team_active_logo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="match-set-input">
                            <ul>
                                {setInputList(useMatchSchedule.setData, "end")}
                            </ul>
                        </div>
                    </div>
                )}

                <div className="match-input-content">
                    <div className="match-tt">
                        <h2>
                            <img src={title04} alt="나의 승부예측" />
                        </h2>
                    </div>

                    <div className="match-input-box">
                        <div className="match-choose-team">
                            <div className="home-team team active">
                                <img src={home_team_active_logo} alt="" />
                            </div>
                        </div>
                        <div className="match-team-score">
                            {MatchVictory.home_score >= 3 ||
                            MatchVictory.away_score >= 3 ? (
                                <>
                                    <span
                                        className={`total-winner left ${
                                            MatchVictory.home_score >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {MatchVictory.home_score >= 3
                                            ? "승"
                                            : "패"}
                                    </span>
                                    <span
                                        className={`total-score home-score ${
                                            MatchVictory.home_score >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {MatchVictory.home_score}
                                    </span>
                                </>
                            ) : (
                                <span className="total-score home-score">
                                    {MatchVictory.home_score}
                                </span>
                            )}

                            <span className="dot active">:</span>

                            {MatchVictory.home_score >= 3 ||
                            MatchVictory.away_score >= 3 ? (
                                <>
                                    <span
                                        className={`total-score away-score ${
                                            MatchVictory.away_score >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {MatchVictory.away_score}
                                    </span>
                                    <span
                                        className={`total-winner right ${
                                            MatchVictory.away_score >= 3
                                                ? "win"
                                                : "lose"
                                        }`}
                                    >
                                        {MatchVictory.away_score >= 3
                                            ? "승"
                                            : "패"}
                                    </span>
                                </>
                            ) : (
                                <span className="total-score away-score">
                                    {MatchVictory.away_score}
                                </span>
                            )}
                        </div>
                        <div className="match-choose-team">
                            <div className="away-team team active">
                                <img src={away_team_active_logo} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="match-set-input">
                        <ul>{setInputList(useMatchSchedule.setData, "my")}</ul>
                    </div>

                    {status === "MS03" ? (
                        <div className="match-submit-btn">
                            {!scheduleScoreChked ? (
                                <button
                                    type="button"
                                    className="btn match-df-confirm"
                                >
                                    경기 결과 확인중 입니다.
                                </button>
                            ) : (
                                <>
                                    {MatchVictory.predict_yn === "N" ? (
                                        <button
                                            type="button"
                                            className="btn match-fail-btn"
                                        >
                                            예측에 실패하셨네요.
                                            <br />
                                            다음에 또 도전해 주세요.
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-gradient-red match-df-confirm btn-success match-btn-tt"
                                            onClick={() => matchApply()}
                                        >
                                            예측에 성공하셨습니다.
                                            <br />
                                            버튼을 눌러 정보를 입력해 주세요.
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    ) : status === "MS02" ? (
                        <div className="match-submit-btn">
                            <button
                                type="button"
                                className="btn match-fail-btn"
                            >
                                경기 진행중 입니다.
                            </button>
                        </div>
                    ) : (
                        <div className="match-submit-btn">
                            <button
                                type="button"
                                className="btn match-fail-btn"
                            >
                                경기 시작 전 입니다.
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="match-p-guide">
                <h2 className="title">승부예측 유의사항</h2>
                <ul className="text-list-dot">
                    <li>본 이벤트는 앱 전용 이벤트입니다.</li>
                    <li>
                        승부예측 참여는 해당 회차가 열린 이후부터 회차 경기시작
                        직전까지 참여가 가능합니다.
                    </li>
                    <li>
                        한 회차당 당첨인원 전체에게 최대 지급 가능한 상금은
                        1,000만원이며, 10명을 초과한 당첨자가 발생한 회차의
                        경우에는 총 1,000만원의 상금을 해당 당첨 인원 수만큼
                        나눠서 균등 지급합니다.
                    </li>
                    <li>
                        승부예측 당첨자에 한하여 개별 메시지 발송 등의 연락이
                        진행되며, 미당첨자에게는 연락이 가지 않습니다.
                    </li>
                    <li>
                        승부예측 당첨 메시지 발송 이후 7일 이내에 회신이 없을
                        경우, 당첨이 자동 취소 됩니다.
                    </li>
                    <li>
                        5만원 초과 경품의 제세공과금(22%)은 당첨자 본인
                        부담입니다.
                    </li>
                    <li>
                        제세공과금 납부와 관련한 세부사항은 소득세법, 지방세법
                        및 기타 관련 법령의 내용에 따라 처리합니다.
                    </li>
                    <li>
                        휴대폰 번호가 확인이 되지 않을 경우, 연락 불가로 인해
                        당첨이 취소될 수 있습니다.
                    </li>
                    <li>
                        부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며,
                        법에 따른 처벌 대상이 될 수 있습니다.
                    </li>
                    <li>
                        해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 내용
                        변경 또는 조기 종료 될 수 있습니다.
                    </li>
                    {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                </ul>
                <button
                    className="chk-last-matches btn btn-default block"
                    // to={`/application${search}`}
                    onClick={handleApply}
                >
                    지난 이벤트 참여 확인
                </button>
            </div>
        </div>
    );
};

const setInputList = (useMatchSchedule, type) => {
    if (type === "end") {
        return useMatchSchedule.map((arr, key) => {
            const { set, endGameScore } = arr;
            return (
                <li
                    className={`match-li ${
                        endGameScore.homeScore > endGameScore.awayScore
                            ? "leftWin"
                            : endGameScore.homeScore < endGameScore.awayScore
                            ? "rightWin"
                            : null
                    } `}
                    key={key}
                >
                    <div className="home-set match-set">
                        <button
                            type="button"
                            className="leftWin"
                            disabled={
                                endGameScore.awayScore === "0" &&
                                endGameScore.homeScore === "0" &&
                                true
                            }
                        >
                            {endGameScore.homeScore > endGameScore.awayScore
                                ? "승리"
                                : "패배"}
                        </button>
                        <input
                            type="number"
                            name={`set${set}`}
                            className={`leftWin set${set} ${
                                endGameScore.homeScore >
                                    endGameScore.awayScore && "winner"
                            }`}
                            readOnly={true}
                            disabled={
                                endGameScore.awayScore === "0" &&
                                endGameScore.homeScore === "0" &&
                                true
                            }
                            defaultValue={endGameScore.homeScore}
                            maxLength="2"
                        />
                    </div>
                    <div className="away-set match-set">
                        <input
                            type="number"
                            name={`set${set}`}
                            className={`rightWin set${set} ${
                                endGameScore.homeScore <
                                    endGameScore.awayScore && "winner"
                            }`}
                            defaultValue={endGameScore.awayScore}
                            readOnly={true}
                            disabled={
                                endGameScore.awayScore === "0" &&
                                endGameScore.homeScore === "0" &&
                                true
                            }
                            maxLength="2"
                        />
                        <button
                            type="button"
                            className="rightWin"
                            disabled={
                                endGameScore.awayScore === "0" &&
                                endGameScore.homeScore === "0" &&
                                true
                            }
                        >
                            {endGameScore.homeScore < endGameScore.awayScore
                                ? "승리"
                                : "패배"}
                        </button>
                    </div>
                    <div className="set-info">
                        <p>{set}SET</p>
                    </div>
                </li>
            );
        });
    } else {
        return useMatchSchedule.map((arr, key) => {
            const { set, myScoreInput } = arr;
            return (
                <li
                    className={`match-li ${
                        myScoreInput.homeScore > myScoreInput.awayScore
                            ? "leftWin"
                            : myScoreInput.homeScore < myScoreInput.awayScore
                            ? "rightWin"
                            : ""
                    } `}
                    key={key}
                >
                    <div className="home-set match-set">
                        <button
                            type="button"
                            className="leftWin"
                            disabled={
                                myScoreInput.awayScore === 0 &&
                                myScoreInput.homeScore === 0 &&
                                true
                            }
                        >
                            {myScoreInput.homeScore > myScoreInput.awayScore
                                ? "승리"
                                : "패배"}
                        </button>
                        <input
                            type="number"
                            name={`set${set}`}
                            className={`leftWin set${set} ${
                                myScoreInput.homeScore >
                                    myScoreInput.awayScore && "winner"
                            }`}
                            readOnly={true}
                            disabled={
                                myScoreInput.awayScore === 0 &&
                                myScoreInput.homeScore === 0 &&
                                true
                            }
                            defaultValue={myScoreInput.homeScore}
                            maxLength="2"
                        />
                    </div>
                    <div className="away-set match-set">
                        <input
                            type="number"
                            name={`set${set}`}
                            className={`rightWin set${set} ${
                                myScoreInput.homeScore <
                                    myScoreInput.awayScore && "winner"
                            }`}
                            defaultValue={myScoreInput.awayScore}
                            disabled={
                                myScoreInput.awayScore === 0 &&
                                myScoreInput.homeScore === 0 &&
                                true
                            }
                            readOnly={true}
                            maxLength="2"
                        />
                        <button
                            type="button"
                            className="rightWin"
                            disabled={
                                myScoreInput.awayScore === 0 &&
                                myScoreInput.homeScore === 0 &&
                                true
                            }
                        >
                            {myScoreInput.homeScore < myScoreInput.awayScore
                                ? "승리"
                                : "패배"}
                        </button>
                    </div>
                    <div className="set-info">
                        <p>{set}SET</p>
                    </div>
                </li>
            );
        });
    }
};

export default MatchPredictionView;
