import { put, takeEvery, call } from "redux-saga/effects";
import { faqGetList, faqGetListSuccess } from "./faq.slice";
import { faqListAPI } from "../API/faqAPI";

// Player List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(faqListAPI, params);
        yield put(faqGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* faqListSaga() {
    yield takeEvery(faqGetList, getList);
}
