import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {
        data: null,
    },
    check: {
        data: null,
    },
    apply: {
        code: 0,
        message: null,
    },
};

export const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        attendanceGetList: (state, { payload }) => {
            state.list.data = null;
        },
        attendanceCheck: (state) => {
            state.check.data = null;
        },
        attendanceApply: (state) => {
            state.apply.code = 0;
            state.apply.message = null;
        },
        attendanceGetSuccess: (state, { payload }) => {
            state.list.data = payload;
        },
        attendanceCheckSuccess: (state, { payload }) => {
            state.check.data = payload;
        },
        attendanceApplySuccess: (state, { payload: { code, msg } }) => {
            state.apply.code = code;
            state.apply.message = msg;
        },
    },
});

export const {
    attendanceGetList,
    attendanceGetSuccess,
    attendanceCheck,
    attendanceCheckSuccess,
    attendanceApply,
    attendanceApplySuccess,
} = attendanceSlice.actions;
