import React from "react";
import Apply from "../apply/apply";

const MatchEventApply = ({ match_id }) => {
    const noteTitle = "승부예측 유의사항";
    const noteList = [
        `본 이벤트는 앱 전용 이벤트입니다.`,
        `승부예측 참여는 해당 회차가 열린 이후부터 회차 경기시작 직전까지 참여가 가능합니다.`,
        `한 회차당 당첨인원 전체에게 최대 지급 가능한 상금은 1,000만원이며, 10명을 초과한 당첨자가 발생한 회차의 경우에는 총 1,000만원의 상금을 해당 당첨 인원 수만큼 나눠서 균등 지급합니다.`,
        `승부예측 당첨자에 한하여 개별 메시지 발송 등의 연락이 진행되며, 미당첨자에게는 연락이 가지 않습니다.`,
        `승부예측 당첨 메시지 발송 이후 48시간 이내에 회신이 없을 경우, 당첨이 자동 취소 됩니다.`,
        `5만원 초과 경품의 제세공과금(22%)은 당첨자 본인 부담입니다.`,
        `제세공과금 납부와 관련한 세부사항은 소득세법, 지방세법 및 기타 관련 법령의 내용에 따라 처리합니다.`,
        `휴대폰 번호가 확인이 되지 않을 경우, 연락 불가로 인해 당첨이 취소될 수 있습니다.`,
        `부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며, 법에 따른 처벌 대상이 될 수 있습니다.`,
        `해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 내용 변경 또는 조기 종료 될 수 있습니다.`,
    ];
    return <Apply noteTitle={noteTitle} noteList={noteList} />;
};

export default MatchEventApply;
