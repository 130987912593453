import React from "react";

const Policy2 = () => {
    return (
        <section className="page-modal">
            <article className="modal-body">
                <div className="content policy2-content">
                    <h2>
                        AI PEPPERS 배구단 앱 개인 정보 수집 및 이용 동의(필수)<br />
                    </h2>
                    <p className="pdt-40">페퍼저축은행 AI PEPPERS 배구단(이하”회사”라고 한다.) 애플리케이션 회원 가입을 위하여 아래의 개인정보 수집 및 이용에 대한 내용을 자세히 읽어보신 후 동의 여부를 결정하여 주시기 바랍니다.</p>
                    <ul>
                        <li className="pdt-40">
                            <h3>1. 개인정보의 처리 목적</h3>
                            <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며, 이용 목적이 변경되는 경우 『개인정보 보호법』 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                            <ol>
                                <li>
                                    <h4>① 회원가입 및 관리</h4>
                                    <p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별∙인증, 회원자격 유지∙관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정 이용방지, 비인가 사용방지, 가입의사확인</p>
                                </li>
                                <li>
                                    <h4>② 서비스 제공</h4>
                                    <p>회원제 서비스 제공, 신청형∙참여형 서비스 제공, 단체관람신청, 회원∙비회원 상담, 민원처리 및 처리결과 연락, 통보, 고지사항 전달</p>
                                </li>
                                <li>
                                    <h4>③ 마케팅 및 광고성 정보 제공</h4>
                                    <p>광고성 정보제공 및 이벤트 신청∙참여 기회 제공, 서비스 유효성 확인, 고객 만족도 조사를 통한 신규 서비스 개발, 맞춤서비스 제공, 이벤트 경품지급, 회원의 서비스 이용에 대한 통계 목적</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>2. 개인정보의 처리 및 보유∙이용기간</h3>
                            <p>회사는 회원가입, 서비스제공, 상담 등을 위해 최소한의 범위에서 다음과 같이 개인정보를 처리 및 보유∙이용합니다.</p>
                            <ol className="ol-content">
                                <li>
                                    <h4>① 회원가입 및 관리</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th>수집항목</th>
                                                <th>보유∙이용기간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>필수항목</td>
                                                <td>닉네임, 휴대폰번호, 비밀번호, 비밀번호확인, 이메일주소, 프로필사진</td>
                                                <td rowSpan={2}>회원 탈퇴 시까지 또는 동의 철회 시까지</td>
                                            </tr>
                                            <tr>
                                                <td>선택항목</td>
                                                <td>마케팅수신동의 (SMS, 앱푸시, 이메일</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <h4>② 서비스 제공</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th>수집항목</th>
                                                <th>보유∙이용기간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>필수항목</td>
                                                <td>닉네임, 이름, 휴대폰번호, 이메일주소, 주소</td>
                                                <td>이용목적 달성 시 까지</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <h4>③ 마케팅 및 광고성 정보 제공</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th>수집항목</th>
                                                <th>보유∙이용기간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>필수항목</td>
                                                <td>닉네임, 이름, 휴대폰번호, 이메일주소</td>
                                                <td>이벤트 종료 시까지</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <h4>④ 서비스를 이용 시 자동으로 생성된 정보</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th>수집항목</th>
                                                <th>보유∙이용기간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>필수항목</td>
                                                <td>IP주소, 쿠키, 방문일시, 서비스 이용기록,, 기기정보 (단말기종류, OS종류, Device ID, Google ID 등)</td>
                                                <td>회원 탈퇴 시까지</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ol>
                            <p className=" pdt-30">
                                ※ 회사는 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 학력, 병력 등 개인의 권리∙이익이나 사생활을 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.
                            </p>
                        </li>
                        <li>
                            <h3>3. 개인정보의 파기</h3>
                            <ol className="ol-content">
                                <li><h4>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</h4></li>
                                <li><h4>② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나, 처리목적 달성 등 개인정보가 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 일정기간 보존 합니다.</h4></li>
                            </ol>
                            <dl>
                                <dt>
                                    <h4>1. 전자상거래 등에서 소비자보호에 관한 법률 제6조에 따른 표시∙광고, 계약 내용 및 이행 등 거래에 관한 기록</h4>
                                </dt>
                                <dd>- 계약 또는 청약철회, 대금결제 및 재화 등의 공급에 관한 기록:  5년</dd>
                                <dd>- 소비자 불만 또는 분쟁처리에 관한 기록:  3년</dd>
                            </dl>
                            <dl>
                                <dt>
                                    <h4>2. 통신비밀보호법 제15조의2에 따른 통신사실확인자료 보관</h4>
                                </dt>
                                <dd>- 서비스이용기록, 접속로그, 접속IP정보:  3개월</dd>
                            </dl>
                        </li>
                    </ul>
                    <p className=" pdt-30">※ 개인정보 수집 및 이용(필수)에 대하여 동의를 거부할 권리가 있으며, 개인정보 수집 및 이용 동의에 대한 미동의 시 회원가입 및 서비스 이용이 제한됩니다.</p>
                    <p className=" pdt-10">※ 자세한 사항은 개인정보처리방침 전문에서 확인하시기 바랍니다.</p>
                    <p className=" pdt-40">{`<준법심의번호: 24-COM-0292>`}</p>
                </div>
                {/*<div className="button-wrap center">*/}
                {/*    <BtnDefault*/}
                {/*        text="확인"*/}
                {/*        size="block"*/}
                {/*        onClick={handleClick}*/}
                {/*    />*/}
                {/*</div>*/}
            </article>
        </section>
    );
};

export default Policy2;

