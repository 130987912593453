import React, { useState } from "react";
import { FULL_URL } from "../../constant/contant";
import { handleAppBridge, setDateFormat } from "../../libs/js/common";
import classNames from "classnames";

const PepperLiveItem = ({ post, onGetTabName }) => {
    const { title, created_at, type_cd, thumb, link, content_cd } = post;

    const date = setDateFormat(created_at, "short");

    const [isImageLoad, setIsImageLoad] = useState(false);

    const handleClick = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: link,
        });
    };

    const convertHtml = (data) => {
        if (data) {
            data = data.replace(/&amp;/g, "&");
            data = data.replace(/&#34;/g, '"');
            data = data.replace(/&lt;/g, "<");
            data = data.replace(/&gt;/g, ">");
            data = data.replace(/&#39;/g, "'");
            return data;
        }
    };

    const handleLoad = () => setIsImageLoad(true);
    console.log(content_cd);

    return (
        <li className="live-list">
            <div
                className={classNames(
                    "img-wrap",
                    isImageLoad && content_cd === "CT09"
                        ? "monopoly"
                        : isImageLoad && content_cd !== "CT09"
                        ? "complete"
                        : null,
                )}
                onClick={handleClick}
            >
                <img
                    src={`${FULL_URL}/file/${thumb}`}
                    alt={title}
                    onLoad={handleLoad}
                />
            </div>
            <div className="text-wrap">
                <p
                    className="title"
                    dangerouslySetInnerHTML={{ __html: convertHtml(title) }}
                />
                <p className="description">
                    {onGetTabName(type_cd)}
                    <span className="date">{date}</span>
                </p>
            </div>
        </li>
    );
};

export default PepperLiveItem;
