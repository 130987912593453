import React, { useEffect, useState } from "react";
import pointBn from "../../libs/images/myTicket/point_bn.png";
import iconPoint from "../../libs/images/myTicket/icon_point.svg";
import bgCoupon from "../../libs/images/myTicket/bg_coupon.png";
import modalImg from "../../libs/images/myTicket/modal_coin.png";
import { Link, useLocation } from "react-router-dom";
import { buyInterParkCode } from "./module";
import { handleAppBridge, scrollError } from "../../libs/js/common";

import { BtnDefault } from "../../components/common/common";

const TicketCouponBuy = ({ getMyPointArr, useCode, setRefresh, dispatch }) => {
    const { username, point, grade_nm, buyYn } = getMyPointArr;

    const { search } = window.location;
    const [usePoint, setPoint] = useState(point);
    const [useCodeBuy, setCodeBuy] = useState(false);
    const [buyAble, setBuyAble] = useState(false);

    const alertMsg = (msg) => {
        alert(msg);
    };
    

    const gradeChk = () => {
        // if (grade_nm === "RED PEPPERS") {
        //     alert(grade_nm + " 등급은 한달에 2번 구매만 가능합니다.");
        // } else if (grade_nm === "BLACK PEPPERS") {
        //     alert(grade_nm + " 등급은 한달에 1번 구매만 가능합니다.");
        // } else {
        //     alert("티켓 예매는 BLACK PEPPERS 등급 이상부터 구매가 가능합니다.");
        // }

        codeBuy();
    };

    const refreshFn = () => {
        window.location.reload();
        setCodeBuy(false);
        setRefresh(true);
    };

    const codeBuy = () => {
        const resConfirm = window.confirm("할인코드를 구매하시겠습니까?");
        if (resConfirm) {
            dispatch(buyInterParkCode());
            setCodeBuy(resConfirm);
        }
    };

    useEffect(() => {
        if (buyYn === "Y") {
            setBuyAble(true);
        }
    });

    useEffect(() => {
        if (useCodeBuy) {
            const cusPoint = Number(usePoint.replace(",", ""));
            const minusPoint = cusPoint - 3000;
            const commaPoint = minusPoint.toLocaleString();
            setPoint(commaPoint);
        }
    }, [useCodeBuy]);

    const pageType = useLocation().state?.pageType;

    useEffect(() => {
        if (pageType === "view") {
            setTimeout(() => {
                scrollError();
            }, 500);
        }
    }, [getMyPointArr]);

    const copyCodeNum = () => {
        const interParkCode = document.getElementById("interParkCode").value;
        handleAppBridge({
            fnName: "setCopy",
            param: [interParkCode, "할인코드가 복사되었습니다."],
        });
    };

    const ticketReservation = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: "https://kovo.co.kr/aipeppers/ticket/ticket-buy",
        });
    };

    return (
        <>
            <div className="point-wrap">
                <div className="point-head">
                    <div className="point-info">
                        <b className="nickName">{username}</b>
                        <span>님의 보유 포인트 :</span>
                        <span className="point">
                            &nbsp;{usePoint < 0 ? 0 : usePoint}&nbsp;
                        </span>
                        <img
                            src={iconPoint}
                            alt="point icon"
                            className="deco-point"
                        />
                    </div>
                </div>
                <div className="point-bn">
                    <img src={pointBn} alt="AI페퍼스에서 3000원 쏜다" />
                </div>
                <div className="point-container">
                    <div className="point-guide">
                        <div className="point-guide-top">
                            <p className="mtxt">
                                포인트로 티켓&nbsp;
                                <span>
                                    <i>할인코드</i>
                                </span>
                                를 구매해 보세요!
                            </p>
                            <p className="stxt">
                                구매 완료 후 등장하는 <b>할인코드</b>를 이용하여
                                <br />
                                <b>코보 티켓 예매처</b>에서 <b>티켓 할인</b>을
                                받을 수 있습니다.{" "}
                            </p>
                        </div>
                        <div className="point-guide-ex">
                            <p>예시)</p>
                            <div className="point-guide-code">
                                <p className="codeNum">6NJDIKFGJH343222</p>
                                <p className="copy">복사</p>
                            </div>
                        </div>
                        <div className="point-guide-txt">
                            <p>
                                *할인코드 구매 시 3,000포인트가 차감됩니다.
                                <br />
                                *할인코드는 1인 1매에만 적용 가능합니다.
                                <br />
                                *할인코드는 구매 후 6개월 이내에 사용
                                가능합니다.
                            </p>
                        </div>
                    </div>

                    <div className="point-coupon-box">
                        <img src={bgCoupon} alt="" />
                    </div>

                    <div className="point-bottom">
                        <button
                            type="button"
                            className={
                                usePoint.replace(/,/g, "") >= 3000
                                    ? "submit"
                                    : "disabled"
                            }
                            onClick={
                                usePoint.replace(/,/g, "") >= 3000
                                    ? () => codeBuy()
                                    : () =>
                                        alertMsg(
                                            "3,000P 이하는 구매하실 수 없습니다.",
                                        )
                            }
                        >
                            티켓 할인코드 구매하기 (-3,000P)
                        </button>
                        <Link
                            to={`/myTicketCoupon${search}`}
                            className="moreCode"
                        >
                            구매한 할인코드 보기
                        </Link>
                    </div>
                </div>
            </div>
            {useCodeBuy &&
                useCode.code &&
                modalCodeBuySucMarkup(
                    search,
                    refreshFn,
                    copyCodeNum,
                    useCode,
                    ticketReservation,
                )}
        </>
    );
};

const modalCodeBuySucMarkup = (
    search,
    refreshFn,
    copyCodeNum,
    useCode,
    ticketReservation,
) => {
    return (
        <div className="buy-code-wrap">
            <div className="buy-code-inr">
                <div className="buy-code-head">
                    <button
                        type="button"
                        className="buy-code-close"
                        onClick={() => refreshFn()}
                    />
                </div>
                <div className="buy-code-content">
                    <div className="buy-code-msg">
                        <div className="deco-img">
                            <img src={modalImg} alt="" />
                        </div>
                        <p className="mtxt">구매가 완료 되었습니다.</p>
                        <p className="stxt">
                            해당코드를 코보 티켓 예매처에서 사용해 주세요.
                            <br /> 할인코드는 구매 후 6개월 이내에 사용
                            가능합니다.
                        </p>
                    </div>
                    <div className="code-box">
                        <input
                            type="text"
                            defaultValue={useCode.code !== null && useCode.code}
                            readOnly={true}
                            className="codeNum"
                            name="interParkCode"
                            id="interParkCode"
                        />
                        <button
                            type="button"
                            className="copy-btn"
                            onClick={() => copyCodeNum()}
                        >
                            복사
                        </button>
                    </div>
                    <div className="code-link">
                        <BtnDefault
                            text={"티켓 예매하기"}
                            onClick={ticketReservation}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketCouponBuy;
