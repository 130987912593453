import { put, takeEvery, call } from "redux-saga/effects";
import {
    getEdiyaCpnCntAPI,
    getEdiyaCpnIssueAPI,
    getMyEdiyaCpnAPI,
    getMyEdiyaCpnCntAPI,
} from "../API/eventAPI";
import {
    ediyaCpnCnt,
    ediyaCpnCntSuccess,
    ediyaCpnIssue,
    ediyaCpnIssueSuccess,
    myEdiyaCpnCnt,
    myEdiyaCpnCntSuccess,
    myEdiyaCpn,
    myEdiyaCpnSuccess,
} from "./ediya.slice";

// 이디야쿠폰발급수
function* getEdiyaCpnCnt(action) {
    const { payload: params } = action;
    // console.log(" getEdiyaCpnCnt action ", action);
    try {
        const data = yield call(getEdiyaCpnCntAPI, params);
        yield put(ediyaCpnCntSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* ediyaCpnCntSaga() {
    yield takeEvery(ediyaCpnCnt, getEdiyaCpnCnt);
}

// 이디야쿠폰발급
function* getEdiyaCpnIssue(action) {
    const { payload: params } = action;
    try {
        const data = yield call(getEdiyaCpnIssueAPI, params);
        yield put(ediyaCpnIssueSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* ediyaCpnIssueSaga() {
    yield takeEvery(ediyaCpnIssue, getEdiyaCpnIssue);
}

// 이디야쿠폰발급여부 조회
function* getMyEdiyaCpnCnt(action) {
    const { payload: params } = action;

    try {
        const data = yield call(getMyEdiyaCpnCntAPI, params);
        yield put(myEdiyaCpnCntSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* myEdiyaCpnCntSaga() {
    yield takeEvery(myEdiyaCpnCnt, getMyEdiyaCpnCnt);
}

// 이디야쿠폰코드 조회
function* getMyEdiyaCpn(action) {
    const { payload: params } = action;

    try {
        const data = yield call(getMyEdiyaCpnAPI, params);
        yield put(myEdiyaCpnSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* myEdiyaCpnSaga() {
    yield takeEvery(myEdiyaCpn, getMyEdiyaCpn);
}
