import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }, [pathname]);
};

export default ScrollTop;
