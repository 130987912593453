import React, { useEffect } from "react";
import { FULL_URL, TOKEN } from "../../constant/contant";
import {
    handleAppBridge,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import { BtnDefault } from "../../components/common/common";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const MatchList = ({ resData: matchArr, nextMatch_id }) => {
    const { search } = window.location;

    const { loginCheck } = useSelector((state) => ({
        loginCheck: state.auth.loginCheck,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'm1_t' ) {
                TrackGoogleAnalyticsEvent('maintab1_top_경기일정_더보기_click','maintab1_top_경기일정_더보기_click','maintab1_top_경기일정_더보기_click');
            }
        } 
    }, []);


    const navigate = useNavigate();
    const newArr = matchArr.filter((arr) => {
        return arr.id !== nextMatch_id;
    });

    const markUp = newArr.map((arr, key) => {
        const {
            match_date,
            stadium,
            homeTeam,
            oppTeam,
            status,
            gubun,
            home_score,
            away_score,
            ticketing_url,
            isApply,
            seat_url,
        } = arr;

        const home_team_logo = `${FULL_URL}/file/${homeTeam.team_pic_url}`;
        const away_team_logo = `${FULL_URL}/file/${oppTeam.team_pic_url}`;

        const endGameScore = () => {
            const totalScore = Number(home_score) + Number(away_score);
            if (totalScore > 5 && status === "MS01") return false;
            return (
                <div className="match-score">
                    {home_score > away_score ? (
                        <span className="match-game-type win">승</span>
                    ) : (
                        <span className="match-game-type lose">패</span>
                    )}

                    <div className="score-box">
                        <span className="score">{home_score}</span>
                        <span className="score">:</span>
                        <span className="score">{away_score}</span>
                    </div>

                    {away_score > home_score ? (
                        <span className="match-game-type win">승</span>
                    ) : (
                        <span className="match-game-type lose">패</span>
                    )}
                </div>
            );
        };

        const appLogin = () => {
            handleAppBridge({
                fnName: "goLogin",
            });
        };
        const outLinkAppTicket = () => {
            handleAppBridge({
                fnName: "onOpenPage",
                param: ticketing_url,
            });
        };
        const alertMsg = (msg) => {
            alert(msg);
        };

        const outLinkAppPost = () => {
            handleAppBridge({
                fnName: "onOpenPage",
                param: seat_url,
            });
        };

        const loginGameType = () => {
            return (
                <BtnDefault
                    text={
                        status === "MS01" && ticketing_url !== null
                            ? "티켓 예매하기"
                            : status === "MS01" && ticketing_url === null
                            ? "티켓 오픈 예정"
                            : status === "MS02" && isApply === "1"
                            ? "승부예측 현황보기"
                            : status === "MS03" && isApply === "1"
                            ? "승부예측 결과보기"
                            : "승부예측 미참여"
                    }
                    size="block"
                    className={
                        status === "MS01" && ticketing_url !== null
                            ? "btn-gradient-red"
                            : (status === "MS03" || status === "MS02") &&
                              isApply === "1"
                            ? "btn-gradient-black"
                            : (status === "MS01" && !ticketing_url) ||
                              ((status === "MS03" || status === "MS02") &&
                                  isApply === "0")
                            ? "btn-disabled-gray"
                            : null
                    }
                    onClick={
                        status === "MS01" && ticketing_url !== null
                            ? outLinkAppTicket
                            : status === "MS01" && ticketing_url === ""
                            ? () =>
                                  alertMsg("구매 가능한 티켓 정보가 없습니다.")
                            : (status === "MS03" || status === "MS02") &&
                              isApply === "0"
                            ? () =>
                                  alertMsg(
                                      "승부예측 이벤트에 참여 하지 않은 경기입니다.",
                                  )
                            : () =>
                                  navigate(
                                      `/matchPredictionView/${arr.id}${search}`,
                                      scrollObj,
                                  )
                    }
                />
            );
        };

        const notLoginGameType = () => {
            console.log("Bb");
            return (
                <BtnDefault
                    text={
                        status === "MS01" && ticketing_url !== null
                            ? "티켓 예매하기"
                            : status === "MS01" && ticketing_url === null
                            ? "티켓 오픈 예정"
                            : "승부예측 결과보기"
                    }
                    size="block"
                    onClick={
                        status === "MS01" && ticketing_url !== null
                            ? outLinkAppTicket
                            : (status === "MS01" || status === "MS03") &&
                              ticketing_url === ""
                            ? () =>
                                  alertMsg("구매 가능한 티켓 정보가 없습니다.")
                            : status === "MS03" && isApply === "0"
                            ? () => appLogin()
                            : null
                    }
                    className={
                        status === "MS01" && ticketing_url !== null
                            ? "btn-gradient-red"
                            : status === "MS01" && ticketing_url === null
                            ? "btn-disabled-grey"
                            : status === "MS03" && isApply === "1"
                            ? "btn-gradient-black"
                            : (status === "MS01" && !ticketing_url) ||
                              (status === "MS03" && isApply === "0")
                            ? "btn-disabled-gray"
                            : null
                    }
                />
            );
        };

        return (
            <li key={key} className="match-li">
                <div className="match-inr">
                    <div className="match-list-head">
                        <div className="match-hd-left">
                            {gubun === "Y" ? (
                                <span className="match-type home">HOME</span>
                            ) : (
                                <span className="match-type away">AWAY</span>
                            )}
                            <span className="match-stadium">{stadium}</span>
                        </div>
                        {/*{seat_url !== null && gubun === "Y" && (*/}
                        {/*    <div className="match-hd-right">*/}
                        {/*        <BtnDefault*/}
                        {/*            text={"3D 좌석보기"}*/}
                        {/*            className={"btn 3dLink"}*/}
                        {/*            onClick={outLinkAppPost}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>

                    <div className="match-content">
                        <div className="match-home match-team">
                            <div className="team-logo home">
                                <img src={home_team_logo} alt="" />
                            </div>
                            <div className="team-name">{homeTeam.owner}</div>
                        </div>
                        <div className="match-info match-team">
                            <p className="match-info-date">
                                {setDateFormat(match_date)}
                            </p>
                            {status === "MS03" ? (
                                <div className="match-play-type end">
                                    경기종료
                                </div>
                            ) : status === "MS02" ? (
                                <div className="match-play-type pre">
                                    경기 진행중
                                </div>
                            ) : (
                                <div className="match-play-type pre">
                                    경기예정
                                </div>
                            )}
                            {home_score !== null && status === "MS03" ? (
                                endGameScore()
                            ) : home_score === null && status === "MS03" ? (
                                <div className="match-finsh-scoreCheck">
                                    결과 확인중
                                </div>
                            ) : null}
                        </div>
                        <div className="match-away match-team">
                            <div className="team-logo home">
                                <img src={away_team_logo} alt="" />
                            </div>
                            <div className="team-name">{oppTeam.owner}</div>
                        </div>
                    </div>
                    <div className="match-btn-box">
                        {loginCheck.result === "Y"
                            ? loginGameType()
                            : notLoginGameType()}
                    </div>
                </div>
            </li>
        );
    });

    return (
        <>
            <div className="match-poster">
                <ul className="match-poster-ul">
                    {matchArr.length > 0 ? (
                        markUp
                    ) : (
                        <li>해당 경기 일정이 없습니다.</li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default MatchList;
