import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { eventVoteCheck } from "./module";
import { BtnDefault } from "../../components/common/common";
import {
    handleAppBridge,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import { FULL_URL } from "../../constant/contant";
import useLoginCheck from "../../hooks/useLoginCheck";

const EventMatch = ({ post }) => {
    const { Away, Home, MatchSchedule, stadium } = post;
    const { OppTeam } = Away;
    const { HomeTeam } = Home;
    const { match_date, event_yn, id } = MatchSchedule;

    const [isPlayerId, setIsPlayerId] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data } = useSelector((state) => ({
        data: state.event.voteCheck.data,
    }));

    const isLogin = useLoginCheck();

    const matchDay = setDateFormat(match_date);

    const handleLink = () => {
        if (isLogin) {
            // 투표 참여 페이지 이동
            const { search } = window.location;
            navigate(`/eventVoting/${id}${search}`, scrollObj);
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 이벤트 참여 여부 체크
    useEffect(() => {
        dispatch(
            eventVoteCheck({
                match_id: id,
            }),
        );
    }, [id]);

    useEffect(() => {
        if (data) {
            !data.player_id && setIsPlayerId(false);
        }
    }, [data]);

    return (
        <div className="macth-banner-wrap">
            <div className="match-banner-box">
                <div className="match-bn-inr">
                    <div className="match-bn-hd">
                        <div className="match-hd-left">
                            <span className="match-type home">HOME</span>
                            <span className="match-stadium">{stadium}</span>
                        </div>
                    </div>
                    <div className="match-bn-container">
                        <div className="match-home match-team">
                            <div className="team-logo home">
                                <img
                                    src={`${FULL_URL}/file/${HomeTeam.team_pic_url}`}
                                    alt=""
                                />
                            </div>
                            <div className="team-name">
                                {HomeTeam.team_name}
                            </div>
                        </div>
                        <div className="match-vs match-team">{matchDay}</div>
                        <div className="match-away match-team">
                            <div className="team-logo home">
                                <img
                                    src={`${FULL_URL}/file/${OppTeam.team_pic_url}`}
                                    alt=""
                                />
                            </div>
                            <div className="team-name">{OppTeam.owner}</div>
                        </div>
                    </div>
                </div>
            </div>
            {event_yn === "Y" && (
                <div className="match-btn-box">
                    <BtnDefault
                        text={
                            data?.data?.player_id
                                ? "투표 참여 완료"
                                : "투표 참여하기"
                        }
                        className="btn-gradient-red"
                        size="block"
                        onClick={handleLink}
                    />
                </div>
            )}
        </div>
    );
};

export default EventMatch;
