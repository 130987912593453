import { call, takeEvery, put } from "redux-saga/effects";
import {
    snsGetList,
    snsGetListSuccess,
    snsGetView,
    snsGetViewSuccess,
} from "./sns.slice";
import { snsAPI, snsViewAPI } from "../API/snsAPI";

function* getList(action) {
    const { payload: params } = action;

    try {
        const result = yield call(snsAPI, params);
        const isData = result.data?.boardList?.length;

        yield put(
            snsGetListSuccess({
                count: result.data.count,
                data: isData ? result.data.boardList : result.data,
            }),
        );
    } catch (error) {
        console.error(error);
    }
}

export function* snsSaga() {
    yield takeEvery(snsGetList, getList);
}

function* getView(action) {
    const { payload: params } = action;

    try {
        const result = yield call(snsViewAPI, params);

        yield put(
            snsGetViewSuccess({
                data: result.data,
            }),
        );
    } catch (error) {
        console.error(error);
    }
}

export function* snsViewSaga() {
    yield takeEvery(snsGetView, getView);
}
