import React from "react";
import {
    BtnDefault,
    BtnOutline,
    BtnOutline02,
    BtnRound,
    BtnSecondary,
    BtnUnderLine,
} from "../../components/common/common";
import { useDispatch } from "react-redux";
import { modalOpen } from "../../components/layerModal/module/layerModal";
import LayerModal from "../../components/layerModal/layerModal";
import { APPLE_EVENT_MESSAGE, IOS } from "../../constant/contant";

const Guide = () => {
    const dispatch = useDispatch();

    const GiftModal = () => {
        return (
            <div className="gift-area">
                <div className="content">
                    <p className="sub-title">포인트 1000점</p>
                    <h2 className="title">“당첨되셨습니당.”</h2>
                </div>
            </div>
        );
    };

    return (
        <div className="pepper-guide">
            <h1 className="main-title">Components</h1>
            <section>
                <h1 className="title">Buttons</h1>
                <div className="content">
                    <div className="button-wrap">
                        <BtnDefault text="Default" />
                        <BtnDefault text="Disabled" disabled={true} />
                        <BtnSecondary text="Secondary" />
                        <BtnOutline text="Outline 01" />
                        <BtnOutline02 text="Outline 02" />
                        <BtnRound text="Round" />
                        <BtnRound text="Round Active" active={true} />
                        <BtnDefault text="Medium" size="medium" />
                        <BtnDefault text="Large" size="large" />
                        <BtnDefault text="Block" size="block" />
                        <BtnUnderLine text="underline" />
                        <BtnUnderLine text="underline Active" active={true} />
                        <BtnRound
                            text="BtnRound small line"
                            size="small"
                            className="line"
                        />
                        {/* size : medium, large, block */}
                    </div>
                </div>
            </section>
            <h1 className="main-title">Style</h1>
            <section>
                <h1 className="title">Tab - Basic</h1>
                <div className="content">
                    <ul className="tab-basic">
                        <li>
                            <BtnRound
                                name="preparing"
                                text="#예정"
                                active={true}
                            />
                        </li>
                        <li>
                            <BtnRound name="ongoing" text="#진행중" />
                        </li>
                        <li>
                            <BtnRound name="close" text="#종료" />
                        </li>
                    </ul>
                </div>
            </section>
            <section>
                <h1 className="title">Tab - Scroll</h1>
                <div className="content">
                    <div className="tab-scroll">
                        <BtnOutline02 text="All" name="all" active={true} />
                        <BtnOutline02 text="Outside hitter" name="OH" />
                        <BtnOutline02 text="Opposite" name="OP" />
                        <BtnOutline02 text="Middle Blocker" name="MB" />
                        <BtnOutline02 text="Setter" name="S" />
                        <BtnOutline02 text="Libero" name="L" />
                    </div>
                </div>
            </section>
            <section>
                <h1 className="title">Tab - Type 2</h1>
                <div className="content">
                    <div className="tab-scroll">
                        <BtnUnderLine text="전체" active={true} />
                        <BtnUnderLine text="두번째 탭" />
                        <BtnUnderLine text="세번째 탭" />
                    </div>
                </div>
            </section>
            <section>
                <h1 className="title">Text List - Basic</h1>
                <div className="content">
                    <ul className="text-list-basic">
                        <li>본 이벤트는 앱 전용 이벤트입니다.</li>
                        <li>
                            당첨자에 한하여 개별 메시지를 발송 드리며,
                            미당첨자에게는 별도의 연락을 드리지 않습니다.
                        </li>
                    </ul>
                </div>
            </section>
            <section>
                <h1 className="title">Text List - Dot</h1>
                <div className="content">
                    <ul className="text-list-dot">
                        <li>본 이벤트는 앱 전용 이벤트입니다.</li>
                        <li>
                            무작위 추첨으로 당첨자를 선별하며,운영자는 당첨자
                            추첨에 일절 관여하지 않습니다.
                        </li>
                        <li>
                            2021년 11월 16일부터 5만원 초과 경품 제공 시
                            제세공과금 22%는 당첨자를 대신하여 당사에서
                            부담합니다.
                        </li>
                        <li>
                            당사는 제세공과금 처리 및 세무신고를 위해 당첨자의
                            신분증 사본 등의 개인정보를 요청할 수 있습니다.
                        </li>
                        {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                    </ul>
                </div>
            </section>
            <section>
                <h1 className="title">Checkbox - Basic</h1>
                <div className="content">
                    <label className="checkbox-basic">
                        <input type="checkbox" name="" />
                        <span className="text">유의사항에 동의합니다.</span>
                    </label>
                </div>
            </section>
            <h1 className="main-title">Layer Modal</h1>
            <section>
                <div className="content">
                    <div className="button-wrap">
                        <BtnDefault
                            text="Modal Open"
                            size="medium"
                            onClick={() => modalOpen(dispatch)}
                        />
                    </div>
                    <LayerModal
                        modalName="attendance"
                        content={<GiftModal />}
                        // onClose={() => {}}
                    />
                </div>
            </section>
        </div>
    );
};

export default Guide;
