import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const faqListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getFaqList",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }
        return response.data;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
