import { put, takeEvery, call } from "redux-saga/effects";
import {
    pepperNewsGetList,
    pepperNewsGetListSuccess,
    pepperNewsGetView,
    pepperNewsGetViewSuccess,
} from "./pepperNews.slice";
import { pepperNewsListAPI, pepperNewsViewAPI } from "../API/pepperNewsAPI";

function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(pepperNewsListAPI, params);
        yield put(pepperNewsGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* pepperNewsSaga() {
    yield takeEvery(pepperNewsGetList, getList);
}

function* getView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(pepperNewsViewAPI, params);
        yield put(pepperNewsGetViewSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* pepperNewsViewSaga() {
    yield takeEvery(pepperNewsGetView, getView);
}
