import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventLeagues } from "./module";
import EventMatch from "./eventMatch";
import { handleAppBridge } from "../../libs/js/common";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Event = () => {
    const dispatch = useDispatch();
    const { data, code } = useSelector((state) => ({
        data: state.event.leagues.data,
        code: state.event.leagues.code,
    }));
    const [isLoading, setIsLoading] = useState(true);

    
    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button6_이벤트_수훈선수_click','event_button6_이벤트_수훈선수_click','event_button6_이벤트_수훈선수_click');
            }
        }
    }, []);

    useEffect(() => {
        dispatch(eventLeagues());
    }, []);

    useEffect(() => {
        if (code) {
            setIsLoading(false);
        }
    }, [code]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    return (
        <div className="event-wrap">
            <div className="inner">
                <div className="event-info">
                    <p>
                        경기중에만 참여 가능한 <span>팝업 이벤트</span>로
                        <br />
                        현장에 내방한 관람객을 위한 이벤트입니다.
                    </p>
                </div>
                {data && !Array.isArray(data) && <EventMatch post={data} />}
                <div className="event-information">
                    <h1 className="title">수훈선수 이벤트 유의사항</h1>
                    <div className="content">
                        <ul className="text-list-basic">
                            <li>해당 이벤트는 홈 경기장에 내방한 현장관람객 전용 이벤트로 승리시에만 당첨자 추첨이 진행됩니다.</li>
                            <li>현장에서의 상품 수령을 원칙으로 하며, 경품 수령 장소는 <strong>홈 경기장 2층 인포메이션</strong> 입니다.</li>
                            <li>당첨자는 현장 전광판을 통해 공개되며, 당첨자에게만 푸시알림을 통해 연락을 드립니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Event;
