import { createSlice } from "@reduxjs/toolkit";

export const infiniteSlice = createSlice({
    name: "infinite",
    initialState: {
        page: 0,
        posts: null,
    },
    reducers: {
        setInfinite: (state, { payload }) => {
            state.page = payload.page;
            state.posts = payload.posts;
        },
    },
});

export const { setInfinite } = infiniteSlice.actions;
