import React from "react";
import { BtnDefault } from "../../components/common/common";
import { handleAppBridge } from "../../libs/js/common";

const Marketing = () => {
    const handleClick = () => {
        handleAppBridge({
            fnName: "onClose",
        });
    };
    return (
        <>
            <section className="page-modal">
                <header>
                    <h2 className="title">마케팅 수신 동의</h2>
                    <button className="btn-modal-close" onClick={handleClick}>
                        닫기
                    </button>
                </header>
                <article className="modal-body">
                    <div className="content marketing-content">
                        <h2 className="title">
                            마케팅 및 광고 활용을 위한 개인정보 수집 동의
                        </h2>
                        <p>
                            AI PEPPERS는 ‘팬 커뮤니케이션 앱(명칭)’ 회원을
                            대상으로 하는 이벤트 및 서비스 안내 등 광고성 정보를
                            제공하기 위하여 아래와 같이 개인정보 수집 및
                            이용하고자 합니다.
                        </p>
                        <dl className="marketing-list">
                            <dt>수집 항목</dt>
                            <dd>휴대폰 전화번호, 이메일</dd>
                        </dl>
                        <dl className="marketing-list">
                            <dt>수집 목적</dt>
                            <dd>이벤트 등 광고성 정보 전달</dd>
                        </dl>
                        <dl className="marketing-list">
                            <dt>보유/이용기간</dt>
                            <dd>
                                <em>회원 탈퇴시까지</em>
                            </dd>
                        </dl>
                        <p>
                            위의 개인정보 수집 이용에 대한 동의를 거부할 권리가
                            있습니다. 그러나 동의를 거부할 경우, 이벤트 및
                            광고성 정보에 대한 전달을 받을 수 없음을
                            알려드립니다.
                        </p>
                        <p className="marketing-md-text color-666">준법심의번호 23-COM-0378</p>
                    </div>
                    {/*<div className="button-wrap center">*/}
                    {/*    <BtnDefault*/}
                    {/*        text="확인"*/}
                    {/*        size="block"*/}
                    {/*        onClick={handleClick}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </article>
            </section>
        </>
    );
};

export default Marketing;
