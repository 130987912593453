import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leagueSort } from "./module";

const MatchSort = ({ useListParam, setListParam, nextMatchRes }) => {
    const { league_id, callAPI, league_detail_id } = useListParam;
    const dispatch = useDispatch();
    const leagueSelect = useRef();
    const leagueRound = useRef();

    const [useRound, setRound] = useState([
        {
            title: "1R",
            value: "RD01",
        },
        {
            title: "2R",
            value: "RD02",
        },
        {
            title: "3R",
            value: "RD03",
        },
        {
            title: "4R",
            value: "RD04",
        },
        {
            title: "5R",
            value: "RD05",
        },
        {
            title: "6R",
            value: "RD06",
        },
    ]);

    useEffect(() => {
        if (!!league_id && !callAPI) {
            dispatch(leagueSort());
        }
    }, [useListParam]);

    const sortList = useSelector((state) => {
        return state.match.leagueSort;
    });

    useEffect(() => {
        if (!nextMatchRes.data) {
            const { league_detail_id } = useListParam;
            sortType(league_detail_id);
        } else {
            const { league_detail_id } = nextMatchRes.data;
            sortType(league_detail_id);
        }
    }, []);

    const sortType = (id) => {
        if (id === "SP01") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
            ]);
        }

        if (id === "PO01") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
            ]);
        }

        if (id === "PO02") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
            ]);
        }

        if (id === "PO03") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
            ]);
        }

        if (id === "CH01") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
                {
                    title: "C.H 1",
                    value: "CH01",
                },
            ]);
        }

        if (id === "CH02") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
                {
                    title: "C.H 1",
                    value: "CH01",
                },
                {
                    title: "C.H 2",
                    value: "CH02",
                },
            ]);
        }

        if (id === "CH03") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
                {
                    title: "C.H 1",
                    value: "CH01",
                },
                {
                    title: "C.H 2",
                    value: "CH02",
                },
                {
                    title: "C.H 3",
                    value: "CH03",
                },
            ]);
        }

        if (id === "CH04") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
                {
                    title: "C.H 1",
                    value: "CH01",
                },
                {
                    title: "C.H 2",
                    value: "CH02",
                },
                {
                    title: "C.H 3",
                    value: "CH03",
                },
                {
                    title: "C.H 4",
                    value: "CH04",
                },
            ]);
        }

        if (id === "CH05") {
            setRound([
                ...useRound,
                {
                    title: "준 P.O",
                    value: "SP01",
                },
                {
                    title: "P.O 1",
                    value: "PO01",
                },
                {
                    title: "P.O 2",
                    value: "PO02",
                },
                {
                    title: "P.O 3",
                    value: "PO03",
                },
                {
                    title: "C.H 1",
                    value: "CH01",
                },
                {
                    title: "C.H 2",
                    value: "CH02",
                },
                {
                    title: "C.H 3",
                    value: "CH03",
                },
                {
                    title: "C.H 4",
                    value: "CH04",
                },
                {
                    title: "C.H 5",
                    value: "CH05",
                },
            ]);
        }
    };

    if (!sortList) return false;

    const sortFilter = sortList.filter((arr) => {
        return arr.up_cd_id === "LE00";
    });

    const sortLeague = sortFilter.map((data, idx) => {
        const { cd_id, cd_nm } = data;
        return (
            <option value={cd_id} key={idx}>
                {cd_nm}
            </option>
        );
    });

    const changeLeagueSort = (target) => {
        setListParam({
            ...useListParam,
            league_id: target.target.value,
            league_detail_id: "RD01",
            callAPI: true,
        });
    };

    const changeRoundSort = (target) => {
        const leagueSortVal = leagueSelect.current;
        setListParam({
            ...useListParam,
            league_id: leagueSortVal.value,
            league_detail_id: target.target.value,
            callAPI: true,
        });
    };

    const sortMarkup = useRound.map((item, key) => {
        return (
            <option value={item.value} key={key}>
                {item.title}
            </option>
        );
    });

    return (
        <div className="match-sort-box">
            <select
                name=""
                id="legSelect"
                className="select"
                defaultValue={useListParam.league_id}
                onChange={(e) => changeLeagueSort(e)}
                ref={leagueSelect}
            >
                {sortLeague}
            </select>
            <select
                name=""
                id="legRoundSelect"
                className="select"
                defaultValue={league_detail_id}
                ref={leagueRound}
                onChange={(e) => changeRoundSort(e)}
            >
                {sortMarkup}
            </select>
        </div>
    );
};

export default MatchSort;
