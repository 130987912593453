import { createSlice } from "@reduxjs/toolkit";

export const layerModalSlice = createSlice({
    name: "layerModal",
    initialState: {
        isModalOpen: false,
    },
    reducers: {
        layerModalOpen: (state) => {
            state.isModalOpen = true;
        },
        layerModalClose: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { layerModalOpen, layerModalClose } = layerModalSlice.actions;
