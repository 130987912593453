import { FULL_URL } from "../../constant/contant";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { scrollObj } from "../../libs/js/common";

const Athlete = ({ post, type, userInfo, setUserInfo, playerId }) => {
    const navigate = useNavigate();
    const [isPlayer, setIsPlayer] = useState(false);
    const { player_pic_url, back_no, player_name, id, position_name } = post;
    const handleNumber = (num) => (num < 10 ? `0${num}` : num);

    const handleNextPage = () => {
        const { search } = window.location;
        navigate(`/playerView/${id}${search}`, scrollObj);
    };

    // 이벤트 > 선수 클릭
    const handleVote = () => {
        setUserInfo((userInfo) => {
            return {
                ...userInfo,
                player_id: id,
                name: player_name,
            };
        });
    };

    useEffect(() => {
        if (playerId === id) {
            setIsPlayer(true);
        }
    }, [playerId]);

    return (
        <li
            className={classNames(
                "player-list",
                playerId === null && "vote",
                isPlayer && "active",
            )}
            style={{
                backgroundImage: `url(${FULL_URL}/file/${player_pic_url})`,
            }}
        >
            <button
                className="player-link"
                onClick={type === "basic" ? handleNextPage : null}
            >
                <div className="top">
                    {type === "event" ? (
                        <>
                            {playerId === null && (
                                <>
                                    <input
                                        type="radio"
                                        name="player"
                                        className="radio-input"
                                        id={`player${id}`}
                                        onChange={handleVote}
                                        checked={userInfo.player_id === id}
                                    />
                                    <label
                                        className="radiobox-basic"
                                        htmlFor={`player${id}`}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <span className="label">{handleNumber(back_no)}</span>
                    )}
                    <strong className="player-name">{player_name}</strong>
                    <span className="position">{position_name}</span>
                </div>
            </button>
        </li>
    );
};

export default Athlete;
