import React, { useEffect } from "react";
import MatchPrediction from "./matchPrediction";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { matchInfo, matchVictory } from "./module";

const MatchPredictionCon = () => {
    const { match_id } = useParams();
    const dispatch = useDispatch();
    const matchInfoRes = useSelector((state) => {
        return state.matchPrediction.matchInfo;
    });

    useEffect(() => {
        dispatch(
            matchInfo({
                id: match_id,
            }),
        );
    }, []);

    if (!matchInfoRes) return false;

    return <MatchPrediction matchInfoRes={matchInfoRes} match_id={match_id} />;
};

export default MatchPredictionCon;
