import { createSlice } from "@reduxjs/toolkit";

export const ediyaSlice = createSlice({
    name: "ediya", // slice name
    initialState: {
        ediyaCpnCnt: {
            data: null,
            code: null,
        },
        ediyaCpnIssue: {
            data: null,
            code: null,
        },
        myEdiyaCpnCnt: {
            data: null,
            code: null,
        },
        myEdiyaCpn: {
            data: null,
            code: null,
        },
    },
    reducers: {
        ediyaCpnCnt: (state) => {
            state.ediyaCpnCnt.data = null;
            state.ediyaCpnCnt.code = null;
        },
        ediyaCpnCntSuccess: (state, { payload: { data, code } }) => {
            state.ediyaCpnCnt.data = data;
            state.ediyaCpnCnt.code = code;
        },
        ediyaCpnIssue: (state) => {
            state.ediyaCpnIssue.data = null;
            state.ediyaCpnIssue.code = null;
        },
        ediyaCpnIssueSuccess: (state, { payload: { data, code } }) => {
            state.ediyaCpnIssue.data = data;
            state.ediyaCpnIssue.code = code;
        },
        myEdiyaCpnCnt: (state) => {
            state.myEdiyaCpnCnt.data = null;
            state.myEdiyaCpnCnt.code = null;
        },
        myEdiyaCpnCntSuccess: (state, { payload: { data, code } }) => {
            state.myEdiyaCpnCnt.data = data;
            state.myEdiyaCpnCnt.code = code;
        },
        myEdiyaCpn: (state) => {
            state.myEdiyaCpn.data = null;
            state.myEdiyaCpn.code = null;
        },
        myEdiyaCpnSuccess: (state, { payload: { data, code } }) => {
            state.myEdiyaCpn.data = data;
            state.myEdiyaCpn.code = code;
        },
    },
});

export const {
    ediyaCpnCnt,
    ediyaCpnCntSuccess,
    ediyaCpnIssue,
    ediyaCpnIssueSuccess,
    myEdiyaCpnCnt,
    myEdiyaCpnCntSuccess,
    myEdiyaCpn,
    myEdiyaCpnSuccess,
} = ediyaSlice.actions;
