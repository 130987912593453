import { put, takeEvery, call } from "redux-saga/effects";
import { buyInterparkCodeAPI, myTicketListAPI } from "../API/myTicketAPI";
import {
    myTicketGetList,
    myTicketGetListSuccess,
    getMyPoint,
    getMyPointSuc,
    buyInterParkCode,
    buyInterParkCodeSuc,
} from "./myTicket.slice";
import { getMyPointAPI } from "../API/myTicketAPI";

// my Ticket List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(myTicketListAPI, params);
        yield put(myTicketGetListSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* myTicketSaga() {
    yield takeEvery(myTicketGetList, getList);
}

// My Point List
function* getMyPointAction(action) {
    const { payload: params } = action;

    try {
        const data = yield call(getMyPointAPI, params);
        yield put(getMyPointSuc(data));
    } catch (err) {
        console.error(err);
    }
}

export function* getMyPointSaga() {
    yield takeEvery(getMyPoint, getMyPointAction);
}

// InterPark Code Buy
function* buyInterParkCodeBuyAction(action) {
    const { payload: params } = action;

    try {
        const data = yield call(buyInterparkCodeAPI, params);
        yield put(buyInterParkCodeSuc(data));
    } catch (err) {
        console.error(err);
    }
}

export function* buyInterParkCodeSaga() {
    yield takeEvery(buyInterParkCode, buyInterParkCodeBuyAction);
}
