import React from "react";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import { BtnSecondary } from "../../components/common/common";
import { handleAppBridge, setDateFormat } from "../../libs/js/common";
import { TOKEN } from "../../constant/contant";
import useLoginCheck from "../../hooks/useLoginCheck";

const PlayerBottom = ({ posts, floatingBox, onFloatingClick }) => {
    const {
        player_name,
        e_player_name,
        back_no,
        position_name,
        nation_name,
        birth_date,
        el_school,
        mid_school,
        high_school,
        height,
        kovo_url,
        user_id, // player userid
        nation,
    } = posts;

    const handleNumber = (num) => (num < 10 ? `0${num}` : num);

    const handleClick = () => {
        handleAppBridge({
            fnName: "onOpenPage",
            param: kovo_url,
        });
    };

    const isLogin = useLoginCheck();

    const handlePlayerClick = () => {
        if (isLogin) {
            handleAppBridge({
                fnName: "goPlayer",
                param: String(user_id), // player userid
            });
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    return (
        <div
            className={`player-bottom-sheet ${floatingBox ? "open" : "close"}`}
        >
            <SwipeableBottomSheet
                overflowHeight={90}
                topShadow={false}
                overlay={true}
                overlayStyle={{ background: "transparent" }}
                open={floatingBox}
                onChange={onFloatingClick}
                style={{ zIndex: "100", boxShadow: "none" }}
                bodyStyle={{ background: "transparent" }}
            >
                <div style={{ height: "390px" }}>
                    <div className="player-floating-box inner">
                        <div className="name-wrap">
                            <button
                                className="floating-button"
                                onClick={onFloatingClick}
                            >
                                버튼
                            </button>
                            <strong className="name">{player_name}</strong>
                            <span className="name-en">{e_player_name}</span>
                            <span
                                className="channel"
                                onClick={handlePlayerClick}
                            >
                                선수채널
                            </span>
                            <span className="label">
                                {handleNumber(back_no)}
                            </span>
                        </div>
                        <div className="player-information">
                            <dl>
                                <dt>포지션</dt>
                                <dd>{position_name}</dd>
                            </dl>
                            <dl>
                                <dt>생년월일</dt>
                                <dd>{setDateFormat(birth_date, "short")}</dd>
                            </dl>
                            {(el_school || mid_school || high_school) && (
                                <dl>
                                    <dt>출신학교</dt>
                                    <dd>
                                        {el_school && `${el_school} / `}
                                        {mid_school && `${mid_school} / `}
                                        {high_school}
                                    </dd>
                                </dl>
                            )}
                            {!(el_school || mid_school || high_school) && (
                                <dl>
                                    <dt>국가</dt>
                                    <dd>{nation_name}</dd>
                                </dl>
                            )}
                            <dl>
                                <dt>신장</dt>
                                <dd>{height}cm</dd>
                            </dl>
                        </div>
                        <div className="button-wrap">
                            <BtnSecondary
                                text="기록 보러가기"
                                size="block"
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </div>
            </SwipeableBottomSheet>
        </div>
    );
};

export default PlayerBottom;
