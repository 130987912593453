import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {
        type: "",
        count: 0,
        data: null,
    },
    view: {
        id: "",
        data: null,
    },
};

export const pepperNewsSlice = createSlice({
    name: "pepperNews", // slice name
    initialState,
    reducers: {
        pepperNewsGetList: (state, { payload: { type } }) => {
            state.list.type = type;
            state.list.count = 0;
            state.list.data = null;
        },
        pepperNewsGetListSuccess: (state, { payload: { data } }) => {
            state.list.count = data.count;
            state.list.data = data.boardList;
        },
        pepperNewsGetView: (state, { payload: id }) => {
            state.view.id = id;
            state.view.data = null;
        },
        pepperNewsGetViewSuccess: (state, { payload: { data } }) => {
            state.view.data = data.board;
            state.view.comments = data.comment;
        },
    },
});

export const {
    pepperNewsGetList,
    pepperNewsGetListSuccess,
    pepperNewsGetView,
    pepperNewsGetViewSuccess,
} = pepperNewsSlice.actions;
