import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

// 티켓 리스트
export const myTicketListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/mypage/myinfo/searchMyInterparkCodeHistory",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 내 포인트 조회
export const getMyPointAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/mypage/myinfo/getMyPoint",
            data: params,
        });

        const { code, msg } = response;
        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }
        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

// 티켓 구매
export const buyInterparkCodeAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/mypage/myinfo/buyInterparkCode",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        // 포인트 부족 시
        if (code === "E144") {
            alert(msg);
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
