import "./libs/styles/style.scss";
import "swiper/css/bundle";

import ScrollTop from "./pages/scrollTop/scrollTop";
import Header from "./components/header/header";
import { Outlet } from "react-router";
import React from "react";
import { Cookies } from "react-cookie";

function App() {

    return (
        <>
            <ScrollTop />
            <Header />
            <Outlet />
        </>
    );
}

export default App;
