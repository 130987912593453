import React, { useRef } from "react";

const AccordionItem = ({ post, onToggle, active }) => {
    const { title, content } = post;
    const contentEl = useRef();

    return (
        <li className={`accordion_item ${active ? "active" : ""}`}>
            <div className="question" onClick={onToggle}>
                {title}
            </div>
            <div
                className="answer-wrap"
                ref={contentEl}
                style={
                    active
                        ? { height: contentEl.current.scrollHeight }
                        : { height: "0px" }
                }
            >
                <div className="answer">{content}</div>
            </div>
        </li>
    );
};

export default AccordionItem;
