import React, { useEffect, useState } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import CompleteClock from "./completeClock";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { BtnDefault, BtnOutline } from "../../components/common/common";
import { useNavigate } from "react-router";

import "moment/locale/ko";
import {
    handleAppBridge,
    scrollObj,
    setDateFormat,
} from "../../libs/js/common";
import {
    APPLE_EVENT_MESSAGE,
    CLOSE,
    FULL_URL,
    IOS,
    TOKEN,
} from "../../constant/contant";

import fallbackImage from "libs/images/common/fallback_image.png";
import { useDispatch, useSelector } from "react-redux";
import { luckyDrawGetApplyCheck, luckyDrawWinnerYn } from "./module";
import { luckyDrawWinnerAPI } from "./API/luckyDrawAPI";
import useLoginCheck from "../../hooks/useLoginCheck";

export const luckyDrawNoteList = [
    `본 이벤트는 앱 전용 이벤트입니다.`,
    `무작위 추첨으로 당첨자를 선별하며, 운영자는 당첨자 추첨에 일절 관여하지 않습니다.`,
    `당첨 메시지 발송 이후 7일 이내에 회신이 없을 경우, 당첨이 자동 취소 됩니다.`,
    `당첨자에 한하여 개별 메시지 발송 등의 연락이 진행되며, 미당첨자에게는 연락이 가지 않습니다.`,
    `5만원 초과 경품의 제세공과금(22%)은 당첨자 본인 부담입니다.`,
    `제세공과금 납부와 관련한 세부사항은 소득세법, 지방세법 및 기타 관련 법령의 내용에 따라 처리합니다.`,
    `고객 귀책 (고객 정보 오전달)으로 인한 이벤트 경품 미수령, 오배송, 반송에 대한 재발송 처리가 불가합니다.`,
    `휴대폰 번호가 확인이 되지 않을 경우, 연락 불가로 인해 당첨이 취소될 수 있으며 경품 수령이 불가할 수 있습니다.`,
    `부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며, 법에 따른 처벌 대상이 될 수 있습니다.`,
    `고객 과실로 인한 경품 분실 시, 재발송은 불가합니다.`,
    `경품 수령 후 반품 처리 불가합니다.`,
    `해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 내용 변경 또는 조기 종료 될 수 있습니다.`,
    `경품의 단종, 품절 등과 같은 당사 및 공급사 사정으로 인해 경품 지급이 어려울 경우,다른 경품으로 대체될 수 있습니다.`,
];

const LuckyDrawViewItem = ({ post }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const applyCheckCode = useSelector(
        (state) => state.luckyDraw.view.applyCheckCode,
    );
    const isLogin = useLoginCheck();

    const [isApply, setIsApply] = useState(false); // 응모 여부
    const [errorImageIdx, setErrorImageIdx] = useState([]); // 에러 이미지 모음
    const [isComplete, setIsComplete] = useState(false); // 이벤트 종료 여부
    const [isPassWinnerDate, setIsPassWinnerDate] = useState(false);
    const [winner, setWinner] = useState(false);

    /**
     * id : 이벤트 아이디
     * event_type : 이벤트 상태
     * event_name : 이벤트 명
     * start_dt : 이벤트 시작일
     * end_dt : 이벤트 종료일
     * winner_dt : 당첨자 발표일
     * file_path : 파일 경로
     * winner_num : 당첨 인원
     * */

    const {
        id,
        event_type,
        event_name,
        start_dt,
        end_dt,
        end_dt_se,
        winner_dt,
        file_path,
        file_path2,
        file_path3,
        file_path4,
        file_path5,
        winner_num,
        winner_result_yn,
    } = post;

    // 유효한 슬라이드 이미지
    const slideImages = [
        file_path,
        file_path2,
        file_path3,
        file_path4,
        file_path5,
    ].filter((item) => !!item);

    const handleImageError = (i) => {
        setErrorImageIdx((errorImageIdx) => {
            if (errorImageIdx.indexOf(i) === -1) {
                return [...errorImageIdx, i];
            }

            return errorImageIdx;
        });
    };

    const handleApply = () => {
        if (isLogin) {
            if (!isApply) {
                const { search } = window.location;

                navigate(`/luckyDrawApply/${id}${search}`, {
                    state: {
                        ...scrollObj.state,
                        endDate: new Date(end_dt),
                    },
                });
            }
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 당첨자 여부 확인
    const handleCheckWinner = () => {
        if (winner_result_yn !== "Y") {
            alert("아직 당첨자 추첨 전 입니다.");
            return false;
        }
        setWinner(true);
        dispatch(luckyDrawWinnerYn({ event_id: id }));
    };

    const { winData, winCode } = useSelector((state) => ({
        winData: state.luckyDraw.winner.data,
        winCode: state.luckyDraw.winner.code,
    }));

    useEffect(() => {
        if (winner) {
            if (winData !== null) {
                // console.log(" winData >>> ", winData);
                const winnerYn = winData?.winnerYn;
                if (winnerYn === "Y") {
                    alert("축하드립니다. 당첨되셨습니다.");
                }
                if (winnerYn === "N") {
                    alert("아쉽지만 이번에는 당첨되지 않으셨어요.");
                }
                if (winnerYn === "D") {
                    alert("해당 이벤트에 참여한 정보가 없습니다.");
                }
            }
        }
    }, [winCode]);

    // 응모 여부
    useEffect(() => {
        // 이미 응모했을 경우
        if (applyCheckCode === "E117") {
            setIsApply(true);
        }
    }, [applyCheckCode]);

    // 마운트 시
    useEffect(() => {
        if (new Date() > new Date(end_dt_se)) {
            // 이벤트 종료일보다 크고 발표일 경과 여부
            setIsPassWinnerDate(new Date() >= new Date(winner_dt));
        }

        // 응모기간 지났을 경우
        if (new Date() > new Date(end_dt_se)) {
            setIsComplete(true);
        }
    }, []);

    useEffect(() => {
        // 응모 여부 체크
        if (isLogin) {
            dispatch(
                luckyDrawGetApplyCheck({
                    id,
                }),
            );
        }
    }, [isLogin]);

    return (
        <div className="lucky-draw-view">
            <div className="inner">
                <div className="flip-clock-wrap">
                    {isComplete ? (
                        <CompleteClock />
                    ) : (
                        <FlipClockCountdown
                            className="flip-clock"
                            to={new Date(end_dt_se).getTime()}
                            labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                            showSeparators={false}
                            duration={0.5}
                            onComplete={() => setIsComplete(true)}
                        />
                    )}
                </div>
                <div className="lucky-draw-swiper">
                    <Swiper
                        modules={[Navigation, Pagination]}
                        navigation={slideImages.length > 1}
                        loop={slideImages.length > 1}
                        pagination={{ clickable: true }}
                    >
                        {slideImages.length ? (
                            slideImages.map((item, i) => {
                                return (
                                    <SwiperSlide key={item}>
                                        <div className="image-wrap">
                                            <img
                                                src={
                                                    errorImageIdx.indexOf(i) >=
                                                    0
                                                        ? fallbackImage
                                                        : `${FULL_URL}/file/${item}`
                                                }
                                                onError={() =>
                                                    handleImageError(i)
                                                }
                                            />
                                        </div>
                                    </SwiperSlide>
                                );
                            })
                        ) : (
                            <SwiperSlide>
                                <div className="image-wrap">
                                    <img src={fallbackImage} />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <section className="lucky-draw-information">
                    <header>
                        <span className="sub-title">LUCKY DRAW</span>
                        <h2 className="title">{event_name}</h2>
                    </header>
                    <dl className="details-list">
                        <dt>응모기간</dt>
                        <dd>
                            {`${setDateFormat(start_dt)} ~ ${setDateFormat(
                                end_dt,
                            )}`}
                        </dd>

                        <dt>당첨자 발표</dt>
                        <dd>
                            {winner_dt
                                ? setDateFormat(winner_dt, "ko-short")
                                : "미정"}
                        </dd>

                        <dt>당첨 인원</dt>
                        <dd>{`${winner_num ?? 0}명`}</dd>
                    </dl>
                    <div className="point-note">
                        <h2 className="title">럭키드로우 유의사항</h2>
                        <ul className="text-list-dot point-note-list">
                            {luckyDrawNoteList.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                            {IOS && <li>{APPLE_EVENT_MESSAGE}</li>}
                        </ul>
                    </div>
                    <div className="button-wrap-single">
                        {isPassWinnerDate ? (
                            <BtnOutline
                                text="당첨자 확인"
                                size="large"
                                onClick={handleCheckWinner}
                            />
                        ) : (
                            <BtnDefault
                                text={
                                    isApply ? "응모완료" : "응모하기 (-1,000P)"
                                }
                                size="large"
                                disabled={
                                    event_type === CLOSE ||
                                    isApply ||
                                    isComplete
                                }
                                onClick={handleApply}
                            />
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default LuckyDrawViewItem;
