import { put, takeEvery, call } from "redux-saga/effects";
import { bannerGetView, bannerGetViewSuccess } from "./banner.slice";
import { bannerViewAPI } from "../API/bannerAPI";

function* getView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(bannerViewAPI, params);
        yield put(bannerGetViewSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* bannerViewSaga() {
    yield takeEvery(bannerGetView, getView);
}


