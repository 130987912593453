import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./match.reducer";
const name = "match";

const initialState = {
    matchList: null,
    matchNext: null,
    leagueSort: null,
};

export const matchSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const { match, nextMatch, leagueSort } = matchSlice.actions;
