import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const snsAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "sns/info/showSns",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

export const snsViewAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "sns/info/showSnsView",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};

export const boardCommentDeleteAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "category/peppernow/boardCommentDelete",
            data: params,
        });

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
