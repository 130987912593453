import { put, takeEvery, call } from "redux-saga/effects";
import {
    checkMatchVictoryApplyAPI,
    getMatchVictoryAPI,
    matchInfoAPI,
    postMatchVictoryAPI,
} from "../API/matchPredictionAPI";
import {
    matchInfo,
    matchVictory,
    getMatchView,
    checkApply,
} from "./matchPrediction.slice";

function* matchInfoAction(action) {
    const param = action.payload;
    try {
        const data = yield call(matchInfoAPI, param);
        yield put({ type: matchInfo, data });
    } catch (err) {
        console.error(err);
    }
}

export function* matchPredictionInfoSaga() {
    yield takeEvery(matchInfo.type, matchInfoAction);
}

function* matchVictoryAction(action) {
    const param = action.payload;
    try {
        const data = yield call(postMatchVictoryAPI, param);
        yield put({ type: matchVictory, data });
    } catch (err) {
        console.error(err);
    }
}

export function* matchVictorySaga() {
    yield takeEvery(matchVictory.type, matchVictoryAction);
}

// MATCH VIEW
function* getMatchVictoryAction(action) {
    const param = action.payload;
    try {
        const data = yield call(getMatchVictoryAPI, param);
        yield put({ type: getMatchView, data });
    } catch (err) {
        console.error(err);
    }
}

export function* matchJoinViewSaga() {
    yield takeEvery(getMatchView.type, getMatchVictoryAction);
}

// MATCH EVENT APPLY CHECK
function* getMatchVictoryApplyCheck(action) {
    const param = action.payload;
    try {
        const data = yield call(checkMatchVictoryApplyAPI, param);
        yield put({ type: checkApply, data });
    } catch (err) {
        console.error(err);
    }
}

export function* getMatchVictoryApplyCheckSaga() {
    yield takeEvery(checkApply.type, getMatchVictoryApplyCheck);
}
