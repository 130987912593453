import React from "react";
import { useNavigate } from "react-router";
import { scrollObj } from "../../libs/js/common";

const ApplicationItem = ({ post }) => {
    const navigate = useNavigate();
    const { event_rel_id, event_type, event_desc, event_type_nm } = post;

    const handleClick = () => {
        const { search } = window.location;

        switch (event_type) {
            case "MP":
                return navigate(
                    `/matchPredictionView/${event_rel_id}${search}`,
                    scrollObj,
                );
            case "VP":
                return navigate(
                    `/eventVoting/${event_rel_id}${search}`,
                    scrollObj,
                );
            default:
                return navigate(
                    `/luckyDrawView/${event_rel_id}${search}`,
                    scrollObj,
                );
        }
    };

    return (
        <li className="application-list" onClick={handleClick}>
            <span className="label">[{event_type_nm}]</span>
            <strong className="title">{event_desc}</strong>
        </li>
    );
};

export default ApplicationItem;
