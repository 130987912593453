import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { applicationGetList } from "./module";
import Infinite from "../../components/infinite/infinite";
import ApplicationItem from "./applicationItem";
import { handleAppBridge } from "../../libs/js/common";

const ApplicationCon = () => {
    const { data, count, code } = useSelector((state) => ({
        data: state.application.list.data,
        count: state.application.list.count,
        code: state.application.list.code,
    }));

    const [isLoad, setIsLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const onItemComponent = (post) => {
        return <ApplicationItem post={post} key={post.id} />;
    };

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <p className="no-purchase-result">
            <strong>응모내역이 없습니다.</strong>
            <span>
                AI PEPPERS에서 준비한 <br />
                다양한 이벤트에 참여해 보세요.
            </span>
        </p>
    );

    useEffect(() => {
        setIsLoad(true);
    }, []);

    useEffect(() => {
        if (code) {
            setIsLoading(false);
        }
    }, [code]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    if (!isLoad) return false;

    return (
        <div className="application-wrap">
            <div className="inner">
                <p className="top-description">
                    최근 3개월간의 응모 내역만 보입니다.
                </p>
                <Infinite
                    listClass="application-list-wrap"
                    count={count}
                    data={data}
                    onGetList={applicationGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                />
            </div>
        </div>
    );
};

export default ApplicationCon;
