import React, { useEffect, useState } from "react";
import { BtnDefault, BtnOutline } from "../../components/common/common";
import { CLOSE, ONGOING, PREPARING } from "../../constant/contant";
import { useNavigate } from "react-router";
import {
    scrollObj,
    setDateFormat,
    setLocalStorage,
} from "../../libs/js/common";
import { FULL_URL } from "../../constant/contant";
import preparingImage from "../../libs/images/luckyDraw/preparing.svg";
import sampleImage from "../../libs/images/luckyDraw/sample.png";

const LuckyDrawListItem = ({ post, activeTab }) => {
    const navigate = useNavigate();

    /**
     * id : 이벤트 아이디
     * event_name : 이벤트 명
     * start_dt : 이벤트 시작일
     * winner_dt : 당첨자 발표일
     * file_path : 파일 경로
     * */

    const { id, event_name, start_dt, end_dt, winner_dt, file_path } = post;

    const [isPassWinnerDate, setIsPassWinnerDate] = useState(false); // 발표일 경과 여부
    const [errorImageIdx, setErrorImageIdx] = useState([]); // 에러 이미지 모음

    const getBtnText = () => {
        switch (activeTab) {
            case PREPARING:
                return `${setDateFormat(start_dt, "short")} OPEN`;
            case ONGOING:
                return "상세보기";
            case CLOSE:
                const date = winner_dt ? setDateFormat(winner_dt, "short") : "";
                const text = isPassWinnerDate ? "확인" : "발표";

                return `${date} 당첨자 ${text}`;
            default:
                return "";
        }
    };

    const handleNextPage = () => {
        const { search } = window.location;
        navigate(`/luckyDrawView/${id}${search}`, scrollObj);

        // 데이터 추가 - 스크롤 위치
        setLocalStorage("scrollY", window.scrollY);
    };

    const handleImageError = (i) => {
        setErrorImageIdx((errorImageIdx) => {
            if (errorImageIdx.indexOf(i) === -1) {
                return [...errorImageIdx, i];
            }

            return errorImageIdx;
        });
    };

    useEffect(() => {
        const today = new Date();
        const endDate = new Date(end_dt);
        const winnerDate = new Date(winner_dt);

        if (winnerDate) {
            setIsPassWinnerDate(today >= endDate && today >= winnerDate);
        }
    }, []);

    return (
        <li className={isPassWinnerDate ? "close-post" : "post"}>
            <h2 className="title">{event_name}</h2>
            <div className="post-image-wrap">
                <img
                    src={
                        errorImageIdx.indexOf(id) > -1
                            ? preparingImage
                            : `${FULL_URL}/file/${file_path}`
                    }
                    alt={event_name}
                    className="post-image"
                    onError={() => handleImageError(id)}
                />
            </div>
            {isPassWinnerDate ? (
                <BtnOutline
                    text={getBtnText()}
                    size="large"
                    onClick={handleNextPage}
                />
            ) : (
                <BtnDefault
                    text={getBtnText()}
                    size="large"
                    disabled={activeTab === PREPARING}
                    onClick={handleNextPage}
                />
            )}
        </li>
    );
};

export default LuckyDrawListItem;
