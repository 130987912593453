import React from "react";
import BtnLike from "./btnLike";
import classNames from "classnames";
import { FULL_URL } from "../../constant/contant";
import { videoCommentLikeAPI } from "../../pages/video/API/videoAPI";
import { videoGetView } from "../../pages/video/module";
import { useDispatch } from "react-redux";
import defaultImage from "libs/images/common/profile_small.png";
import useLoginCheck from "../../hooks/useLoginCheck";
import { handleAppBridge } from "../../libs/js/common";

const CommentItem = ({ post, onSheetOpen, onWrite, onCommentLike }) => {
    const dispatch = useDispatch();

    const {
        id,
        boardId,
        username,
        profilePicSmall, // 썸네일
        comment, // 코멘트 내용
        parentId, // 부모 아이디
        parentNickname, // 부모 닉네임
        diffDate, // 등록일
        isSub,
        like,
        likeCnt,
        commentActive,
        role,
        type,
        userId,
        targetName,
    } = post;

    const isLike = like === "Y"; // 좋아요 여부
    const isDelete = commentActive === "false"; // 삭제 여부
    const isPlayer = role === "player"; // 작성자가 선수인지 여부

    const isType = type;

    // 좋아요 버튼 클릭 시
    const handleLike = () => {
        onCommentLike(id, parentNickname);
    };

    const isLogin = useLoginCheck();

    const handlePlayerClick = () => {
        if (isLogin) {
            handleAppBridge({
                fnName: "goPlayer",
                param: String(userId), // player userid
            });
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    const imageOnLoad = (event) => {
        if (event.currentTarget.className !== "error") {
          event.currentTarget.className = "success";
        }
    };
    
    const imageOnError = (event) => {
        event.currentTarget.src = defaultImage;
        event.currentTarget.className = "error";
    };

    /* 삭제된 댓글일 경우 */
    if (isType == "D") {
        return <div className="comment-item">삭제된 댓글입니다.</div>;
    } else if (isType == "B") {
        return <div className="comment-item">차단된 사용자의 댓글입니다.</div>;
    } else if (isType == "S") {
        return <div className="comment-item">정지된 사용자의 댓글입니다.</div>;
    } else if (isType == "R") {
        return <div className="comment-item">찾을 수 없는 사용자 입니다.</div>;
    }

    // if (isPlayer) {
    //     return <div className="comment-item">찾을 수 없는 사용자 입니다.</div>;
    // }

    return (
        <div className="comment-item">
            <button
                type="button"
                className="avatar"
                onClick={handlePlayerClick}
            >
                <img
                    src={ profilePicSmall !== null ?  `${FULL_URL}file/${profilePicSmall}` : defaultImage }
                    onLoad={imageOnLoad}
                    onError={imageOnError}
                    alt={username}
                />
            </button>
            <div className="comment-content">
                <div className="comment-user">
                    <em
                        className={classNames(
                            "nickname",
                            isPlayer && "is-player",
                        )}
                    >
                        {username}
                    </em>
                    <span className="date">{diffDate}</span>
                </div>
                <div className="comment-text">
                    {/* TODO 대댓글의 댓글일 경우만 노출 */}
                    {targetName && (
                        <span className="author">@{targetName}</span>
                    )}

                    {comment}
                </div>
                <ul className="comment-icons">
                    <li>
                        <BtnLike
                            count={likeCnt}
                            isLike={isLike}
                            id={`commentLike${id}`}
                            onChange={handleLike}
                        />
                    </li>
                    <li>
                        <button
                            type="button"
                            className="btn-comment-write"
                            onClick={(e) => onWrite(e, post, parentId)}
                        >
                            댓글쓰기
                        </button>
                    </li>
                </ul>
            </div>
            <div className="comment-more">
                <button
                    type="button"
                    className="btn-comment-more"
                    onClick={() => onSheetOpen(post, parentId)}
                >
                    더보기
                </button>
            </div>
        </div>
    );
};

export default CommentItem;
