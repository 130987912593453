import { put, takeEvery, call } from "redux-saga/effects";
import { staffListApi, staffMenuListApi } from "../API/staffListApi";
import { staffGetList, staffGetListSuccess, staffGetMenuList, staffGetMenuListSuccess } from "./staff.slice";

// Staff List

function* getist(action) {
    const { payload: params } = action;
    try {
        const data = yield call(staffListApi, params);
        yield put(staffGetListSuccess(data));
    } catch (err) { 
        console.warn("Failed to process data:" + err);
    }
}

export function* staffListSaga() {
    yield takeEvery(staffGetList, getist);
}

function* getMenuList(action) {
    const { payload: params } = action;
    try {
        const data = yield call(staffMenuListApi, params);
        yield put(staffGetMenuListSuccess(data));
    } catch (err) {
        console.warn("Failed to process data:" + err);
     }
}

export function* staffMenuListSaga() {
    yield takeEvery(staffGetMenuList, getMenuList);
}
