import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { luckyDrawGetList } from "./module";
import LuckyDrawTabs from "./luckyDrawTabs";
import { CLOSE, ONGOING, PREPARING } from "../../constant/contant";
import Infinite from "../../components/infinite/infinite";
import LuckyDrawListItem from "./luckyDrawListItem";
import { doc } from "prettier";
import { modalOpen } from "../../components/layerModal/module/layerModal";
import LayerModal from "../../components/layerModal/layerModal";
import { handleAppBridge } from "../../libs/js/common";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const LuckyDrawList = () => {
    const dispatch = useDispatch();
    const { step, count, data } = useSelector((state) => ({
        step: state.luckyDraw.list.step,
        count: state.luckyDraw.list.count,
        data: state.luckyDraw.list.data,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'm1_e' ) {
                TrackGoogleAnalyticsEvent('maintab1_event_LUCKY DRAW_click','maintab1_event_LUCKY DRAW_click','maintab1_event_LUCKY DRAW_click');
            } else if( gb === 'm3_b' ) {
                TrackGoogleAnalyticsEvent('maintab3_banner_LUCKY DRAW_click','maintab3_banner_LUCKY DRAW_click','maintab3_banner_LUCKY DRAW_click');
            } else if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button3_이벤트_럭키드로우_click','event_button3_이벤트_럭키드로우_click','event_button3_이벤트_럭키드로우_click');
            }            
        } 
    }, []);

    const [activeTab, setActiveTab] = useState(step);
    const [posts, setPosts] = useState(null);

    // 탭 클릭 시
    const onTabClick = (type) => {
        if (activeTab !== type) {
            setActiveTab(type);
        }
    };

    // 리스트 아이템
    const onItemComponent = (post) => (
        <LuckyDrawListItem key={post.id} post={post} activeTab={activeTab} />
    );

    // 데이터 없을 경우
    const onNoneResultComponent = () => {
        return (
            <p className="none-result">
                {activeTab === PREPARING && "예정중인 이벤트가 없습니다."}
                {activeTab === ONGOING && "진행중인 이벤트가 없습니다."}
                {activeTab === CLOSE && (
                    <>
                        종료된 이벤트가 없습니다.
                        <span className="description">
                            최근 3개월 동안의 럭키드로우 내역만 보여집니다.
                        </span>
                    </>
                )}
            </p>
        );
    };

    const handleGoBack = () => {
        document.body.style.overflow = "unset";
        //navigator(-1);
        handleAppBridge({
            fnName: "goBack",
        });
    };

    const GiftModal = () => {
        return (
            <div className="next-seezn">
                <div className="content" />
            </div>
        );
    };

    useEffect(() => {
        setPosts(data);
    }, [data]);

    useEffect(() => {
        // 시즌 종료 시 모달 노출
        //modalOpen(dispatch);
    }, []);

    return (
        <div className="lucky-draw">
            <LayerModal
                modalName="nextSeezn"
                content={<GiftModal />}
                bottomButton={false}
                closeClick={handleGoBack}
                closeClass={"white-close"}
            />
            <div className="inner">
                <div className="lucky-draw-content">
                    <LuckyDrawTabs
                        activeTab={activeTab}
                        onTabClick={onTabClick}
                    />
                    <section className="step">
                        {activeTab === CLOSE && (
                            <p className="message">
                                최근 3개월 동안의 진행 내역만 보여집니다.
                            </p>
                        )}
                        <Infinite
                            listClass="lucky-draw-posts"
                            count={count}
                            data={posts}
                            activeTab={activeTab}
                            onGetList={luckyDrawGetList}
                            onItemComponent={onItemComponent}
                            onNoneResultComponent={onNoneResultComponent}
                        />
                    </section>
                </div>
            </div>
        </div>
    );
};

export default LuckyDrawList;
