import React, { useState } from "react";
import NewsTabs from "./newsTabs";
import EventList from "./eventList";
import NoticeList from "./noticeList";
import { useDispatch } from "react-redux";
import { newsTab } from "./module";

const NewsItem = ({ newsName }) => {
    const dispatch = useDispatch();
    const [tabName, setTabName] = useState(newsName);

    // tab
    const handleTabClick = (e) => {
        e.preventDefault();
        const { name } = e.target;

        if (tabName !== name) {
            setTabName(name);
        }

        dispatch(
            newsTab({
                tab: name,
            }),
        );
    };

    return (
        <div className="news-wrap">
            {/* <NewsTabs tabName={tabName} handleTabClick={handleTabClick} /> */}
            <div className={`inner ${tabName}`}>
                {tabName === "event" ? <EventList /> : <NoticeList />}
            </div>
        </div>
    );
};

export default NewsItem;
