import { createSlice } from "@reduxjs/toolkit";

export const newsSlice = createSlice({
    name: "news",
    initialState: {
        newsTab: {
            tab: "",
        },
        noticeList: {
            count: 0,
            data: null,
        },
        noticeView: {
            id: "",
            data: null,
        },
        eventList: {
            count: 0,
            data: null,
        },
    },
    reducers: {
        newsTab: (state, { payload: { tab } }) => {
            state.newsTab.tab = tab;
        },
        noticeGetList: (state) => {
            state.noticeList.count = 0;
            state.noticeList.data = null;
        },
        noticeGetListSuccess: (state, { payload: { data, count } }) => {
            state.noticeList.count = count;
            state.noticeList.data = data;
        },
        noticeGetView: (state, { payload: id }) => {
            state.noticeView.id = id;
            state.noticeView.data = null;
        },
        noticeGetViewSuccess: (state, { payload }) => {
            state.noticeView.data = payload;
        },
        eventGetList: (state) => {
            state.eventList.count = 0;
            state.eventList.data = null;
        },
        eventGetListSuccess: (state, { payload: { data, count } }) => {
            state.eventList.count = count;
            state.eventList.data = data;
        },
    },
});

export const {
    newsTab,
    noticeGetList,
    noticeGetListSuccess,
    noticeGetView,
    noticeGetViewSuccess,
    eventGetList,
    eventGetListSuccess,
} = newsSlice.actions;
