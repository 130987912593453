import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { Provider } from "react-redux";
import store from "./libs/storySet/configureStore.";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Match from "./pages/match/match";
import LuckyDrawList from "./pages/luckyDraw/luckyDrawList";
import LuckyDrawView from "./pages/luckyDraw/luckyDrawView";
import LuckyDrawApply from "./pages/luckyDraw/luckyDrawApply";
import MatchPredictionCon from "./pages/matchPrediction/matchPredictionCon";
import MatchPredictionViewCon from "./pages/matchPrediction/matchPredictionViewCon";
import MatchEventApplyCon from "./pages/matchPrediction/matchEventApplyCon";
import PlayerList from "./pages/player/playerList";
import Player from "./pages/player/player";
import Merchandise from "./pages/merchandise/merchandise";
import About from "./pages/about/about";
import Team from "./pages/team/team";
import Guide from "./pages/guide/guide";
import Workspace from "./pages/workspace/workspace";
import Attendance from "./pages/attendance/attendance";
import AttendanceApply from "./pages/attendance/attendanceApply";
import PepperLiveCon from "./pages/pepperLive/pepperLiveCon";
import Event from "./pages/event/event";
import VoteList from "./pages/event/voteList";
import EventApply from "./pages/event/eventApply";
import Ticket from "./pages/ticket/ticket";
import MyTicketCoupon from "./pages/myTicket/myTicketCoupon";
import TicketCouponBuyCon from "./pages/myTicket/ticketCouponBuyCon";
import Faq from "./pages/faq/faq";
import Policy from "./pages/footer/policy";
import Terms from "./pages/footer/terms";
import NewsCon from "./pages/news/newsCon";
import NoticeView from "./pages/news/noticeView";
import ApplicationCon from "./pages/application/applicationCon";
import Marketing from "./pages/marketing/marketing";
import EventIntro from "./pages/event/eventIntro";
import ErrorTest from "./pages/errorTest/errorTest";
import ErrorView1 from "./pages/errorTest/errorView1";
import ErrorView2 from "./pages/errorTest/errorView2";
import NotFound from "./pages/error/notFound";
import Policy2 from "./pages/footer/policy2";
import PolicyControl from "./pages/footer/policyControl";
import Video from "./pages/video/video";
import VideoView from "./pages/video/videoView";
import Login from "./pages/auth/login";
import Sns from "./pages/sns/sns";
import PepperNews from "./pages/pepperNews/pepperNews";
import PepperNewsView from "./pages/pepperNews/pepperNewsView";
import Boradcast from "./pages/broadcast/boradcast";
import Banner from "./pages/banner/bannerDetail";
import DormantAccount from "./pages/auth/dormantAccount";
import Membership from "./pages/membership/membership";
import Ediya231011 from "./pages/event/ediyaEvent";
import Gateway from "./pages/gateway/gateway";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "luckyDraw",
                element: <LuckyDrawList />,
                errorElement: <NotFound />,
            },
            {
                path: "luckyDrawView/:event_id",
                element: <LuckyDrawView />,
                errorElement: <NotFound />,
            },
            {
                path: "luckyDrawApply/:event_id",
                element: <LuckyDrawApply />,
                errorElement: <NotFound />,
            },
            {
                path: "luckyDrawApply/:event_id",
                element: <LuckyDrawApply />,
                errorElement: <NotFound />,
            },
            {
                path: "match",
                element: <Match />,
                errorElement: <NotFound />,
            },
            {
                path: "matchPrediction/:match_id",
                element: <MatchPredictionCon />,
                errorElement: <NotFound />,
            },
            {
                path: "matchPredictionView/:match_id",
                element: <MatchPredictionViewCon />,
                errorElement: <NotFound />,
            },
            {
                path: "matchEventApply/:match_id/:victory_id",
                element: <MatchEventApplyCon />,
                errorElement: <NotFound />,
            },
            {
                path: "playerList",
                element: <PlayerList />,
                errorElement: <NotFound />,
            },
            {
                path: "playerView/:seq_id",
                element: <Player />,
                errorElement: <NotFound />,
            },
            {
                path: "merchandise",
                element: <Merchandise />,
                errorElement: <NotFound />,
            },
            {
                path: "about",
                element: <About />,
                errorElement: <NotFound />,
            },
            {
                path: "staff",
                element: <Team />,
                errorElement: <NotFound />,
            },
            {
                path: "organization",
                element: <Team />,
                errorElement: <NotFound />,
            },
            {
                path: "guide",
                element: <Guide />,
                errorElement: <NotFound />,
            },
            {
                path: "attendance",
                element: <Attendance />,
                errorElement: <NotFound />,
            },
            {
                path: "attendanceApply/:attend_id",
                element: <AttendanceApply />,
                errorElement: <NotFound />,
            },
            {
                path: "video/:type",
                element: <Video />,
                errorElement: <NotFound />,
            },
            {
                path: "video/:type/:contentCd",
                element: <Video />,
                errorElement: <NotFound />,
            },
            {
                path: "video/:type/:contentCd/:id",
                element: <VideoView />,
                errorElement: <NotFound />,
            },
            {
                path: "pepperLive/:id",
                element: <PepperLiveCon />,
                errorElement: <NotFound />,
            },
            {
                path: "event",
                element: <Event />,
                errorElement: <NotFound />,
            },
            {
                path: "eventVoting/:event_match",
                element: <VoteList />,
                errorElement: <NotFound />,
            },
            {
                path: "eventApply/:event_match/:player_id",
                element: <EventApply />,
                errorElement: <NotFound />,
            },
            {
                path: "ticket",
                element: <Ticket />,
                errorElement: <NotFound />,
            },
            {
                path: "myTicketCoupon",
                element: <MyTicketCoupon />,
                errorElement: <NotFound />,
            },
            {
                path: "ticketCouponBuy",
                element: <TicketCouponBuyCon />,
                errorElement: <NotFound />,
            },
            {
                path: "faq",
                element: <Faq />,
                errorElement: <NotFound />,
            },
            {
                path: "policy",
                element: <PolicyControl />,
                errorElement: <NotFound />,
            },
            {
                path: "/terms",
                element: <Terms />,
                errorElement: <NotFound />,
            },
            {
                path: "/news/:newsName",
                element: <NewsCon />,
                errorElement: <NotFound />,
            },
            {
                path: "/noticeView/:notice_id",
                element: <NoticeView />,
                errorElement: <NotFound />,
            },
            {
                path: "/application",
                element: <ApplicationCon />,
                errorElement: <NotFound />,
            },
            {
                path: "/workspace",
                element: <Workspace />,
                errorElement: <NotFound />,
            },
            {
                path: "/errorTest",
                element: <ErrorTest />,
            },
            {
                path: "/errorTestView",
                element: <ErrorView1 />,
            },
            {
                path: "/errorTestView1",
                element: <ErrorView2 />,
            },
            {
                path: "marketing",
                element: <Marketing />,
            },
            {
                path: "sns",
                element: <Sns />,
            },
            {
                path: "sns/:type",
                element: <Sns />,
            },
            {
                path: "peppernews",
                element: <PepperNews />,
            },
            {
                path: "peppernews/:news_id",
                element: <PepperNewsView />,
            },
            {
                path: "broadcast",
                element: <Boradcast />,
            },
            {
                path: "banner/:banner_id",
                element: <Banner />,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "dormantAccount",
                element: <DormantAccount />,
            },
            {
                path: "membership",
                element: <Membership />,
            },
            {
                path: "ediya_231011",
                element: <Ediya231011 />,
            },
            {
                path: "gateway/:type",
                element: <Gateway />,
            },
        ],
    },
    {
        path: "eventIntro",
        element: <EventIntro />,
    },
]);

root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>,
);
