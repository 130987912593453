import { handleAppBridge, handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const applicationAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            //url: "/mypage/myinfo/searchEventApplyHistory",
            url: "/mypage/myinfo/getMyEvents",
            data: params,
        });

        const { code, msg } = response;

        if (code === "E101") {
            handleAppBridge({
                fnName: "goLogout",
                param: [msg, "refresh"],
            });
        }

        return response;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
