import React, { useEffect, useState } from "react";
import { FULL_URL } from "../../constant/contant";
import { handleAppBridge, setDateFormat } from "../../libs/js/common";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { snsGetView } from "./module";

const SnsItem = ({ post }) => {
    const dispatch = useDispatch();

    const { data } = useSelector((state) => ({
        data: state.sns.view.data,
    }));

    const { id, title, regDt, typeCd, thumb, link, imageUrl } = post;

    const [images, setImages] = useState(null);
    const [isPhotoClick, setIsPhotoClick] = useState(false);
    const [addClass, setAddClass] = useState(false);

    const getPostType = () => {
        switch (typeCd) {
            case "SN01":
                return "photo";
            case "SN02":
                return "instagram";
            case "SN03":
                return "naver-post";
            case "SN04":
                return "naver-tv";
            default:
                return "photo";
        }
    };

    const getPostTypeKR = () => {
        switch (typeCd) {
            case "SN01":
                return "페퍼포토";
            case "SN02":
                return "인스타그램";
            case "SN03":
                return "네이버 포스트";
            case "SN04":
                return "네이버 TV";
            default:
                return "페퍼포토";
        }
    };

    // 이미지 뷰어 호출
    const handleImageViewer = () => {
        handleAppBridge({
            fnName: "onImageViewer",
            param: imageUrl,
        });
        setIsPhotoClick(false);
    };

    const handleClick = () => {
        if (typeCd === "SN01") {
            // 페퍼 포토 (이미지 뷰어)
            if (imageUrl) {
                handleImageViewer();
            }
            setIsPhotoClick(true);
        } else {
            // 외부 링크 오픈
            handleAppBridge({
                fnName: "onOpenPage",
                param: link,
            });
        }
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (id === data?.board?.id) {
            if (data.image?.length) {
                setImages(
                    data.image.map(
                        (item) => `${FULL_URL}file/${item.imageUrl}`,
                    ),
                );
            }
        }
    }, [data]);

    useEffect(() => {
        if (images && isPhotoClick) {
            alert("imageViewers - useEffect");
            handleImageViewer();
        }
    }, [images]);

    useEffect(() => {
        if (typeCd === "SN01" || typeCd === "SN02") {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }, [isVisible]);

    useEffect(() => {
        if (typeCd === "SN01") {
            setAddClass(false);
        } else {
            setAddClass(true);
        }
    }, [addClass]);

    return (
        <>
            <li
                className={classNames("live-post", getPostType())}
                onClick={handleClick}
            >
                <div className={`thumb ${addClass ? "" : "square"}`}>
                    <img src={`${FULL_URL}file/${thumb}`} alt={title} />
                </div>
                {isVisible && <p className="title">{title}</p>}
                <div className="live-info">
                    <span>{getPostTypeKR()}</span>
                    <span>{regDt}</span>
                </div>
            </li>
        </>
    );
};

export default SnsItem;
