import React from "react";

const ErrorView1 = () => {
    return (
        <div style={{ height: "1000px", padding: "20px" }}>
            Navigate로 들어온 페이지
        </div>
    );
};

export default ErrorView1;
