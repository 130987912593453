import { put, takeEvery, call, all } from "redux-saga/effects";
import { aboutGetList, aboutGetListFailure, aboutGetListSuccess, aboutGetMenuList, aboutGetMenuListSuccess } from "./about.slice";
import { aboutListApi, aboutMenuListApi } from "../API/aboutApi";

// about List

function* getist(action) {
    const { payload: tabList } = action;
    try {
        const data = yield all(tabList.map((tab) => call(aboutListApi, tab.cdId)));
        yield put(aboutGetListSuccess(data));
    } catch (err) {
        yield put(aboutGetListFailure(err.message));
     }
}

export function* aboutListSaga() {
    yield takeEvery(aboutGetList, getist);
}

function* getMenuList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(aboutMenuListApi, params);
        yield put(aboutGetMenuListSuccess(data));
    } catch (err) { 
        console.warn("Failed to process data:" + err);
     }
}

export function* aboutMenuListSaga() {
    yield takeEvery(aboutGetMenuList, getMenuList);
}
