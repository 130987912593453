import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./gateway.reducer";

const initialState = {
    list: {
        data: null,
    },
};

export const gatewaySlice = createSlice({
    name: "gateway",
    initialState,
    reducers,
});

export const { gatewayGetDetail, gatewayGetDetailSuccess } = gatewaySlice.actions;