import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { handleAppBridge } from "../../libs/js/common";
import useLoginCheck from "../../hooks/useLoginCheck";

const AttendanceItem = ({ post, onAttendanceCheck }) => {
    const {
        date,
        attend_point,
        attend_product_type,
        attend_check,
        attend_gift_open,
        submitInfo,
        giftType,
    } = post;

    const isLogin = useLoginCheck();

    const [isComplete, setIsComplete] = useState(false);
    const [attendanceType, setAttendanceType] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    // const [isUserInfoSubmit, setIsUserInfoSubmit] = useState(false);

    const day = new Date(date).getDate();
    const today = new Date().getDate();
    const isToday = today === day;
    const isPast = today > day;
    const isFuture = today < day;
    // const isSubmit = attend_gift_open === "Y"; // 제출 여부 && submitInfo === 1
    const isUserInfoSubmit = giftType === "B" && Number(submitInfo) === 1;

    const isDisabled =
        isFuture ||
        (attendanceType === "gift" ? isSubmit : isPast || isComplete);

    // useEffect(() => {
    //     if (post.id === "786") {
    //         console.log(" post ", post);
    //     }
    // }, []);
    const handleAttendanceCheck = () => {
        // 로그인 여부 체크
        if (isLogin) {
            if (attendanceType === "gift") {
                //미스터리박스
                if (giftType === "B") {
                    //실물
                    if (isFuture) return false;
                    if (isToday && isUserInfoSubmit) return false;
                } else {
                    //포인트
                    if (isPast || isFuture) return false;
                    if (isComplete) return false;
                    setIsSubmit(true);
                }
                setIsComplete(true);
            } else {
                //포춘쿠키, 포인트
                if (isSubmit) return false;
                if (isComplete) return false;
                if (isPast || isFuture) return false;
            }
            setIsComplete(true);

            // return false;
            //출석체크 토스트
            onAttendanceCheck(post);
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 마운트 시
    useEffect(() => {
        const getAttendanceType = () => {
            switch (attend_product_type) {
                case "PT02":
                    return "cookie";
                case "PT03": //기프트박스(미스터리박스)
                    if (isLogin) {
                        if (giftType === "B") {
                            //실물
                            return "gift";
                        } else {
                            return "point"; //포인트
                        }
                    } else {
                        return "gift";
                    }
                default:
                    return "point";
            }
        };

        setAttendanceType(getAttendanceType());
    }, []);

    useEffect(() => {
        if (isLogin) {
            setIsSubmit(attend_gift_open === "Y");

            if (attend_check === "Y") {
                setIsComplete(attend_check === "Y");
            }
        }
    }, [isLogin]);

    return (
        <div className="attendance-item">
            <button
                type="button"
                className={classNames(
                    `btn-attendance ${attendanceType}`,
                    isToday && "today",
                    isPast && "past",
                    isFuture && "future",
                    isComplete && "complete",
                    // isComplete && attendanceType === "gift"
                    //     ? isSubmit
                    //         ? "submit-complete"
                    //         : "none-submit"
                    //     : null,
                    attendanceType === "gift" && giftType === "B"
                        ? isUserInfoSubmit
                            ? "submit-complete"
                            : "none-submit"
                        : isSubmit
                        ? "submit-complete"
                        : "none-submit",
                )}
                onClick={handleAttendanceCheck}
                // disabled={isDisabled}
            >
                +{attend_point}P
            </button>
            <span className="date">{day}일</span>
        </div>
    );
};

export default AttendanceItem;
