import { Cookies } from "react-cookie";
import { getUserAgent } from "../libs/js/common";

// API
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;

// TOKEN
export const TOKEN = new Cookies().get("token");
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
// export const HEAD_TOKEN = { headers: { "Auth-Token": TOKEN ?? "" } };

// COMMON
export const FULL_URL = process.env.REACT_APP_FULL_URL;
export const IOS = getUserAgent() === "WI";
export const APPLE_EVENT_MESSAGE = "본 이벤트는 Apple과 무관합니다.";

// LUCKY DRAW
export const PREPARING = "pre";
export const ONGOING = "on";
export const CLOSE = "close";

// AUTH
export const IS_AUTH = new Cookies().get("isAuth") === "Y";
