import React from "react";

const ErrorView2 = () => {
    return (
        <div style={{ height: "1000px", padding: "20px" }}>
            Link로 들어온 페이지
        </div>
    );
};

export default ErrorView2;
