import { put, takeEvery, call } from "redux-saga/effects";
import { broadcastAPI } from "../API/broadcastAPI";
import { broadcastInfo, broadcastInfoSuccess } from "./broadcast.slice";

function* broadcastView(action) {
    const { payload: params } = action;

    try {
        const data = yield call(broadcastAPI, params);
        yield put(broadcastInfoSuccess(data));
    } catch (err) {
        console.error(err);
    }
}

export function* broadcastSaga() {
    yield takeEvery(broadcastInfo, broadcastView);
}
