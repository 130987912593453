import { createSlice } from "@reduxjs/toolkit";

export const myTicketSlice = createSlice({
    name: "myTicket",
    initialState: {
        list: {
            count: 0,
            data: null,
        },
        buyCode: { data: null },
        buyList: {
            data: null,
        },
    },
    reducers: {
        myTicketGetList: (state) => {
            state.list.count = 0;
            state.list.data = null;
        },
        myTicketGetListSuccess: (state, { payload: { data, count } }) => {
            state.list.count = count;
            state.list.data = data;
        },
        getMyPoint: (state) => {
            state.buyList.data = null;
        },
        getMyPointSuc: (state, { payload: { data, code, msg } }) => {
            state.buyList.data = data;
            state.buyList.code = code;
        },
        buyInterParkCode: (state) => {
            state.buyCode.data = null;
        },
        buyInterParkCodeSuc: (state, { payload: { data, code, msg } }) => {
            state.buyCode = { data, code, msg };
        },
    },
});

export const {
    myTicketGetList,
    myTicketGetListSuccess,
    getMyPoint,
    getMyPointSuc,
    buyInterParkCode,
    buyInterParkCodeSuc,
} = myTicketSlice.actions;
