import { put, takeEvery, call } from "redux-saga/effects";
import {
    attendanceApply,
    attendanceApplySuccess,
    attendanceCheck,
    attendanceCheckSuccess,
    attendanceGetList,
    attendanceGetSuccess,
} from "./attendance.slice";
import {
    attendanceApplyAPI,
    attendanceCheckAPI,
    attendanceListAPI,
} from "../API/attendanceAPI";

// List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(attendanceListAPI, params);
        yield put(attendanceGetSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* attendanceListSaga() {
    yield takeEvery(attendanceGetList, getList);
}

// 출석체크
function* setAttendanceCheck(action) {
    const { payload: params } = action;

    try {
        const data = yield call(attendanceCheckAPI, params);
        yield put(attendanceCheckSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* attendanceSetSaga() {
    yield takeEvery(attendanceCheck, setAttendanceCheck);
}

// 당첨 정보 입력
function* setAttendanceApply(action) {
    const { payload: params } = action;

    try {
        const data = yield call(attendanceApplyAPI, params);
        yield put(attendanceApplySuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* attendanceApplySaga() {
    yield takeEvery(attendanceApply, setAttendanceApply);
}
