import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const privacyListAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getHtmlPageDateList",
            data: params,
        });

        return response.data;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};


export const privacyHtmlAPI = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "mypage/myinfo/getHtmlPage",
            data: params,
        });

        return response.data;
    } catch (error) {
        if (error.response.status > 200) {
            handleError();
        }
    }
};
