import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IOS, TOKEN } from "../../constant/contant";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { nextMatch } from "./module";
import NextMatch from "./nextMatch";
import { handleAppBridge } from "../../libs/js/common";
import MatchSort from "./matchSort";
import MatchListCon from "./matchListCon";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Match = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const navigator = useNavigate();

    const [useListParam, setListParam] = useState({
        isloding: true,
        league_id: null,
        league_detail_id: null,
        nextMatch_id: null,
        callAPI: true,
        seezn: null,
    });

    const { nextMatchRes, loginCheck } = useSelector((state) => ({
        nextMatchRes: state.match.matchNext,
        loginCheck: state.auth.loginCheck,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button1_AI페퍼스_경기일정_click','AI_peppers_button1_AI페퍼스_경기일정_click','AI_peppers_button1_AI페퍼스_경기일정_click');
            }
        } else {
            TrackGoogleAnalyticsEvent('maintab1_top_경기일정_더보기_click','maintab1_top_경기일정_더보기_click','maintab1_top_경기일정_더보기_click');
        }
    }, []);

    useEffect(() => {
        dispatch(nextMatch());
    }, [dispatch]);

    useEffect(() => {
        if (useListParam.isloding) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [useListParam.isloding]);

    useEffect(() => {
        if (useListParam.isloding) {
            if (nextMatchRes?.data) {
                const { league_id, league_detail_id, id } = nextMatchRes.data;
                setListParam({
                    ...useListParam,
                    isloding: false,
                    league_id,
                    league_detail_id,
                    nextMatch_id: id,
                    seezn: true,
                });
            } else {
                setListParam({
                    ...useListParam,
                    seezn: false,
                });
            }
        }
    }, [nextMatchRes]);

    const matchTop = () => {
        const { result } = loginCheck;
        if (result === "Y") {
            navigator(`/ticketCouponBuy${search}`, {
                state: {
                    pageType: "view",
                },
            });
        } else {
            handleAppBridge({
                fnName: "goLogin",
                // param: ["로그인 후 이용 가능합니다.", "refresh"],
            });
        }
    };

    if (!nextMatchRes) {
        return false;
    }

    return (
        <>
            <div className="match-top-banner">
                <button
                    type="button"
                    className="match-top-bn"
                    onClick={() => matchTop()}
                />
            </div>
            <div className="match-head">
                {/*<ul className="tab-scroll">
                    <li
                        className="active"
                        style={IOS ? { marginBottom: "-1px" } : null}
                    >
                        <button type="button" className="tab-btn">
                            V-리그
                        </button>
                    </li>
                </ul>*/}
            </div>
            <div className="match-container">
                <div className="inner">
                    {useListParam.league_detail_id !== null && (
                        <MatchSort
                            setListParam={setListParam}
                            useListParam={useListParam}
                            nextMatchRes={nextMatchRes}
                        />
                    )}

                    {!!nextMatchRes.data && (
                        <NextMatch
                            resData={nextMatchRes.data}
                            useListParam={useListParam}
                        />
                    )}

                    {useListParam.seezn !== null && (
                        <MatchListCon
                            useListParam={useListParam}
                            setListParam={setListParam}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Match;
