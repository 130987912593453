import React, { useEffect, useState } from "react";
import MerchandiseTabs from "./merchandiseTabs";
import MerchandiseItem from "./merchandiseItem";
import { useSelector } from "react-redux";
import { merchandiseGetList } from "./module";
import Infinite from "../../components/infinite/infinite";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const Merchandise = () => {
    const { count, data } = useSelector((state) => ({
        count: state.merchandise.count,
        data: state.merchandise.data,
    }));

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('AI_peppers_button4_AI페퍼스_구단상품_click','AI_peppers_button4_AI페퍼스_구단상품_click','AI_peppers_button4_AI페퍼스_구단상품_click');
            }
        } else {
            TrackGoogleAnalyticsEvent('maintab1_banner4_구단상품_click','maintab1_banner4_구단상품_click','maintab1_banner4_구단상품_click');
        }
        
    }, []);

    const [activeTab, setActiveTab] = useState("all");

    // 탭 클릭 시
    const onTabClick = (type) => {
        if (activeTab !== type) {
            setActiveTab(type);
        }
    };

    // 리스트 아이템
    const onItemComponent = (post) => (
        <MerchandiseItem post={post} key={post.id} />
    );

    // 데이터 없을 경우
    const onNoneResultComponent = () => (
        <div className="none-merchandise">판매중인 상품이 없습니다.</div>
    );

    return (
        <div className="merchandise">
            <div className="inner">
                <MerchandiseTabs
                    activeTab={activeTab}
                    onTabClick={onTabClick}
                />
                <Infinite
                    listClass="merchandise-list"
                    count={count}
                    data={data}
                    activeTab={activeTab}
                    onGetList={merchandiseGetList}
                    onItemComponent={onItemComponent}
                    onNoneResultComponent={onNoneResultComponent}
                />
            </div>
        </div>
    );
};

export default Merchandise;
