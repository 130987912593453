import React, { useEffect, useState } from "react";
import CommentItem from "./commentItem";
import classNames from "classnames";
import { handleAppBridge } from "../../libs/js/common";
import { boardCommentDeleteAPI } from "../../pages/sns/API/snsAPI";
import useLoginCheck from "../../hooks/useLoginCheck";
import { useDispatch, useSelector } from "react-redux";

import { videoGetView } from "../../pages/video/module";

const Comment = ({
    boardId,
    enterRef,
    currentPost,
    setCurrentPost,
    setAuthor,
    comments: posts,
    setCommentInfo,
    onCommentLike,
}) => {
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [isMyComment, setIsMyComment] = useState(false); // 내가 작성한 댓글인지 여부
    const isLogin = useLoginCheck();

    const dispatch = useDispatch();

    // 시트 열기
    const handleSheetOpen = (post) => {
        setIsSheetOpen(true);
        setCurrentPost(post);
        setIsMyComment(post.myComment);
    };

    // 시트 닫기
    const handleSheetClose = () => {
        setIsSheetOpen(false);
    };

    // 코멘트 리프레시
    useEffect(() => {
        window.handleRefreshComment = new CustomEvent("NativeEvent");
        const nativeEventCallback = () => {
            const boardId = currentPost.boardId;

            handleSheetClose();

            dispatch(videoGetView({ id: boardId }));
            setCommentInfo({
                type: "comment",
            });
        };

        window.addEventListener("NativeEvent", nativeEventCallback);

        // event listener clean up
        return () => {
            window.removeEventListener("NativeEvent", nativeEventCallback);
        };
    }, [currentPost]);

    const handleRefreshCommentList = () => {
        // const boardId = currentPost.boardId;

        dispatch(videoGetView({ id: boardId }));
        setCommentInfo({
            type: "comment",
        });
    };

    // 신고하기 버튼 클릭 시
    const handleReport = () => {
        if (isLogin) {
            const isSub = !("commentReply" in currentPost);
            const type = isSub ? "board_comment_reply" : "board_comment";
            const commentId = currentPost.id;
            const parentId = currentPost.boardId;

            handleAppBridge({
                fnName: "onReportComment",
                param: [type, commentId, parentId],
            });

            handleSheetClose();

            /*const params = {
                report_reason_title: "댓글 신고",
                comment_id: currentPost.id,
                parent_id: currentPost.boardId,
                type: isSub ? "board_comment_reply" : "board_comment",
            };

            videoCommentReportAPI(params) //
                .then((res) => {
                    if (res.code === "200") {
                        alert("댓글이 신고 되었습니다.");
                        setIsSheetOpen(false);
                    }
                });*/
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    // 댓글쓰기 버튼 클릭 시
    const handleWrite = (e, post, parentId) => {
        enterRef.current.focus();
        handleSheetClose();
        post && setCurrentPost(post);

        if (!isLogin) {
            handleAppBridge({
                fnName: "goLogin",
            });
        }

        if (post) {
            // 기본 댓글쓰기 버튼 클릭 시
            const isSub = !("commentReply" in post);

            setAuthor(isSub ? post.username : null);
            setCommentInfo({
                type: isSub ? "replyreply" : "reply",
                comment_id: isSub ? parentId : post.id,
                reply_comment_id: isSub ? post.id : null,
            });
        } else {
            // 시트 > 댓글쓰기 버튼 클릭 시
            const isSub = !("commentReply" in currentPost);

            setAuthor(isSub ? currentPost.username : null);
            setCommentInfo({
                type: isSub ? "replyreply" : "reply",
                comment_id: isSub ? parentId : currentPost.id,
                reply_comment_id: isSub ? currentPost.id : null,
            });
        }
    };

    // 댓글 삭제
    const handleDeleteComment = () => {
        // 댓글 또는 대댓글이 있을 경우에만 컨펌창 노출 후 삭제
        if (window.confirm("댓글을 삭제 하시겠습니까?")) {
            const isSub = !("commentReply" in currentPost);
            const type = isSub ? "reply" : "comment";
            const commentId = currentPost.id;
            const boardId = currentPost.boardId;

            let params = {
                id: commentId,
                type: type, // 일반댓글 : comment / 대댓글 : reply / 대대댓글 : reply
            };

            handleSheetClose();

            //API 호출
            boardCommentDeleteAPI(params) //
                .then((res) => {
                    if (res.code === "200") {
                        alert("댓글이 삭제 되었습니다.");
                        handleRefreshCommentList();
                    } else {
                        alert(res.msg);
                        return false;
                    }
                });
        }
    };

    // 차단하기
    const handleBlock = () => {
        if (isLogin) {
            handleAppBridge({
                fnName: "onUserBlock",
                param: [currentPost.userId, currentPost.username],
                callbackName: "handleRefreshComment",
            });
        } else {
            handleAppBridge({
                fnName: "goLogin",
            });
        }
    };

    useEffect(() => {
        document.body.style.overflow = isSheetOpen ? "hidden" : "unset";
    }, [isSheetOpen]);

    return (
        <>
            <ul className="comment-list">
                {posts.map((post) => {
                    const { commentReply } = post;

                    return (
                        <li
                            key={post.id}
                            className={classNames(isMyComment === 1 && "is-my")}
                        >
                            <CommentItem
                                post={post}
                                onSheetOpen={handleSheetOpen}
                                onWrite={handleWrite}
                                onCommentLike={onCommentLike}
                            />
                            {commentReply.length > 0 && (
                                <ul className="comment-list sub-comment-list">
                                    {commentReply.map((item) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className={classNames(
                                                    isMyComment === 1 &&
                                                        "is-my",
                                                )}
                                            >
                                                <CommentItem
                                                    post={{
                                                        ...item,
                                                        parentId: post.id,
                                                        parentNickname: post.username,
                                                    }}
                                                    onSheetOpen={
                                                        handleSheetOpen
                                                    }
                                                    onWrite={handleWrite}
                                                    onCommentLike={
                                                        onCommentLike
                                                    }
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
            <div
                className={classNames("bottom-sheet", isSheetOpen && "active")}
            >
                <div className="sheet-content">
                    <button type="button" onClick={handleWrite}>
                        댓글쓰기
                    </button>
                    {isMyComment === 1 ? (
                        /* 내 댓글 */
                        <button type="button" onClick={handleDeleteComment}>
                            삭제하기
                        </button>
                    ) : (
                        <>
                            {/* 타인 댓글 */}
                            <button type="button" onClick={handleReport}>
                                신고하기
                            </button>
                            <button type="button" onClick={handleBlock}>
                                <em>레드카드(차단하기)</em>
                            </button>
                        </>
                    )}
                    <button type="button" onClick={handleSheetClose}>
                        취소
                    </button>
                </div>
                <div className="dim" onClick={handleSheetClose} />
            </div>
        </>
    );
};

export default Comment;
