import React from 'react'



const SelectComponent = React.memo(({ data, onChange, selectedValue, type }) => {
    const sortedData = React.useMemo(() => data.slice().sort((pr, nt) => new Date(nt.title) - new Date(pr.title)), [data]);

    return (
        <div className="select-wrap">
            <strong>{
                type === "termsConditions" ? "이전 이용약관 보기" : "이전 개인정보 취급 방침 보기"
                }</strong>
            <select value={selectedValue} onChange={onChange}>
                {sortedData.map((da) => (
                    <option
                        key={da.id}
                        value={
                            JSON.stringify({
                                id: da.id,
                                name: da.name,
                            })
                        }
                    >
                        시행일자 : {da.title}
                    </option>
                ))}
            </select>
        </div>
    );
});


export default SelectComponent;