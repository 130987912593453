import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bannerGetView } from "./module";
import { useParams } from "react-router";
import { FULL_URL } from "../../constant/contant";

const BannerDetail = () => {
    const { banner_id } = useParams();
    const dispatch = useDispatch();

    const convertHtml = (data) => {
        data = data.replace(/&lt;/g, "<");
        data = data.replace(/&gt;/g, ">");
        data = data.replace(/&quot;/g, '"');
        data = data.replace(/&nbsp;/g, " ");
        data = data.replace(/\n/g, "<br />");
        return data;
    };

    useEffect(() => {
        dispatch(bannerGetView({ id: banner_id }));
    }, []);

    const { bannerViewData } = useSelector((state) => ({
        bannerViewData: state.banner.bannerView.data,
    }));

    if (!bannerViewData) return false;

    const el = document.querySelector("#header .page-name");
    el.textContent = bannerViewData.title;

    return (
        <>
            <div className="bnr_detail_wrap">
                <img
                    src={`${FULL_URL}/file/${bannerViewData.detail_image}`}
                    alt={convertHtml(bannerViewData.title)}
                />
                {bannerViewData.url && (
                    <div className="btm_btn">
                        <button type="button" onClick={() => window.open(bannerViewData.url, '_blank')}>
                            {bannerViewData.description !== ""
                                ? bannerViewData.description
                                : "이벤트 참여하기"}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default BannerDetail;
