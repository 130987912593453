import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./application.reducer";

// 이벤트 응모 페이지
const initialState = {
    list: {
        count: 0,
        data: null,
        code: null,
    },
};

export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers,
});

export const { applicationGetList, applicationGetListSuccess } =
    applicationSlice.actions;
