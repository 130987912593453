import React, { useEffect, useState } from "react";
import "swiper/scss";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { playerGetList } from "./module";
import PlayerItem from "./playerItem";
import { handleAppBridge, scrollError } from "../../libs/js/common";
import { IOS } from "../../constant/contant";
import { useLocation } from "react-router-dom";

const Player = () => {
    const dispatch = useDispatch();
    const { seq_id } = useParams();

    const { type, data } = useSelector((state) => ({
        type: state.player.list.type,
        data: state.player.list.data,
    }));

    const tabType = type === "all" ? "" : type;
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const pageType = location.state?.pageType;

    useEffect(() => {
        dispatch(
            playerGetList({
                type: tabType,
            }),
        );
    }, [type]);

    /* 로딩 */
    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (pageType === "view" && IOS) {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    return data && <PlayerItem data={data} seq={seq_id} />;
};

export default Player;
