import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextMatch } from "../match/module";
import { eventGetList } from "./module";
import { FULL_URL } from "../../constant/contant";
import { Link } from "react-router-dom";
import { handleAppBridge, scrollObj } from "../../libs/js/common";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const EventList = () => {
    const dispatch = useDispatch();

    const { nextMatchRes, data } = useSelector((state) => ({
        nextMatchRes: state.match.matchNext,
        data: state.news.eventList.data,
    }));

    const el = document.querySelector("#header .page-name");
    if (el) {
        el.textContent = "AI페퍼스 소식";
    }

    const { search } = window.location;
    const [isLoading, setIsLoading] = useState(true);
    const [matchId, setMatchId] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    useEffect(() => {
        InitializeGoogleAnalytics();
        if( gb !== null ) {
            if( gb === 'm1_e' ) {
                TrackGoogleAnalyticsEvent('maintab1_event_더보기_click','maintab1_event_더보기_click','maintab1_event_더보기_click');
            }
        }
    }, []);


    useEffect(() => {
        dispatch(eventGetList());
        dispatch(nextMatch());
    }, []);

    useEffect(() => {
        if (nextMatchRes) {
            if (nextMatchRes.data !== null) {
                setMatchId(nextMatchRes.data.id);
            }
        }
    }, [nextMatchRes]);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (isLoading) {
            handleAppBridge({
                fnName: "startLoading",
            });
        } else {
            handleAppBridge({
                fnName: "stopLoading",
            });
        }
    }, [isLoading]);

    const eventMatchListMarkup = (type, post, isApply, status) => {
        const { id, link, thumb, title, content } = post;

        if (type === "matchEventNotJoin") {
            return (
                <div className="list" key={id}>
                    <Link
                        style={{
                            backgroundImage: `url(${FULL_URL}file/${thumb})`,
                        }}
                        to={`/matchPrediction/${matchId}${search}`}
                        state={{ scrollObj }}
                        className="match"
                    >                        
                    </Link>
                    <strong>{title}</strong>
                </div>
            );
        } else {
            return (
                <div
                    key={id}
                    style={{
                        backgroundImage: `url(${FULL_URL}file/ ${thumb})`,
                    }}
                    className="list"
                    // onClick={
                    //     status === "MS01" && isApply === "1"
                    //         ? () => alert("승부예측에 이미 참가했습니다.")
                    //         : status === "MS02"
                    //         ? () =>
                    //               alert(
                    //                   "경기가 진행중 입니다. \n다음 경기에 참여 해주세요.",
                    //               )
                    //         : status === "MS03"
                    //         ? () =>
                    //               alert(
                    //                   "경기가 종료 되었습니다. \n다음 경기에 참여 해주세요.",
                    //               )
                    //         : null
                    // }
                >
                    <Link
                        style={{
                            backgroundImage: `url(${FULL_URL}file/${thumb})`,
                        }}
                        to={`/matchPredictionView/${matchId}${search}`}
                        state={{ scrollObj }}
                        className="match"
                    >
                    </Link>
                    <strong>{title}</strong>
                    {/*<span>{content}</span>*/}
                </div>
            );
        }
    };

    if (!nextMatchRes || !data) return false;

    return (
        <div className="event-banner">
            {data.length === 0 && <p>진행중인 이벤트가 없습니다.</p>}
            {data.map((post) => {
                const { id, link, thumb, title, content } = post;
                if (link === "/matchPrediction") {
                    if (matchId !== null) {
                        const { status, isApply } = nextMatchRes.data;
                        if (status === "MS01") {
                            if (isApply === "0") {
                                return eventMatchListMarkup(
                                    "matchEventNotJoin",
                                    post,
                                    isApply,
                                    status,
                                );
                            } else {
                                return eventMatchListMarkup(
                                    "matchEventJoin",
                                    post,
                                    isApply,
                                    status,
                                );
                            }
                        } else {
                            return eventMatchListMarkup(
                                "matchEventJoin",
                                post,
                                isApply,
                                status,
                            );
                        }
                    }
                } else {
                    return (
                        <div className="list" key={id}>
                            <Link
                                style={{
                                    backgroundImage: `url(${FULL_URL}file/${thumb})`,
                                }}
                                to={`${link}${search}`}
                                state={{ scrollObj }}
                                className="match"
                            >
                                {/*<span>{content}</span>*/}
                            </Link>
                            <strong>{title}</strong>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default EventList;
