import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noticeGetView } from "./module";
import { useParams } from "react-router";
import { IOS } from "../../constant/contant";
import { scrollError, setDateFormat } from "../../libs/js/common";
import { useLocation } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent} from "../../utils/google-analytics/index";

const NoticeView = () => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({
        id: state.news.noticeView.id,
        data: state.news.noticeView.data,
    }));
    const { notice_id } = useParams();
    const location = useLocation();
    const pageType = location.state?.pageType;

    const convertHtml = (data) => {
        data = data.replace(/&lt;/g, "<");
        data = data.replace(/&gt;/g, ">");
        data = data.replace(/\n/g, "<br />");
        return data;
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const gb = searchParams.get("gb");

    

    useEffect(() => {
        dispatch(
            noticeGetView({
                id: notice_id,
            }),
        );
    }, []);

    useEffect(() => {
        if (pageType === "view" && IOS) {
            setTimeout(() => {
                scrollError();
            }, 400);
        }
    }, []);

    useEffect(() => {
        InitializeGoogleAnalytics();

        if( gb !== null ) {
            if( gb === 'm_p' ) {
                TrackGoogleAnalyticsEvent('main_popup_이벤트알림_click','main_popup_이벤트알림_click','main_popup_이벤트알림_click');
            } else if( gb === 'ca' ) {
                TrackGoogleAnalyticsEvent('event_button7_이벤트_미스테리박스_click','event_button7_이벤트_미스테리박스_click','event_button7_이벤트_미스테리박스_click');
            } else if( gb === 'm1_e' ) {
                if( data ) {                    
                    const replaceTitle = data.title.replace(/\s+/g,'');

                    if( replaceTitle.indexOf('점핑점핑')  > -1 ) {
                        TrackGoogleAnalyticsEvent('maintab1_event_점핑점핑_click','maintab1_event_점핑점핑_click','maintab1_event_점핑점핑_click');
                    } else if ( replaceTitle.indexOf('미스터리박스')  > -1 ) {                                                
                        TrackGoogleAnalyticsEvent('maintab1_event_미스터리박스_click','maintab1_event_미스터리박스_click','maintab1_event_미스터리박스_click');
                    }
                }
            }
        }
    }, [data]);

    if (!data) return false;

    const { title, reg_dt, content } = data;

    return (
        <div className="notice-view">
            <div className="notice-head">
                <p className="title">{title}</p>
                <span className="date">{setDateFormat(reg_dt, "short")}</span>
            </div>
            <div className="notice-view">
                <div
                    className="editor"
                    dangerouslySetInnerHTML={{ __html: convertHtml(content) }}
                />
            </div>
        </div>
    );
};

export default NoticeView;
