export const matchPredictionJSON = {
    inputConfirm: false,
    totalScore: {
        left: 0,
        right: 0,
    },
    setData: [
        {
            set: 1,
            dfMaxScore: 25,
            dfminScore: 23,
            winner: "",
            step: "step01",
            winScore: null,
            loseScore: null,
        },
        {
            set: 2,
            dfMaxScore: 25,
            dfminScore: 23,
            winner: "",
            step: "step00",
            winScore: null,
            loseScore: null,
        },
        {
            set: 3,
            dfMaxScore: 25,
            dfminScore: 23,
            winner: "",
            step: "step00",
            winScore: null,
            loseScore: null,
        },
        {
            set: 4,
            dfMaxScore: 25,
            dfminScore: 23,
            winner: "",
            step: "step00",
            winScore: null,
            loseScore: null,
        },
        {
            set: 5,
            dfMaxScore: 15,
            dfminScore: 13,
            winner: "",
            step: "step00",
            winScore: null,
            loseScore: null,
        },
    ],
};
