import { put, takeEvery, call } from "redux-saga/effects";
import { matchAPI, nextMatchAPI, leagueSortAPI } from "../API/matchAPI";
import { match, nextMatch, leagueSort } from "./match.slice";

function* matchAction(action) {
    const param = action.payload;
    try {
        const data = yield call(matchAPI, param);
        yield put({ type: match, data });
    } catch (err) {
        console.error(err);
    }
}

export function* matchSaga() {
    yield takeEvery(match.type, matchAction);
}

function* nextMatchAction(action) {
    const param = action.payload;
    try {
        const data = yield call(nextMatchAPI);
        yield put({ type: nextMatch, data });
    } catch (err) {
        console.error(err);
    }
}

export function* nextMatchSaga() {
    yield takeEvery(nextMatch.type, nextMatchAction);
}

function* leagueSortAction(action) {
    const param = action.payload;

    try {
        const data = yield call(leagueSortAPI);
        yield put({ type: leagueSort, data });
    } catch (err) {
        console.error(err);
    }
}

export function* matchLeagueSortSaga() {
    yield takeEvery(leagueSort.type, leagueSortAction);
}
