import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { FULL_URL } from "constant/contant";
import { Swiper, SwiperSlide } from "swiper/react";
import { throttle } from "lodash";
import convertHtml from "constant/convertHtml";
import { Autoplay, Pagination } from "swiper";

const AboutItem = ({ aboutWrapRef, handleActiveTab, clicked }) => {
    const { data: aboutList, loading } = useSelector((state) => state.about.list);
    const innerRef = React.useRef(null);

    React.useEffect(() => {
        const aboutWrap = aboutWrapRef.current;
        const handleScroll = throttle((event) => {
            if (clicked) return;
            
            if (!loading && innerRef.current) {
                const offset = 20;
                const wrapRect = aboutWrap.getBoundingClientRect();
                const tabHeight = aboutWrap.querySelector('.tab-swiper').scrollHeight;
                const sections = aboutWrap.querySelector('.inner').children;

                Array.from(sections).forEach((section) => {
                    const sectionRect = section.getBoundingClientRect();
                    const sectionTop = sectionRect.top - wrapRect.top - tabHeight - offset;

                    if (sectionTop < 0 && Math.abs(sectionTop) < section.offsetHeight) {
                        handleActiveTab(section.id)
                    }
                })
            }
        },50)
        
        aboutWrap.addEventListener('scroll', handleScroll)

        return () => {
            aboutWrap.removeEventListener('scroll', handleScroll);
        };
    }, [aboutWrapRef, handleActiveTab, loading,clicked]);


    if (loading || !aboutList) return null;

    return (
        <div className="inner" ref={innerRef}>
            {aboutList && aboutList.map((about, index) => {
                return (
                    <React.Fragment key={index}>
                        {about && about.length > 0 && (
                            <section id={about[0].typeCd}>
                                {about.map((ab) => (
                                    <React.Fragment key={`${ab.typeCd}_${ab.id}`}>
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            modules={[Pagination,Autoplay]}
                                            autoplay={{
                                                delay: 3000,
                                                disableOnInteraction: false
                                            }}
                                            pagination={{
                                                el: ".swiper-pagination",
                                                type: "bullets",
                                                bulletClass: "swiper-bullet",
                                                bulletActiveClass: "active",
                                                clickable: true,
                                            }}
                                        >
                                            {ab.list.map((list, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <img
                                                            src={`${FULL_URL}file${list.filePath}${list.fileNo}`}
                                                            alt="about"
                                                        />
                                                    </SwiperSlide>
                                                );
                                            })}
                                            <div className="swiper-pagination"></div>
                                        </Swiper>
                                        <div className="content">
                                            <p className="title">{ab.typeCdNm}</p>
                                            <div>
                                                <strong>{ab.title}</strong>
                                                <p dangerouslySetInnerHTML={{ __html: convertHtml(ab.content)}}></p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </section>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default AboutItem;
