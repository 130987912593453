import React from "react";
import Apply from "../apply/apply";
import { attendanceNoteList } from "./attendance";

const AttendanceApply = () => {
    return (
        <Apply
            noteTitle="개인정보 취급 및 유의사항 동의"
            noteList={attendanceNoteList}
        />
    );
};

export default AttendanceApply;
