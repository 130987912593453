
export const reducers = {
    staffGetList: (state, { payload: { typeCd } }) => {
        state.list.data = null;
    },
    staffGetListSuccess: (state, { payload }) => {
        state.list.loading = false;
        state.list.data = payload;
    },
    staffGetMenuList: (state, { payload: { typeCd } }) => {
        state.menuList.type = typeCd;
        state.menuList.data = null;
    },
    staffGetMenuListSuccess: (state, { payload }) => {
        state.menuList.data = payload;
    },
};
