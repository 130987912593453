import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import PlayerBottom from "./playerBottom";
import SlideItem from "./slideItem";
import { Navigation } from "swiper";

const PlayerItem = ({ data, seq }) => {
    const [posts, setPosts] = useState(null);
    const [id, setId] = useState("");
    const [floatingBox, setFloatingBox] = useState(false);
    const [currentIdx, setCurrentIdx] = useState(0);
    const ordArr = data.map((item) => {
        return item.id.toString();
    });

    const ordNum = ordArr.indexOf(seq);

    const onFloatingClick = () => {
        setFloatingBox((floatingBox) => !floatingBox);
    };

    useEffect(() => {
        if (posts !== data) {
            setPosts(data);
        }
    }, [data]);

    if (!posts) return false;

    return (
        <div className={`player-info-wrap ${floatingBox ? "active" : ""}`}>
            <Swiper
                modules={[Navigation]}
                navigation={true}
                initialSlide={ordNum}
                loop={true}
                observer={true}
                observeParents={true}
                onSlideChange={(swiper) => {
                    const { realIndex } = swiper;
                    setCurrentIdx(realIndex);
                    setId(posts[realIndex].id);
                }}
            >
                {posts.map((post, index) => {
                    let setClass = null;
                    if (post.bg_type === "I") {
                        setClass = "player-slide load-done";
                    } else {
                        setClass = "player-slide";
                    }

                    return (
                        <SwiperSlide key={post.id} className={setClass}>
                            <SlideItem
                                posts={posts}
                                post={post}
                                index={index}
                                id={post.id}
                                currentIdx={currentIdx}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <PlayerBottom
                posts={posts[currentIdx]}
                floatingBox={floatingBox}
                onFloatingClick={onFloatingClick}
            />
        </div>
    );
};

export default PlayerItem;
