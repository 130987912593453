import React from "react";
import { BtnOutline02 } from "../../components/common/common";

const PepperLiveTabs = ({ tabList, activeTab, handleTabClick }) => {
    return (
        <div className="tab-scroll">
            {tabList.map(({ type, text }) => (
                <BtnOutline02
                    key={type}
                    name={type}
                    text={text}
                    active={activeTab === type}
                    onClick={() => handleTabClick(type)}
                />
            ))}
        </div>
    );
};

export default PepperLiveTabs;
