import { handleError } from "../../../libs/js/common";
import request from "../../../utils/api";

export const staffListApi = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/coachList",
            data: params,
        });

        return response.data;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};


export const staffMenuListApi = async (params) => {
    try {
        const response = await request({
            method: "post",
            url: "/category/aipeppers/menuUpList",
            data: params,
        });
        return response.data;
    } catch (e) {
        if (e.response.status > 200) {
            handleError();
        }
    }
};
