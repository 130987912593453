import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useCallback } from 'react';
import { FULL_URL } from 'constant/contant';
import convertHtml from 'constant/convertHtml';
import { createPortal } from 'react-dom';
const StaffBottom = ({ staffList, show, showId, handleBottomHide }) => {
    const [isVisible,setIsVisible] = React.useState(false);
    const [showRender, setShowRender] = React.useState(false);
    const swiperRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const touch = React.useRef({
        startY:0,
        endY:0,
        startTime:0,
        currentY:0,
    })

    React.useEffect(() => {
        if(show){
            setShowRender(true);
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            },10)

            document.body.addEventListener('touchstart', handleTouchStart);
        }else{
            setIsVisible(false);
            setTimeout(() => {
                setShowRender(false);
                document.body.style.overflow = '';
            },300)
        }
    },[show])

    function handleNextNav() {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            const nowIdx = swiperRef.current.swiper.activeIndex;

            if (nowIdx < swiper.slides.length - 1) {
                swiper.slideNext()
            }
        }
    }
    function handlePrevNav() {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            const nowIdx = swiperRef.current.swiper.activeIndex;

            if (nowIdx > 0) {
                swiper.slidePrev()
            }
        }
    }

    const handleTouchStart = useCallback((e) => {
        touch.current.startY = e.touches[0].clientY;
        document.body.addEventListener('touchmove', handleTouchMove);
        document.body.addEventListener('touchend', handleTouchEnd);
    })

    function handleTouchMove(e){
        const canvas = canvasRef.current;
        const deltaY = e.touches[0].clientY - touch.current.startY < 0 ? 0 : e.touches[0].clientY - touch.current.startY;

        if(canvas){
            canvas.style.transition = 'transform 0s linear'
            canvas.style.transform = `translateY(${deltaY}px)`
        }
        if (touch.current.startTime === 0){
            touch.current.startTime = new Date().getTime();
        }

        touch.current.endY = e.touches[0].clientY;
        touch.current.currentY = deltaY;
    }

    function handleTouchEnd(e){
        const canvas = canvasRef.current;
        const deltaY = touch.current.endY - touch.current.startY;
        const deltaTime = new Date().getTime() - touch.current.startTime;
        const threshold  = 100;
        const timeLimit = 100;

        if(canvas){
            if (canvas.offsetHeight * 0.4 > touch.current.currentY) {
                canvas.style = '';
                touch.current.startTime = 0;
            } else {
                handleClose();
            }
        }

        if (deltaY > threshold && deltaTime < timeLimit) {
           handleClose();
        }
    }

    function handleClose(){
        const canvas = canvasRef.current;

        touch.current.startY = 0;
        touch.current.currentY = 0;
        touch.current.startTime = 0;
        touch.current.moveDistance = 0;

        setTimeout(() => {
            canvas.style = '';
        }, 10)

        document.body.removeEventListener('touchmove', handleTouchMove);
        document.body.removeEventListener('touchend', handleTouchEnd);

        handleBottomHide();
    }

    const renderBottom = () => {
        return (
            <>
                <div className='canvas-backdrop'>
                </div>
                <div className={`canvas-container ${isVisible ? 'show' : 'hide'}`} ref={canvasRef}>
                    <div className="staff-bottom">
                        <div className='container'>
                            <header className='canvas-top'>
                                <h3>
                                    코칭스태프
                                </h3>
                                <button className='close-btn' onClick={handleClose} type='button' />
                            </header>
                            <div className='canvas-cont'>
                                <Swiper
                                    ref={swiperRef}
                                    onInit={(swiper) => {
                                        const slideIndex = staffList.findIndex((staff) => staff.id === showId);
                                        swiper.slideTo(slideIndex, 0)
                                    }}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                >
                                    {staffList.map((staff, idx) => (
                                        <SwiperSlide key={idx}>
                                            <div className='top'>
                                                <div className='br-wrap'>
                                                    <div className='image-wrap'>
                                                        <img src={`${FULL_URL}file${staff.filePath}${staff.fileNo}`} alt='TOP IMG' />
                                                    </div>
                                                    <div className='info-wrap'>
                                                        <strong dangerouslySetInnerHTML={{ __html: staff.typeCdNm.split(' ').join('\n').replace(/\n/g, '<br />') }}></strong>
                                                        <div>
                                                            <p className='kr'>{staff.name}</p>
                                                            <p className='en'>{staff.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='navigator'>
                                                    <button onClick={handlePrevNav} className='prev'></button>
                                                    <button onClick={handleNextNav} className='next'></button>
                                                </div>
                                            </div>
                                            <div className='cont'>
                                                <div className='title'>
                                                    <h3>경력</h3>
                                                </div>
                                                <div className='desc' dangerouslySetInnerHTML={{ __html: convertHtml(staff.content) }}>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return showRender ? createPortal(renderBottom(), document.body) : null;
}

export default StaffBottom;