import React from "react";
import CardItem from "./cardItem";

import organization_01 from "../../libs/images/team/organization_01.png";
import organization_02 from "../../libs/images/team/organization_02.png";
import organization_03 from "../../libs/images/team/organization_03.png";

const OrganizationCard = () => {
    const cardList = [
        {
            isMark: true,
            position: "구단주",
            name: "장매튜",
            engName: "CHANG MATTHEW",
            src: organization_01,
        },
        {
            isMark: true,
            position: "단장",
            name: "김동언",
            engName: "KIM DONG EON",
            src: organization_02,
        },
        {
            isMark: true,
            position: "사무국장",
            name: "정성우",
            engName: "JUNG SUNG WOO",
            src: organization_03,
        },
    ];

    return (
        <div className="staff-swiper none-swiper">
            {cardList.map((item, index) => {
                return <CardItem key={index} item={item} />;
            })}
        </div>
    );
};

export default OrganizationCard;
