import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { errorTest } from "./module";

const ErrorTest = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const dispatch = useDispatch();

    const test = useSelector((state) => {
        return state.errorTest.errorTest;
    });

    useEffect(() => {
        setTimeout(() => {
            dispatch(errorTest());
        }, 0);
    }, [dispatch]);

    if (!test) return false;

    return (
        <div style={{ height: "2000px", padding: "0 20px" }}>
            <ul>
                <li style={{ paddingTop: "1000px", backgroundColor: "#eee" }} />
                <li
                    style={{ padding: "20px 0" }}
                    onClick={() => navigate(`/errorTestView${search}`)}
                >
                    NAVIGATE
                </li>
                <li style={{ padding: "20px 0" }}>
                    <Link to={`/errorTestView1${search}`}> LINK TO</Link>
                </li>
            </ul>
            <div>{test.created_at}</div>
        </div>
    );
};

export default ErrorTest;
