import { put, takeEvery, call } from "redux-saga/effects";
import {
    login,
    loginCheck,
    loginCheckSuccess,
    loginSuccess,
    logout,
    logoutSuccess,
} from "./auth.slice";
import { loginAPI, loginCheckedAPI, logoutAPI } from "../API/headerAPI";

// 로그인
function* setLogin(action) {
    const { payload: params } = action;

    try {
        const data = yield call(loginAPI, params);
        yield put(loginSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* authSaga() {
    yield takeEvery(login, setLogin);
}

// 로그아웃
function* setLogout(action) {
    const { payload: params } = action;

    try {
        const data = yield call(logoutAPI, params);
        yield put(logoutSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* logoutSaga() {
    yield takeEvery(logout, setLogout);
}

// 로그인 세션 체크 ACTION
function* loginChecked(action) {
    const { payload: params } = action;

    try {
        const data = yield call(loginCheckedAPI, params);
        yield put(loginCheckSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

// 로그인 세션 체크 SAGA
export function* loginCheckSaga() {
    yield takeEvery(loginCheck, loginChecked);
}
