import React from "react";
import Apply from "../apply/apply";

const EventApply = () => {
    const noteTitle = "개인정보 취급 및 유의사항 동의";
    const noteList = [
        `본 이벤트는 홈 경기장에 내방한 현장관람객 전용 이벤트입니다.`,
        `당첨자는 경기 종료 후 현장 전광판을 통해 공개됩니다.`,
        `현장에서의 상품 수령을 원칙으로 합니다.`,
        `당첨자는 경기 종료 후 30분 이내에 공지한 장소에서 상품 수령을 완료해야 합니다.`,
        `해당 이벤트 내용은 당사 사정에 따라 사전고지 없이 경품 및 내용 변경 또는 조기 종료될 수 있습니다.`,
        `단종, 품절 등과 같은 당사 및 공급사 사정으로 인해 경품 지급이 어려울 경우, 다른 경품으로 대체될 수 있습니다.`,
        `5만원 초과 경품의 제세공과금(22%)은 당첨자 본인 부담입니다.`,
        `제세공과금 납부와 관련한 세부 사항은 소득세법, 지방세법, 기타 관련 법령의 내용에 따라 처리합니다.`,
        `부정한 방법으로 이벤트에 참여한 경우 혜택은 환수되며, 법에 따른 처벌 대상이 될 수 있습니다.`,
        `본 이벤트 당첨 자격은 타인에게 양도 불가합니다.`,
    ];

    return <Apply noteTitle={noteTitle} noteList={noteList} />;
};

export default EventApply;
