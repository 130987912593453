import { put, takeEvery, call } from "redux-saga/effects";
import { playerListAPI } from "../API/playerListAPI";
import { playerGetList, playerGetListSuccess } from "./player.slice";

// Player List
function* getList(action) {
    const { payload: params } = action;

    try {
        const data = yield call(playerListAPI, params);
        yield put(playerGetListSuccess(data));
    } catch (err) {}
}

export function* playerListSaga() {
    yield takeEvery(playerGetList, getList);
}
