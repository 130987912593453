import { put, takeEvery, call } from "redux-saga/effects";
import {
    getApplyEssnInfo,
    getApplyEssnInfoSuccess,
    postApply,
    postApplySuccess,
} from "./apply.slice";
import { getEssnInfoAPI } from "../API/getEssnInfoAPI";

// Apply
function* apply(action) {
    const { payload: params } = action;
    const options = {
        ...params,
    };

    delete options.callAPI;

    try {
        const data = yield call(params.callAPI, options);
        yield put(postApplySuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* applySaga() {
    yield takeEvery(postApply, apply);
}

// Apply
function* getEssnInfo(action) {
    const { payload: params } = action;

    try {
        const data = yield call(getEssnInfoAPI, params);
        yield put(getApplyEssnInfoSuccess(data));
    } catch (error) {
        console.error(error);
    }
}

export function* getEssnInfoSaga() {
    yield takeEvery(getApplyEssnInfo, getEssnInfo);
}
