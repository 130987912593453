import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./pepperLive.reducer";

const initialState = {
    list: {
        type: "",
        count: 0,
        data: null,
    },
};

export const pepperLiveSlice = createSlice({
    name: "pepperLive",
    initialState,
    reducers,
});

export const { pepperGetList, pepperGetListSuccess } = pepperLiveSlice.actions;
